const state = {
    /* Конфигурация приложения */
    config: {},

    /* Профиль пользователя */
    profile: {},

    /* Куки */
    isCookieAccepted: false,

    /* Объект меню */
    aside: {},

    /* Глобальный список опций */
    options: {
      /* Список проектов */
      projects: {
        limit: 100,
        offset: 0,
        next: true,
        list: [],

        search: {
          limit: 100,
          offset: 0,
          next: true,
          items: []
        }
      },

      /* Список регионов семантика */
      regions: {
        data: [],
        limit: 100,
        offset: 0,
        pattern: ''
      },

      /* Клиенты */
      clients: [],
      clientsInactive: []
    },

    /* Активный проект */
    active_project: null,

    /* Данные по активному проекту */
    active_project_data: {},

    /* Данные по проектам SW */
    projectsSW: {
        has_data: false,
        total: 0
    },

    /* Значения доступных опций в фильтрах */
    filtersOptions: {
        has_data: false,
        items: {
            demensions: [],
            devices: [],
            traffic_sources: {}
        }
    },

    chartColors: [
        '#F25449',
        '#7059FF',
        '#FFD54F',
        '#01D358',
        '#FF9E2D',
        '#5F7193',
        '#32A4FF',
        '#B245F4',
        '#57E5F8',
        '#E8F472',
    ],

    cancelRequests: false,

    filtersTraffic: {
        source: {
            limit: 100,
            offset: 0,
            next: true,
            items: [],

            search: {
                limit: 100,
                offset: 0,
                next: true,
                items: []
            }
        },
        medium: {
            limit: 100,
            offset: 0,
            next: true,
            items: [],

            search: {
                limit: 100,
                offset: 0,
                next: true,
                items: []
            }
        }
    },

    trafficConditions: [
        {id: 'contains', name: 'Содержит'},
        {id: 'not_contains', name: 'Не содержит'},
        {id: 'one_of', name: 'Любой из'},
        {id: 'not_one_of', name: 'Ни одного из'}
    ],

    entityCountTypes: [
        {id: 'elements', name: 'Всего'},
        {id: 'listings', name: 'Листингов'},
        {id: 'tag_listings', name: 'Теговых листингов'},
        {id: 'offers', name: 'Товаров'}
    ],

    regionSearchList: {
        hasData: false,
        items: []
    },

  trafficSources: [
    {
      id: 'ga3',
      name: 'GA3'
    },
    {
      id: 'ga4',
      name: 'GA4'
    },
    {
      id: 'ym',
      name: 'YM'
    }
  ],

  trafficSourceSelectedName: ''
}

export default state
