export function generateTitle (moduleName, projectData = {}) {
  try {
    if (moduleName && projectData.label && (projectData.id || projectData.id === 0)) {
      return `${moduleName} / ${projectData.label} (${projectData.id})`
    } else if (moduleName) {
      return moduleName
    } else {
      return ''
    }
  } catch (_) {
    return ''
  }
}
