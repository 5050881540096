import {findOptions} from '@/function/findOptions.js'

import CONSTANTS from '@/const'

const SEGMENTS_TYPES = [
  CONSTANTS.SEMANTIC_SECTIONS.CATEGORY,
  CONSTANTS.SEMANTIC_SECTIONS.GROUP,
  CONSTANTS.SEMANTIC_SECTIONS.QUERY_GROUP
]

/* Маркировка выбираемых свойств */
export function checkSelection (array) {
  return array.map(section => {
    section.is_selection = section.allowed_sources && SEGMENTS_TYPES.some(key => !!section.allowed_sources[key])
    return section
  })
}

/* Получение количества элементов для первого уровня */
export function treeCount (array) {
  return array.map(item => {
    if (item.items) {
      item.items = treeCount(item.items)

      if (!item.statistics.counters || Array.isArray(item.statistics.counters)) {
        item.statistics.counters = {}
      }

      item.statistics.counters = item.items.reduce((counts, child) => {
        for (let i of SEGMENTS_TYPES) {
          if (child.statistics.counters[i]) {
            if (!counts[i]) {
              counts[i] = 0
            }
            counts[i] += child.statistics.counters[i]
          }
        }

        return counts
      }, item.statistics.counters)
    }
    return item
  })
}

/* Фильтрация родительских элементов */
export function treeFilter (elem) {
  elem.items = elem.items.reduce((a, item) => {
    if (item.is_selection) {
      item.items = undefined
      a.push(item)
    } else if (!item.is_selection && item.items && item.items.length) {
      let newItem = treeFilter(item)
      if (newItem.items && newItem.items.length) {
        a.push(newItem)
      }
    }

    return a
  }, [])

  return elem
}

/* Трансформация списка в дерево */
export function transformationInTree (array, selection) {
  let tree = JSON.parse(JSON.stringify(array))

  tree = tree.reduce((a, c) => {
    let items = tree.filter(i => i.parent_id === c.id)
    c.items = items && items.length ? items : undefined

    if (selection) {
      c.is_selection = !(items && items.length)
    }

    a.push(c)
    return a
  }, []).filter(i => !+i.parent_id)

  return tree
}

/* Трансформация списка в дерево */
export function transformationInTreeV2 (array, selection) {
  let tree = JSON.parse(JSON.stringify(array))

  tree = tree.reduce((a, c) => {
    const items = tree.filter(i => i.parent_id === c.id)
    const itemsIds = items.map(i => i.id)

    tree.forEach(item => {
      if (itemsIds.includes(item.id)) {
        item.hasParent = true
      }
    })

    c.items = items && items.length ? items : undefined

    if (selection) {
      c.is_selection = !(items && items.length)
    }

    a.push(c)
    return a
  }, []).filter(i => !i.hasParent)

  return tree
}

/* Генерация панировочных сухарей */
export function generateBreadCrumbs (id, categories) {
  let breadcrumbsList = []
  let i = 5
  while (id && i--) {
    let item = findOptions(id, categories)
    if (item && item.id) {
      breadcrumbsList.push({
        id: item.id,
        title: item.name,
        selected: item.allowed_sources && !!(item.allowed_sources.category || item.allowed_sources.group || item.allowed_sources.query_group)
      })
      id = item.parent_id
    }
  }
  breadcrumbsList.reverse()
  breadcrumbsList[0].tab = true
  return breadcrumbsList
}
