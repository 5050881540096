import Vue from 'vue'
import component from './modals.vue'

const events = new Vue({
  data: {
    modal: {
      name: '',
      right: false,
      props: {},
      transparent: false
    },

    functions: {
      open: (props) => {
        if (props.name) {
          Vue.set(events.modal, 'name', props.name)
        }

        if (props.props) {
          Vue.set(events.modal, 'props', props.props)
        }

        if (props.right) {
          Vue.set(events.modal, 'right', props.right)
        }

        if (props.transparent) {
          Vue.set(events.modal, 'transparent', props.transparent)
        }
      },

      close: () => {
        Vue.set(events.modal, 'name', '')
        Vue.set(events.modal, 'props', {})
        Vue.set(events.modal, 'right', false)
        Vue.set(events.modal, 'transparent', false)
      }
    }
  }
})

const modals = {
  install (Vue, options = {}) {
    Vue.component('v-modals', component)

    Object.defineProperty(Vue.prototype, '$modal', {
      get: function () {
        return {...events.modal, ...events.functions}
      }
    })
  }
}

export default modals
