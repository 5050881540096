import Vue from 'vue'
import {getDefaultState} from './helpers'

const mutations = {
  saveDataPeriodStatic(state, payload) {
    if (payload.hasData) {
      let categories = [...state.categories]
      let items = []

      if (payload.name === 'summaryDefault' || payload.name === 'summaryEcomDefault') {
        Vue.set(state, payload.name, {
          hasData: true,
          data: payload.data.summary
        })
      } else {
        if (payload.data && payload.data.items && payload.data.items.length) {
          items = [...payload.data.items]

          if (!categories.length) {
            categories = items.map(({catalog_id}) => catalog_id)
          } else {
            items.forEach(({catalog_id}) => {
              if (!categories.includes(catalog_id)) {
                categories.push(catalog_id)
              }
            })
          }
          Vue.set(state, 'categories', categories)
        }

        Vue.set(state, payload.name, {
          hasData: !!items.length,
          items: items || []
        })
      }
    } else {
      Vue.set(state, payload.name, {
        hasData: false,
        items: []
      })
    }
  },

  saveDataPeriodDynamic(state, payload) {
    const isSummary = payload.name === 'summaryPeriod' || payload.name === 'summaryEcomPeriod'

    if (payload.hasData) {
      let data = {hasData: true}

      if (isSummary) {
        data.data = payload.data.summary
        Vue.set(state[payload.name][payload.type], payload.period, data)
      } else {
        data.items = payload.data.items
        Vue.set(state[payload.name], payload.period, data)
      }
    } else {
      if (isSummary) {
        Vue.set(state[payload.name][payload.type], payload.period, {
          hasData: false,
          data: {}
        })
      } else {
        Vue.set(state[payload.name], payload.period, {
          hasData: false,
          items: []
        })
      }
    }
  },

  saveDataGeo(state, payload) {
    if (payload.name === 'summaryGeo') {
      if (payload.hasData) {
        Vue.set(state[payload.name], payload.regionId, {
          hasData: true,
          data: payload.data.summary
        })
      } else {
        Vue.set(state[payload.name], payload.regionId, {
          hasData: false,
          data: {}
        })
      }
    } else {
      if (payload.hasData) {
        Vue.set(state[payload.name], payload.regionId, {
          hasData: true,
          items: payload.data.items
        })
      } else {
        Vue.set(state[payload.name], payload.regionId, {
          hasData: false,
          items: []
        })
      }
    }
  },

  saveDataTopGeo(state, payload) {
    if (payload.name === 'summaryTopGeo') {
      if (payload.hasData) {
        Vue.set(state[payload.name][payload.metric], payload.regionId, {
          hasData: true,
          data: payload.data.summary
        })
      } else {
        Vue.set(state[payload.name][payload.metric], payload.regionId, {
          hasData: false,
          data: {}
        })
      }
    } else {
      if (payload.hasData) {
        Vue.set(state[payload.name][payload.metric], payload.regionId, {
          hasData: true,
          items: payload.data.items
        })
      } else {
        Vue.set(state[payload.name][payload.metric], payload.regionId, {
          hasData: false,
          items: []
        })
      }
    }
  },

  saveDataChannels(state, payload) {
    if (payload.name === 'summaryChannels') {
      if (payload.hasData) {
        Vue.set(state[payload.name], payload.medium, {
          hasData: true,
          data: payload.data.summary
        })
      } else {
        Vue.set(state[payload.name], payload.medium, {
          hasData: false,
          data: {}
        })
      }
    } else {
      if (payload.hasData) {
        Vue.set(state[payload.name], payload.medium, {
          hasData: true,
          items: payload.data.items
        })
      } else {
        Vue.set(state[payload.name], payload.medium, {
          hasData: false,
          items: []
        })
      }
    }
  },

  saveDataChannelsPeriod(state, payload) {
    if (payload.name === 'summaryChannelsPeriod' || payload.name === 'summaryEcomChannelsPeriod') {
      let stateData = {...state[payload.name][payload.type][payload.period]}
      if (payload.hasData) {
        stateData[payload.channel] = {
          hasData: true,
          data: payload.data.summary
        }
      } else {
        stateData[payload.channel] = {
          hasData: false,
          data: {}
        }
      }
      Vue.set(state[payload.name][payload.type], payload.period, stateData)
    } else {
      let stateData = {...state[payload.name][payload.period]}
      if (payload.hasData) {
        stateData[payload.channel] = {
          hasData: true,
          items: payload.data.items
        }
      } else {
        stateData[payload.channel] = {
          hasData: false,
          items: []
        }
      }
      Vue.set(state[payload.name], payload.period, stateData)
    }
  },

  saveDataEntity(state, payload) {
    if (payload.hasData) {
      let stateData = {...state[payload.name][payload.period].data}
      Vue.set(state[payload.name], payload.period, {
        hasData: true,
        data: {
          ...stateData,
          [payload.type]: payload.data.summary
        }
      })
    }
  },

  saveDataDevices(state, payload) {
    if (payload.name === 'summaryDevices') {
      if (payload.hasData) {
        Vue.set(state[payload.name], payload.type, {
          hasData: true,
          data: payload.data.summary
        })
      } else {
        Vue.set(state[payload.name], payload.type, {
          hasData: false,
          data: {}
        })
      }
    } else {
      if (payload.hasData) {
        Vue.set(state[payload.name], payload.type, {
          hasData: true,
          items: payload.data.items
        })
      } else {
        Vue.set(state[payload.name], payload.type, {
          hasData: false,
          items: []
        })
      }
    }
  },

  saveDataEntityChannel(state, payload) {
    if (payload.hasData) {
      let stateData = {...state[payload.name][payload.period].data}
      Vue.set(state[payload.name], payload.period, {
        hasData: true,
        data: {
          ...stateData,
          [payload.type]: {
            ...stateData[payload.type],
            [payload.channel]: payload.data.summary
          }
        }
      })
    }
  },

  saveWidgetConfig(state, payload) {
    if (payload.hasData) {
      state.widgetConfig = {
        projectId: payload.projectId,
        data: payload.data
      }
    } else {
      state.widgetConfig = {
        projectId: undefined,
        data: []
      }
    }
  },

  saveTopRegions(state, payload) {
    if (payload.hasData) {
      Vue.set(state.topRegions, payload.metric, {
        hasData: true,
        items: payload.data.items
      })
    } else {
      Vue.set(state.topRegions, payload.metric, {
        hasData: false,
        items: []
      })
    }
  },

  resetState(state) {
    state = getDefaultState()
  }
}

export default mutations
