<template>
  <div class="dashboard">
    <header class="dashboard-header" v-if="header" ref="header">
      <slot name="header">
        <v-header v-resize="resize"></v-header>
      </slot>
    </header>

    <div class="dashboard__data">
      <div class="dashboard-aside" v-if="aside" ref="aside">
        <slot name="aside">
          <v-aside
              class="dashboard__aside-sticky"
              v-resize="resize"
              :style="styleAside"
          />
        </slot>
      </div>

      <div
          class="dashboard-container _column _main"
          :style="styleContent"
      >
        <div class="dashboard-content" ref="container">
          <slot/>
        </div>

        <v-footer class="dashboard__footer" />
      </div>
    </div>
  </div>
</template>

<script>
import resize from 'vue-resize-directive'
import ru from 'moment/locale/ru'

export default {
  directives: {
    resize,
  },

  props: {
    aside: {
      type: Boolean,
      default: true
    },

    header: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      sizeAsideWidth: 0,
      sizeContainer: 0,
      sizeHeaderHeight: 0
    }
  },

  computed: {
    styleAside() {
      return `top: ${this.sizeHeaderHeight}px; height: calc(100vh - ${this.sizeHeaderHeight}px)`
    },

    styleContent () {
      return {
        width: `calc(100% - ${this.sizeAsideWidth}px)`
      }
    }
  },

  created() {
    this.$moment.locale('ru')
  },

  mounted() {
    this.resize()
  },

  methods: {
    resize() {
      if (this.aside) {
        this.sizeAsideWidth = this.$refs.aside ? this.$refs.aside.clientWidth : 0
      }

      if (this.header) {
        this.sizeHeaderHeight = this.$refs.header ? this.$refs.header.clientHeight : 0
      }

      this.$sizes.saveSize('containerWidth', this.$refs.container.clientWidth);
      this.$sizes.saveSize('containerHeight', this.$refs.container.clientHeight);
      this.$sizes.saveSize('asideWidth', this.sizeAsideWidth);
      this.$sizes.saveSize('headerHeight', this.sizeHeaderHeight)
    }
  }
};
</script>

<style lang="scss" scoped>
.dashboard {
  width: 100%;
  min-width: 1440px;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__footer {
    width: 100%;
    margin-top: auto;
    flex-shrink: 0;
  }

  &__data {
    width: 100%;
    max-width: 100%;
    display: flex;
    align-content: flex-start;
    flex-wrap: nowrap;
  }
}

.dashboard-header {
  border-bottom: 1px solid #E0E6F0;
  box-shadow: 0 0 4px rgba(146, 157, 176, 0.2);
  z-index: 101;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
}

.dashboard-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  &._column {
    flex-direction: column;
  }

  &._pt {
    padding-top: 17px;
  }

  &._pb {
    padding-bottom: 47px;
  }

  &._main {
    min-height: calc(100vh - 57px);
  }
}

.dashboard-content {
  width: 100%;
}

.dashboard-aside {
  z-index: 100;
  flex-shrink: 0;
}

.dashboard__aside-sticky {
  height: auto;
  position: sticky;
  left: 0;
}
</style>
