const state = {
    /* Список листингов */
    listingList: {
        has_data: false,
        items: [],
        total: 0
    },

    /* Список карточек */
    cardList: {
        has_data: false,
        items: [],
        total: 0
    },

    /* Данные итого */
    summary: {
        has_data: false,
        data: {}
    }
}

export default state
