import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import Url from 'url-parse'

import {logOut, isLoggedInAndRefresh} from '@/function/auth.js'

import ROUTE_NAME from './routeName'

/* Модули */
import {bi} from './sections/bi.js'
import {segments} from './sections/segments.js'

const clearContainer = () => import('@/services/clearContainer.vue')


/* Проверка аутентификации */
let beforeEnterIndex = async function (config, to, from, next) {
    if (await isLoggedInAndRefresh(config)) {
        next({ name: ROUTE_NAME.DASHBOARD })
    } else {
        next()
    }
};

/* Сквозной переход */
let beforeEnterRedirect = async function (config, to) {
    let url = Url(to.query.q || '/');
    if (url.hostname === window.location.hostname) {
        url.set('host', Url(config.mainUrl).host)
    }

    location.assign(url.href);
}

/* Разлогирование */
let beforeEnterLogOut = async function (config, to, from, next) {
    if (await logOut(config)) {
        next({ name: ROUTE_NAME.LOGIN, query: { to: from.fullPath } })
    } else {
        next()
    }
};


/* Проверка прав доступа к проекту */
let beforeEnterDashboard = async function (config, to, from, next) {
    if (await isLoggedInAndRefresh(config)) {
        next()
    } else {
        next({name: ROUTE_NAME.LOGIN, query: {to: to.fullPath}})
    }
};


/* Центральный роутинг */
export function router(config, i18n) {
    const routes = [
        /* Сводная страница */
        {
            path: '/',
            component: () => import('../landing/view/'),
            beforeEnter: (...args) => beforeEnterIndex(config, ...args),
            children: [
                {
                    name: ROUTE_NAME.INDEX,
                    path: '/',
                    component: () => import('../landing/index/')
                },
                {
                    name: ROUTE_NAME.HELP,
                    path: '/help',
                    component: () => import('../landing/help/')
                },
                {
                    name: ROUTE_NAME.ABOUT_TEAM,
                    path: '/about/team',
                    component: () => import('../landing/team/')
                },
                {
                    name: ROUTE_NAME.CONTACTS,
                    path: '/contacts',
                    component: () => import('../landing/contacts/')
                },
                {
                  name: ROUTE_NAME.CONFIDENTIAL,
                  path: '/confidential',
                  component: () => import('../landing/confidential/')
              }
            ]
        },
        {
            name: ROUTE_NAME.LANDING,
            path: '/landing',
            component: () => import('../landing/'),
            redirect: '/'
        },
        {
            name: ROUTE_NAME.DEMO,
            path: '/demo',
            component: () => import('../services/demo/')
        },
        /* Сквозной переход (требуется для верификации урлов в меню) */
        {
            name: ROUTE_NAME.REDIRECT,
            path: '/redirect',
            beforeEnter: (...args) => beforeEnterRedirect(config, ...args)
        },

        /* Страница аутентификации */
        {
            name: ROUTE_NAME.AUTH,
            path: '/auth',
            component: clearContainer,
            children: [
                {
                    name: ROUTE_NAME.LOGIN,
                    path: '/login',
                    component: () => import('../services/auth/pages/login.vue')
                },
                {
                    name: ROUTE_NAME.LOGOUT,
                    path: '/logout',
                    beforeEnter: (...args) => beforeEnterLogOut(config, ...args)
                }
            ]
        },

        /* Дашборд */
        {
            name: ROUTE_NAME.DASHBOARD,
            path: '/dashboard',
            component: () => import('../services/dashboard/container/'),
            beforeEnter: (...args) => beforeEnterDashboard(config, ...args),
            redirect: { name: ROUTE_NAME.QUICK_START },
            children: [
              {
                name: ROUTE_NAME.INDEX_CATALOG,
                path: '/catalog/:activeProject(\\d+)?',
                component: () => import('../services/dashboard/pages/indexCatalog/')
              },
              {
                name: ROUTE_NAME.TRAFFIC_FUNNEL,
                path: '/traffic-funnel/:activeProject(\\d+)?',
                redirect: to => {
                  return {
                    name: ROUTE_NAME.INDEX_CATALOG,
                    params: {activeProject: to.params.activeProject}
                  }
                }
              },
              {
                  name: ROUTE_NAME.TASKS,
                  path: '/tasks',
                  component: () => import('../services/dashboard/pages/tasks/pages/create')
              },
              {
                  name: ROUTE_NAME.LISTINGS,
                  path: '/categories/:activeProject(\\d+)?',
                  component: () => import('../services/dashboard/pages/listings/')
              },
              {
                  name: ROUTE_NAME.CARDS,
                  path: '/products/:activeProject(\\d+)?',
                  component: () => import('../services/dashboard/pages/cards/')
              },
              {
                  name: ROUTE_NAME.PROJECT_CREATE,
                  path: '/project-setting',
                  component: () => import('../services/dashboard/pages/projects/form/')
              },
              {
                  name: ROUTE_NAME.PROJECT_LIST,
                  path: '/project-data',
                  component: () => import('../services/dashboard/pages/projects/list/')
              },
              {
                  name: ROUTE_NAME.QUICK_START,
                  path: '/quick-start/:activeProject(\\d+)?',
                  component: () => import('../services/dashboard/pages/quickStart/')
              },
              {
                  name: ROUTE_NAME.ROI_FUNNEL,
                  path: '/roi-funnel',
                  component: () => import('../services/dashboard/pages/roiFunnel/')
              },
              {
                  name: ROUTE_NAME.PREDICTION_FUNNEL,
                  path: '/prediction-funnel',
                  component: () => import('../services/dashboard/pages/predictionFunnel/')
              },
              {
                  name: ROUTE_NAME.CONTENT_ANALYSIS,
                  path: '/content-analysis/:activeProject(\\d+)?',
                  component: () => import('../services/dashboard/pages/contentAnalysis/')
              },
              {
                  name: ROUTE_NAME.COMMERCE_ANALYSIS,
                  path: '/commerce-analysis/:activeProject(\\d+)?',
                  component: () => import('../services/dashboard/pages/commerceAnalysis/')
              },
              {
                  name: ROUTE_NAME.BRAND_ANALYSIS,
                  path: '/brand-analysis/:activeProject(\\d+)?',
                  component: () => import('../services/dashboard/pages/brandAnalysis/')
              },
              {
                  name: ROUTE_NAME.K50_FEED,
                  path: '/k50/feed-rules/:activeProject(\\d+)?',
                  component: () => import('../services/dashboard/pages/k50/feed/')
              },
              {
                  name: ROUTE_NAME.K50_REPORT,
                  path: '/k50/report/:activeProject(\\d+)?',
                  component: () => import('../services/dashboard/pages/k50/report/')
              },
              {
                  name: ROUTE_NAME.INSIGHTS_ANALYTICS,
                  path: '/insights-analytics/:activeProject(\\d+)?',
                  component: () => import('../services/dashboard/pages/insightsAnalytics/')
              },
              {
                  name: ROUTE_NAME.CONTENT_INSIGHTS,
                  path: '/content-insights/:activeProject(\\d+)?',
                  component: () => import('../services/dashboard/pages/contentInsights/')
              },
              {
                  name: ROUTE_NAME.ABC_ANALYSIS,
                  path: '/abc-analysis/:activeProject(\\d+)?',
                  component: () => import('../services/dashboard/pages/abcAnalysis/')
              }
            ].concat([
                bi(i18n),
                segments()
            ])
        },
    ]

    return new Router({
        mode: 'history',
        base: process.env.BASE_URL,
        scrollBehavior (to, from) {
            if (to.hash) {
              return {selector: to.hash}
            } else if (from.path === to.path) {
                return {}
            } else {
                return { x: 0, y: 0 }
            }
        },
        routes
    })
}
