<template>
  <div class="metric-modal">
    <div class="metric-modal__title">Фильтр: {{ metricName }} {{ diff ? '- дельта' : undefined }}</div>

    <div
        v-if="types.includes(FILTER_TYPE.RANGE)"
        class="metric-modal__row"
    >
      <div class="metric-modal__item _mr-8">
        <div class="metric-modal__label">Значение</div>
        <s-input
            v-model="from"
            min-width="116px"
            max-width="116px"
            type="number"
            clear-button
            :error="$v.from.$invalid"
            :on-keydown-func="checkIsNumericOnKeydown"
            :on-paste-func="checkIsNumericOnPaste"
        >
          <template #label>
            <div class="metric-modal__input-label">
              <span>От</span>
            </div>
          </template>
        </s-input>
      </div>

      <div class="metric-modal__item">
        <s-input
            v-model="to"
            min-width="116px"
            max-width="116px"
            type="number"
            clear-button
            :error="$v.to.$invalid"
            :on-keydown-func="checkIsNumericOnKeydown"
            :on-paste-func="checkIsNumericOnPaste"
        >
          <template #label>
            <div class="metric-modal__input-label">
              <span>До</span>
            </div>
          </template>
        </s-input>
      </div>
    </div>

    <div
        v-if="types.includes(FILTER_TYPE.ABC)"
        class="metric-modal__row"
    >
      <div class="metric-modal__item _mr-8">
        <div class="metric-modal__label">ABC-категории</div>
        <s-multiselect
            v-model="abcCategory"
            clear-button
            :options="abcCategories"
            placeholder="Выберите категорию"
            show-checkboxes
        />
      </div>
    </div>

    <div class="metric-modal-buttons">
      <v-button
          class="metric-modal__button"
          name="Применить"
          :disabled="$v.from.$invalid || $v.to.$invalid"
          @click="applyFilter"
      />
      <v-button
          class="metric-modal__button"
          type="text-base"
          name="Отменить"
          @click="$modal.close()"
      />
    </div>
  </div>
</template>

<script>
import {findOptions} from '@/function'
import updateUrl from '@/mixins/updateUrl'

const regexpForbidden = [
  /^0+\d+$/,
  /^0+\d+[\.,]\d*$/,
  /^[\.,]\d+$/
]

/* Валидаторы значений метрики */
const maxValue = max => value => (!value && value !== '0') || !max || +value <= max
const minValue = min => value => (!value && value !== '0') || !min || +value >= min
const regexpTest = value => regexpForbidden.every(regexp => !regexp.test(value))

const FILTER_TYPE = {
  RANGE: 'range',
  ABC: 'abc'
}

const abcCategories = [
  {id: 'a', name: 'A'},
  {id: 'b', name: 'B'},
  {id: 'c', name: 'C'}
]

export default {
  name: 'metricFilter',

  mixins: [updateUrl],

  props: {
    metric: {
      type: Object,
      required: true
    },

    diff: {
      type: Boolean,
      default: false
    },

    types: {
      type: Array,
      default: () => ([FILTER_TYPE.RANGE]),
      validator: value => Array.isArray(value) && value.every(item => [FILTER_TYPE.RANGE, FILTER_TYPE.ABC].includes(item))
    }
  },

  data () {
    return {
      FILTER_TYPE,
      abcCategories,
      from: undefined,
      to: undefined,
      abcCategory: []
    }
  },

  computed: {
    metricName () {
      return this.metric.name.replace('<br>', '')
    }
  },

  validations () {
    return {
      from: {
        maxValue: maxValue(+this.to),
        regexpTest
      },
      to: {
        minValue: minValue(+this.from),
        regexpTest
      }
    }
  },

  created() {
    const routeQuery = {...this.$route.query}
    this.from = routeQuery[`${this.metric.filterName}${this.diff ? '_diff' : ''}_from`]
    this.to = routeQuery[`${this.metric.filterName}${this.diff ? '_diff' : ''}_to`]

    let queryAbcCategory = routeQuery[`${this.metric.filterName}_category`]

    if (queryAbcCategory) {
      queryAbcCategory = queryAbcCategory.split('')

      queryAbcCategory.forEach(item => {
        if (findOptions(item, abcCategories) && !this.abcCategory.includes(item)) {
          this.abcCategory.push(item)
        }
      })
    }
  },

  methods: {
    applyFilter () {
      let newQuery = {}

      if (this.types.includes(FILTER_TYPE.RANGE)) {
        newQuery[`${this.metric.filterName}${this.diff ? '_diff' : ''}_from`] = this.from || undefined
        newQuery[`${this.metric.filterName}${this.diff ? '_diff' : ''}_to`] = this.to || undefined

        if (this.$route.query[`${this.metric.filterName}${this.diff ? '_diff' : ''}_from`] !== this.from || this.$route.query[`${this.metric.filterName}${this.diff ? '_diff' : ''}_to`] !== this.to) {
          newQuery.page = undefined
        }
      }

      if (this.types.includes(FILTER_TYPE.ABC)) {
        newQuery[`${this.metric.filterName}_category`] = this.abcCategory.join('')
      }

      this.updateUrl(newQuery)
      this.$modal.close()
    },

    checkIsNumericOnKeydown(e) {
      const keyCode = ('which' in e) ? e.which : e.keyCode
      const isNotNumeric = (keyCode === 69 || keyCode === 101)

      if (isNotNumeric) {
        e.preventDefault()
      }
    },

    checkIsNumericOnPaste(e) {
      const clipboardData = e.clipboardData || window.clipboardData
      const pastedData = clipboardData
          .getData('Text')
          .toUpperCase()

      if (pastedData.includes('E')) {
        e.stopPropagation()
        e.preventDefault()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.metric-modal {
  width: calc(90vw - 46px);
  max-width: 394px;

  &__title {
    padding: 32px 32px 24px;
    margin-bottom: 28px;
    border-bottom: 1px solid #E0E6F0;
    color: #455165;
    font-size: 20px;
    font-weight: 500;
    overflow-wrap: break-word;
  }

  &__row {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    row-gap: 10px;
    padding: 0 32px 32px;
    border-bottom: 1px solid #E0E6F0;
  }

  &__label {
    margin-bottom: 4px;
    color: #7D7D7D;
    font-size: 12px;
  }

  &__item {
    margin-right: 20px;

    &._mr-8 {
      margin-right: 8px;
    }
  }

  &-buttons {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    padding: 32px;
  }

  &__button {
    flex-shrink: 0;

    &:not(:last-child) {
      margin-right: 17px;
    }
  }

  &__input-label {
    padding-right: 4px;

    span {
      display: inline-block;
      width: 28px;
      padding-right: 11px;
      border-right: 1px solid #E0E6F0;
      line-height: 30px;
    }
  }
}
</style>
