import Vue from 'vue'

const mutations = {
    setTasksConfig(state, data) {
        if (data.type) {
            Vue.set(state, 'taskTypes', data.type)
        }
    },
}

export default mutations