import Vue from 'vue'

const mutations = {
    /* Сохранение списка сущностей */
    saveSegments (state, payload) {
        if (payload.hasData) {
            Vue.set(state, 'segments', {
                has_data: true,
                items: payload.data.items || [],
                total: payload.data.total || 0
            })
        } else {
            Vue.set(state, 'segments', {
                has_data: false,
                items: [],
                total: 0
            })
        }
    },

    /* Сохранение данных итого */
    saveSummary (state, payload) {
        if (payload.hasData) {
            Vue.set(state, 'summary', {
                has_data: true,
                data: payload.data || {}
            })
        } else {
            Vue.set(state, 'summary', {
                has_data: false,
                data: {}
            })
        }
    }
}

export default mutations
