import {generateRoute} from '@/function'

export default {
  methods: {
    /* Обновление URL фильтров */
    updateUrl (query, replace) {
      let newRoute = generateRoute(this.$route)
      let isEqual = function (query) {
        return !Object
          .keys(query)
          .some(key => (newRoute.query[key] || query[key]) && String(newRoute.query[key]) !== String(query[key]))
      }

      if (!isEqual(query)) {
        this.$router[replace ? 'replace' : 'push']({
          query: Object.assign(newRoute.query, query),
          params: {noscroll: true}
        })
      }
    }
  }
}
