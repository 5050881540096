<template>
  <div class="dialog">
    <div
      class="dialog__shadow"
      data-test="dialog_shadow"
      @click="dismissDialog"
    ></div>
    <div class="dialog-content">
      <div
        v-if="title"
        class="dialog__title"
        data-test="dialog_title"
      >
        {{ title }}
      </div>
      <div
        v-if="text"
        class="dialog__text"
        data-test="dialog_text"
      >
        {{ text }}
      </div>
      <div
        v-if="html"
        class="dialog__text"
        data-test="dialog_html"
        v-html="html"
      ></div>
      <div class="dialog__buttons">
        <v-button
          v-if="showCancelButton"
          ref="dialogCancelBtn"
          :name="buttonTextCancel || $t('modules.pages')['Cancel']"
          type="text-base"
          @click="dismissDialog"
        />

        <v-button
          ref="dialogConfirmBtn"
          :name="buttonTextConfirm"
          type="main"
          :background="actionDelete ? '#F44336' : undefined"
          data-test="dialog_confirm_button"
          @click="confirmDialog"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    text: {
      type: String,
      default: ''
    },

    html: {
      type: String,
      default: ''
    },

    buttonTextConfirm: {
      type: String,
      default: 'OK'
    },

    buttonTextCancel: {
      type: String,
      default: ''
    },

    showCancelButton: {
      type: Boolean,
      default: true
    },

    actionDelete: {
      type: Boolean,
      default: false
    },

    onConfirm: {
      type: Function
    },

    onDismiss: {
      type: Function
    }
  },

  methods: {
    confirmDialog () {
      if (this.onConfirm) {
        this.onConfirm()
      }
    },

    dismissDialog () {
      if (this.onDismiss) {
        this.onDismiss()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9997;
  overflow-y: scroll;
  background: rgba(0, 0, 0, .5);

  &__shadow {
    width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &-content {
    min-width: 370px;
    max-width: 640px;
    padding: 24px;
    border-radius: 6px;
    text-align: left;
    z-index: 101;
    background: #FFFFFF;
    box-shadow: 0 0 24px rgba(146, 157, 176, .22), 0 24px 24px rgba(146, 157, 176, .3);
  }

  &__title {
    width: calc(100% - 24px);
    margin-bottom: 5px;
    line-height: 28px;
    font-weight: 500;
    font-size: 20px;
    color: #2D3646;
  }

  &__text {
    width: calc(100% - 24px);
    margin-bottom: 30px;
    line-height: 24px;
    overflow-wrap: break-word;
    color: #455165;
    font-size: 16px;
  }

  &__buttons {
    display: flex;
    column-gap: 10px;
    justify-content: right;
  }
}
</style>
