<template>
  <vue-simple-table
      class="metrics-table _custom-scrollbar"
      small-padding
      head-background
      :hoverable-rows="false"
  >
    <template #head>
      <vue-line>
        <vue-item
            v-for="(cell, i) of headCells"
            :key="i"
            class="metrics-table__cell"
            :align="i === 3 ? 'center' : 'left'"
        >
          {{ cell }}
        </vue-item>
      </vue-line>
    </template>

    <template #body>
      <vue-line
          v-for="(metric, i) of metrics"
          :key="i"
      >
        <vue-item
            class="metrics-table__cell _index"
            align="right"
        >
          {{ ++i }}
        </vue-item>
        <vue-item class="metrics-table__cell">
          {{ metric.name }}
        </vue-item>
        <vue-item class="metrics-table__cell">{{ metric.parentName }}</vue-item>
        <vue-item
            class="metrics-table__cell"
            align="center"
        >
          <v-button
              class="metrics-table__delete"
              type="text-base"
              icon="delete"
              padding-right="0"
              padding-left="0"
              width="24px"
              height="24px"
              :disabled="metric.disabled"
              :data-test="`delete_${metric.id}`"
              @click="deleteMetric(metric)"
          />
        </vue-item>
      </vue-line>
    </template>
  </vue-simple-table>
</template>

<script>
export default {
  name: 'metricsList',

  props: {
    metrics: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {
      headCells: [
          '#',
          'Метрика',
          'Группа',
          'Удалить'
      ]
    }
  },

  methods: {
    deleteMetric (metric) {
      let data = {[metric.id]: false}

      if (!this.metrics.filter(item => item.parentId === metric.parentId && item.id !== metric.id).length) {
        data[metric.parentId] = false
      }

      this.$emit('delete-metric', data)
    }
  }
}
</script>

<style scoped lang="scss">
.metrics-table {
  height: 396px;
  overflow-y: scroll;

  &__cell {
    border-right: 0;
    border-left: 0;
    color: #2D3646;
    font-size: 14px !important;

    &._index {
      font-size: 12px !important;
    }
  }

  &__delete {
    & /deep/ .button__icon {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
