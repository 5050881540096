function state () {
  return {
    projects: {
      hasData: false,
      items: [],
      limit: 100,
      offset: 0,
      next: true,

      search: {
        limit: 100,
        offset: 0,
        next: true,
        items: []
      }
    }
  }
}

export default state
