import constants from '@/const/'
import errors from '@/store/errors.js'

export default {
  getFrequency ({rootState, commit}, input) {
    return new Promise(async (resolve, reject) => {
      const requestFrequency = async ({ query, callback }) => {
        return rootState.request({
          type: constants.METHODS.GET,
          url: '/bi/frequency',
          apiName: 'seowork',
          auth: true,
          query: query
        })
      }

      if (input.search_engines && input.search_engines.length) {
        let result = {}
        for (let item of input.search_engines) {
          try {
            result[item.id] = await requestFrequency({
              query: {
                ...input.query,
                search_engine: item.id
              }
            })
          } catch (_) {}
        }
        await commit('setFrequencySE', result)
        resolve(true)
      } else {
        try {
          let result = await requestFrequency({ query: input.query })
          await commit('setFrequency', result)
          resolve(true)
        } catch (_) {
          reject(new Error(errors.GET_DATA))
        }
      }
    })
  },

  getTraffic ({rootState, commit}, input) {
    return new Promise((resolve, reject) => {
      rootState.request({
        type: constants.METHODS.GET,
        url: '/bi/traffic/segments-date',
        apiName: 'seowork',
        auth: true,
        query: input.query
      }).then(result => {
        commit('setTraffic', {
          searchEngine: input.query.search_engine,
          result
        })
        resolve(true)
      }, () => {
        reject(new Error(errors.GET_DATA))
      })
    })
  },

  /* Запрос данных по проекту */
  getProject: async ({state, commit, getters, rootState}, input) => {
    await rootState.request({
      type: constants.METHODS.GET,
      apiName: 'seowork',
      url: '/projects/additional',
      auth: true,
      query: input.query
    }).then(result => {
      commit('getProject', {})
      if (result.data && result.data.id) {
        commit('getProject', result.data)
        return true
      } else {
        throw new Error(errors.NO_DATA_AVAILABLE)
      }
    }, () => {
      throw new Error(errors.GET_DATA)
    })
  },
}
