import Vue from 'vue'

const mutations = {
    /* Сохранение списка сущностей */
    saveItems (state, payload) {
        if (payload.has_data) {
            const items = payload.data && payload.data.items
            Vue.set(state, `${payload.type}List`, {
                has_data: !!(items && items.length),
                items: items || [],
                total: payload.data.total || 0
            })
        } else {
            Vue.set(state, `${payload.type}List`, {
                has_data: false,
                items: [],
                total: 0
            })
        }
    },

    /* Сохранение данных итого */
    saveSummary (state, payload) {
        if (payload.hasData) {
            Vue.set(state, 'summary', {
                has_data: true,
                data: payload.data || {}
            })
        } else {
            Vue.set(state, 'summary', {
                has_data: false,
                data: {}
            })
        }
    }
}

export default mutations
