<template>
  <div class="projects">
    <s-select
      v-bind="{
        placeholder: 'Выберите проект',
        viewOptionsName: 'label',
        viewOptionsNameList: 'label',
        search: true,
        staticWidthDrop: '350px',
        appendToBody: false,
        options: projects.list,
        searchOptions: projects.search.items,
        lazyLoad: lazyLoadList,
        searchLoad: lazyLoadList
      }"
      v-model="activeProjectValue"
      @input="editActiveProject($event)"
    >
      <template v-slot:value="{valueReal}">
        <div class="projects__value">
          <img class="projects__logo" :src="'https://favicon.yandex.net/favicon/v2/' + valueReal.host.name" :alt="valueReal.label">
          {{ valueReal.label }}
        </div>
      </template>
      <template v-slot:list-item="{data}">
        <span class="select-item__data">
            <img
              class="projects__logo"
              width="16"
              :src="'https://favicon.yandex.net/favicon/v2/' + data.host.name" :alt="data.label"
            />
            {{ data.label }}
        </span>
      </template>
    </s-select>
  </div>
</template>

<script>
import { findKey, generateRoute } from '@/function'
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    customUpdatePage: {
      type: Function
    }
  },

  data () {
    return {
      activeProjectValue: null
    }
  },

  watch: {
    async $route (to, from, ...args) {
      /* Установка активного проекта */
      await this.initProjectId()
    }
  },

  created () {
    /* Установка активного проекта */
    this.initProjectId()

    /* Загрузка списка проектов */
    this.initData()
  },

  computed: {
    ...mapState({
      /* ID активного проекта */
      activeProjectState: state => Number(state.active_project),

      /* Данные активного проекта */
      activeProjectData: state => state.active_project_data,

      /* Конфигурация приложения */
      config: state => state.config,

      /* Список доступных проектов */
      projects: state => state.options.projects
    }),

    ...mapGetters([
      'getActiveProjectInMenu'
    ]),

    /* ID активного проекта в URL */
    activeProjectId () {
      let id = this.$route.params.activeProject
      return id ? +id : undefined
    },

    /* ID активного проекта в query параметрах */
    activeProjectIdQuery () {
      let id = this.$route.query.project_id
      return id ? +id : undefined
    },

    needingProjects () {
      try {
        return this.$route.matched[this.$route.matched.length - 1].path.indexOf('activeProject') !== -1
      } catch (_) {
        return false
      }
    }
  },

  methods: {
    /* Установка ID проекта и редирект на страницу с установленным проектом */
    initProjectId () {
      if (this.needingProjects && !this.activeProjectId) {
        this.$router.push({
          params: {
            activeProject: this.activeProjectIdQuery || this.activeProjectState
          },
          query: {
            ...this.$route.query
          }
        })
      } else {
        /* Запись активного проекта в селектор */
        this.setActiveProject()
      }
    },

    /* Установка активного проекта в селектор */
    setActiveProject (value) {
      this.$set(this, 'activeProjectValue', value || this.activeProjectId)
    },

    /* Изменение активного проекта */
    editActiveProject (id) {
      if (this.needingProjects && this.activeProjectId !== id) {
        this.$store.commit('setCancelRequests', true)
        this.redirect(id)
      } else if (this.activeProjectState !== id) {
        this.$store.commit('initActiveProject', id)
      }
    },

    /* Редирект на новую страницу */
    redirect (newProjectId, replace) {
      if (this.customUpdatePage) {
        this.customUpdatePage(newProjectId)
      } else {
        /* Создание нового роута */
        let newRoute = generateRoute(this.$route)

        /* Установка активного проекта */
        newRoute.params.activeProject = String(newProjectId)
        newRoute.params.updateActiveProject = true

        /* Удаление фильтров */
        newRoute.query = {}

        /* Редирект на страницу активного проекта */
        if (replace) {
          this.$router.replace(newRoute)
        } else {
          this.$router.push(newRoute)
        }
      }
    },

    /* Загрузка данных */
    initData () {
      (async () => {
        if (!this.projects.list || !this.projects.list.length) {
          await this.lazyLoadList()
        }

        let keyProject
        while (
          this.activeProjectData.id &&
          this.projects.next &&
          (keyProject === undefined || keyProject + 1 >= this.projects.offset)
        ) {
          keyProject = findKey(this.activeProjectState, this.projects.list)
          if (this.projects.next && (keyProject === undefined || keyProject + 1 >= this.projects.offset)) {
            await this.lazyLoadList()
          }
        }

        if (!this.activeProjectId) {
          this.editActiveProject(this.projects.list[0].id)
        }
      })()
    },

    /* Загрузка списка проектов */
    lazyLoadList (data) {
      return this.$store.dispatch('getProjects', data)
    }
  }
}
</script>

<style lang="scss" scoped>
  .projects {
    margin-right: auto;
    margin-left: 20px;

    &__value {
      display: flex;
      align-items: center;

      img {
        margin-right: 6px;
      }
    }

    &__logo {
      vertical-align: middle;
      margin-right: 6px;
    }
  }
</style>
