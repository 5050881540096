import Vue from 'vue'
import container from './container/'
import { router } from '../router/index.js';
import store from '../store/'

/* Импорт библиотек */
import * as libs from './importLibs.js'

/* Импорт компонентов */
import * as components from './importComponents.js';

/* Импорт фильтров */
import * as filters from './importFilters.js'

/* Функция запроса */
import {init, request} from '../function/request.js';
import VueIntercom from 'vue-intercom'

import VueI18n from 'vue-i18n'
import en from '@/languages/en.js'
import ru from '@/languages/ru.js'

Vue.use(VueI18n)

Vue.config.productionTip = false

Vue.http.get('/config.json').then(response => {
    /* Конфигурация приложения */
    let config = {...response.data};

    if (window.location.hostname.startsWith('stand')) {
        let newApi = {...config.api}
        let keys = ['main', 'mainApi', 'dataforce']

        keys.forEach(key => {
            newApi[key] = config.api[key].replace('api.', 'api-stand.')
        })

        config = {
            ...config,
            api: {
                ...config.api,
                ...newApi
            },
            intercom_id: ''
        }
    }

    /* Запись конфигурации в хранилище */
    store.state.config = config;

    /* Инициализация функции запроса */
    store.state.request = init(config).request;

    if(config.intercom_id && config.intercom_id !== "") {
        Vue.use(VueIntercom, {
            appId: config.intercom_id,
            language_override: config.language
        })
    }

    /* Кастомная установка языка приложения */
    try {
        let pathUrl = new URL(location.href)
        let langQuery = pathUrl.searchParams.get('lang')
        if (langQuery) {
            config.language = ['en', 'ru'].indexOf(langQuery) !== -1 ? langQuery : undefined
        } else if (!config.language) {
            config.language = 'en'
        }
    } catch (_) {
    }

    let i18n = new VueI18n({
        locale: config.language,
        fallbackLocale: 'en',
        messages: {
            en,
            ru
        }
    })

	window.vue = new Vue({
		store,
        i18n,
        router: router(config, i18n),
		render: h => h(container)
	}).$mount('#app')
}, error => {
    alert('Not found config')
});
