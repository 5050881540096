const state = {
    /* Список сегментов */
    segments: {
        has_data: false,
        items: [],
        total: 0
    },

    /* Данные итого */
    summary: {
        has_data: false,
        data: {}
    }
}

export default state
