import {METHODS} from '@/const/'
const ERRORS_PATH = 'modules.error_message'

const actions = {
  getProjects ({state, commit, rootState}, input) {
    return new Promise((resolve, reject) => {
      let projects = {...state.projects}
      let query = {}

      if (input) {
        query.all = input.all
        query.client_id = input.client_id
      }

      if (input && input.search) {
        if (input.firstPage) {
          commit('clearProjectSearch')
          projects = {...state.projects}
        }

        query.limit = projects.search.limit
        query.offset = projects.search.offset
        query.q = input.search
      } else {
        if (!state.projects.next) {
          resolve({next: false})
        }

        if (input && input.projectId) {
          query.project_id = input.projectId
        } else {
          query.limit = projects.limit
          query.offset = projects.offset
        }
      }

      rootState.request({
        type: METHODS.GET,
        url: '/projects',
        auth: true,
        apiName: 'main',
        query
      }).then(result => {
        if (result && result.has_data && result.data && result.data.items) {
          const name = input && input.search ? 'saveProjectSearch' : 'saveProjects'
          commit(name, {
            hasData: true,
            items: result.data.items || [],
            projectId: input && input.projectId && !input.search ? input.projectId : undefined,
            next: result.data.has_more
          })
        }
        if (input && input.projectId) {
          resolve(true)
        } else {
          resolve({next: result && result.data && result.data.has_more && result.data.items && !!result.data.items.length})
        }
      }, (_) => {
        reject(new Error(rootState.i18n.t(ERRORS_PATH).GET_DATA))
      })
    })
  }
}

export default actions
