import comparison from './comparison/'
import segments from './segments/'
import compareChannels from './compareChannels/'
import comparePeriods from './comparePeriods/'
import overview from './overview/'

export default {
  namespaced: true,
  modules: {
    comparison,
    segments,
    compareChannels,
    comparePeriods,
    overview
  }
}
