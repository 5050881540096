import Vue from 'vue'
import {findKey} from '@/function'

const mutations = {
    /* Сохранение выбранных метрик в таблице */
    saveTableCellsConfig (state, {newConfig, key}) {
        let config = [...state[key]]

        config.forEach(section => {
            section.visible = newConfig[section.id]

            section.items.forEach(metric => metric.visible = newConfig[metric.id])
        })

        Vue.set(state, key, config)
        localStorage.setItem(key, JSON.stringify(newConfig))
    },

    setFrequencyTypeNames (state, payload) {
        let tableCellsConfig = [...state[payload.key]]
        if (payload && payload.properties && payload.properties.ws) {
            let metricsDemandIdx = findKey('demand', tableCellsConfig)

            tableCellsConfig[metricsDemandIdx].items.map(item => {
                if (payload.properties.ws[item.id]) {
                    item.name = payload.properties.ws[item.id].name
                }
                return item
            })

            Vue.set(state, payload.key, tableCellsConfig)
        }
    }
}

export default mutations
