import Vue from 'vue'

import constants from '@/const/'
import errors  from '@/store/errors'

const actions = {
    /* Создание проекта */
    createTask({rootState}, input) {
        return new Promise((resolve, reject) => {
            rootState.request({
                type: constants.METHODS.POST,
                url: '/task',
                auth: true,
                body: input,
                logs: true,
                apiName: 'mainApi'
            }).then(result => {
                resolve(result)
            }, error => {
                try {
                    if (error.body.data.errors) {
                        throw error.body.data.errors
                    } else {
                        throw errors.GET_DATA
                    }
                } catch(error) {
                    reject(error)
                }
            })
        })
    },

    /* Получение списка настроек для задания */
    getTasksConfig({commit, rootState}) {
        return new Promise((resolve, reject) => {
            rootState.request({
                type: constants.METHODS.GET,
                url: '/task/config',
                auth: true,
                apiName: 'mainApi'
            }).then(result => {
                commit('setTasksConfig', result.data.items)
                resolve(true)
            }, error => {
                reject(errors.GET_DATA)
            })
        })
    },

    /* Получение списка проектов */
    getProjects: function ({state, commit, getters, rootState}, input) {
        return new Promise((resolve, reject) => {
            if (state.projects.next || (input && input.search)) {
                let projects = state.projects
                let query = {}

                if (input && input.search && input.search !== '') {
                    query.q = input && input.search ? input.search : undefined
                } else {
                    query.limit = projects.limit
                    query.offset = projects.offset
                }

                rootState.request({
                    type: constants.METHODS.GET,
                    apiName: 'main',
                    url: '/projects',
                    query: {
                        active: 1,
                        ...query
                    },
                    auth: true
                }).then(result => {
                    if (input && input.search && input.search !== '') {
                        Vue.set(state.projects, 'search', result.data.items.filter((thing, index, self) =>
                            index === self.findIndex(t => t.id === thing.id)
                        ))
                        resolve({next: false})
                    } else {
                        /* Очистка поиска */
                        Vue.set(state.projects, 'search', [])

                        projects.offset += projects.limit
                        let list = projects.list
                        list = list.concat(result.data.items)

                        Vue.set(state.projects, 'list', list.filter((thing, index, self) =>
                            index === self.findIndex(t => t.id === thing.id)
                        ))

                        let next = result.data.total >= projects.offset
                        projects.next = next
                        resolve({next: next})
                    }
                }, error => {
                    reject(false)
                })
            } else {
                resolve({next: false})
            }
        })
    },
}

export default actions