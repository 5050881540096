import state from './state.js'
import mutations from './mutations.js'
import getters from './getters'

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
