import state from './state'
import mutations from './mutations'
import actions from './actions'

import projects from './projects'
import tasks from './tasks'
import quickStart from './quickStart'
import traction from './traction'
import abcAnalysis from './abcAnalysis'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    modules: {
        projects,
        tasks,
        quickStart,
        traction,
        abcAnalysis
    }
}
