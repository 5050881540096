import Vue from 'vue'

import VueCookie from 'vue-cookie'

/* Запросы */
import VueResource from 'vue-resource'

/* Библиотека для работы с токеном аутентификации */
import jwtDecode from 'jwt-decode'

/* Работа с датами */
import moment from 'moment'

/* Константы */
import CONSTANTS from '@/const'

Vue.use(VueCookie)
Vue.use(VueResource)

/* Библиотека для работы с cookies */
const Cookies = Vue.cookie

/*
 * Запрос новой пары токенов (Promise)
 */
export async function jwtRefresh (config) {
    let refreshToken = Cookies.get(CONSTANTS.AUTH.REFRESH_TOKEN)

    if (refreshToken) {
        try {
            let response = await Vue.http.post(config.api.main + '/auth/refresh', {[CONSTANTS.AUTH.REFRESH_TOKEN]: refreshToken})
            return await logIn(config, response.body.data)
        } catch (error) {
            saveLogs(error)
            return false
        }
    }
}

/*
 * Получение текущего домена
 */
export function getMainDomain () {
    let brokenDomain = document.location.hostname.split('.')
    let countFragments = brokenDomain.length

    if (countFragments === 1) {
        return brokenDomain[0]
    } else {
        return brokenDomain[countFragments - 2] + '.' + brokenDomain[countFragments - 1]
    }
}

/*
 * Получение токена
 */
export function getTokenData () {
    try {
        return jwtDecode(Cookies.get(CONSTANTS.AUTH.ACCESS_TOKEN))
    } catch (_) {
        return undefined
    }
}

/*
 * Получение данных пользователя
 */
export function getUser () {
    const token = getTokenData()

    function roleСheck (roleId) {
        try {
            return token.current.role.id === roleId
        } catch (_) {
            return false
        }
    }

    let current = token && token.current ? token.current : {}

    return {
        ...current,

        /* Рот пользователь */
        isRoot: () => roleСheck(CONSTANTS.ROLES.ROOT),

        /* Админ */
        isAdministrator: () => roleСheck(CONSTANTS.ROLES.ADMINISTRATOR)
    }
}

/*
 * Получение TTL
 */
export function getTokenTTL () {
    const getDataFromStorage = writeKey => () => {
        try {
            if (writeKey) {
                let value = +localStorage.getItem(writeKey)
                if (value && value !== NaN && value > 60) {
                    return value
                } else {
                    throw ''
                }
            } else {
                throw ''
            }
        } catch (_) {
            return undefined
        }
    }

    return {
        access: getDataFromStorage(CONSTANTS.LOCAL_STORAGE_DATA.JWT_TTL_ACCESS),
        refresh: getDataFromStorage(CONSTANTS.LOCAL_STORAGE_DATA.JWT_TTL_REFRESH)
    }
}

/*
 * Функция создания сессии
 * config - Конфигурация приложения
 * tokens - Объект токенов авторизации
 */
export async function logIn (config, tokens) {
    try {
        if (tokens && tokens.access_token && tokens.refresh_token) {
            const tokenData = jwtDecode(tokens.access_token)

            if (tokenData && tokenData.exp) {
                /* Дата истечения токенов */
                const expTime = new Date(tokenData.exp * 1000)
                const rxpTime = tokenData.rxp ? new Date(tokenData.rxp * 1000) : expTime

                /* Сохранение данных */
                await Cookies.set(CONSTANTS.AUTH.ACCESS_TOKEN, tokens.access_token, {
                    expires: expTime,
                    domain: getMainDomain()
                    // samesite: 'Lax',
                    // Secure: true
                })

                await Cookies.set(CONSTANTS.AUTH.REFRESH_TOKEN, tokens.refresh_token, {
                    expires: rxpTime,
                    domain: getMainDomain()
                    // samesite: 'Lax',
                    // Secure: true
                })

                return isLoggedIn()
            } else {
                throw ''
            }
        } else {
            throw ''
        }
    } catch (_) {
        return false
    }
}

/*
 * Функция удаление сессии
 */
export async function logOut (config) {
    /* Удаление данных из cookies */
    Cookies.delete(CONSTANTS.AUTH.ACCESS_TOKEN, {
        domain: getMainDomain()
    })
    Cookies.delete(CONSTANTS.AUTH.REFRESH_TOKEN, {
        domain: getMainDomain()
    })
    Cookies.delete(CONSTANTS.AUTH.PHPSESSID, {
        domain: getMainDomain()
    })

    localStorage.removeItem(CONSTANTS.LOCAL_STORAGE_DATA.ACTIVE_PROJECT)

    /* ToDo: Отключили, так как при запросе из БД удаляются все токены конкретного юзера */
    // try {
    //   let accessToken = Cookies.get(CONSTANTS.AUTH.ACCESS_TOKEN)
    //   if (accessToken && config) {
    //     await Vue.http.delete(config.api.main + `/auth`, {headers: {Authorization: `Bearer ${accessToken}`}}).then().catch()
    //   }
    // } catch (_) {
    // }

    /* Отчистка данных пользователя в виджете Intercom */
    // if (window.Intercom) {
    //     window.Intercom('shutdown');
    //     window.Intercom('boot', {app_id: config.intercom_id})
    // }

    return !isLoggedIn()
}


/*
 * Проверка существования JWT токенов
 */
export function isLoggedIn () {
    return !!(Cookies && Cookies.get(CONSTANTS.AUTH.ACCESS_TOKEN) && Cookies.get(CONSTANTS.AUTH.REFRESH_TOKEN))
}

/*
 * Проверка возможности получения новой пары JWT
 */
export function isRefresh () {
    return !!(Cookies && Cookies.get(CONSTANTS.AUTH.REFRESH_TOKEN))
}

/*
 * Проверка существования сессии и рефреш токенов (Promise)
 */
export async function isLoggedInAndRefresh (config) {
    if (isLoggedIn()) {
        return true
    } else if (isRefresh) {
        let resultRefresh = await jwtRefresh(config)

        if (!resultRefresh) {
            logOut(config)
        }

        return resultRefresh
    }
}

/*
 * Сохранение логов
 */
export async function saveLogs (error) {
    let logs = []
    try {
        logs = JSON.parse(localStorage.getItem('log_auth')) || []
    } catch (_) {}

    if (!logs || !Array.isArray(logs)) {
        logs = []
    }

    logs.unshift({
        date: moment().format('LLL'),
        context: error
    })

    localStorage.setItem('log_auth', JSON.stringify(logs.slice(0, 10)))
}

/*
 * Библиотека для работы с данными
 */
export {Cookies}
