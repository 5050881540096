import Vue from 'vue'
import Url from 'url-parse'

import CONSTANTS from '@/const/'
import {Cookies, isRefresh, isLoggedIn, jwtRefresh, logOut} from './auth.js'

import VueResource from 'vue-resource'

Vue.use(VueResource)

const ERROR_CONFIG = 'Ошибка конфигурации приложения'

let refreshTokenRequest = null;

const logoutUser = function (input) {
    logOut(input.config)
    document.location.href = input.notComeBack ? '/login' : '/login?to=' + window.location.pathname + window.location.search
}

let previousRequests = []

/*
 * Функция инициализации запросов
 */
export function init (config) {
    return {
        request: (input) => {
            return request({
                config,
                ...input
            })
        }
    }
}

/*
 * Функция отправки запроса
 */
export function request (input) {
    return new Promise(async function (resolve, reject) {
        let isValid = await validate({ input })

        if (!isValid.status) {
            reject(isValid.message || ERROR_CONFIG)
        }

        let headers = {
            'X-Referrer': location.href
        }

        if (input.headers) {
            headers = {
                ...headers,
                ...input.headers
            }
        }

        let accessToken = Cookies.get(CONSTANTS.AUTH.ACCESS_TOKEN)
        if (input.auth && accessToken) {
            headers.Authorization = 'Bearer ' + accessToken
        }

        let credentials = !!input.credentials

        /*
         * Генераци адреса запроса
         */
        let url

        try {
            switch (true) {
                case !!input.straightUrl:
                    url = Url(input.straightUrl)
                    break

                case !!input.mock:
                    url = Url(input.config.api.mock + input.url)
                    break

                case !!input.apiName && !!input.config.api && !!input.config.api[input.apiName]:
                    url = Url(input.config.api[input.apiName] + input.url)
                    break

                default:
                    url = Url(input.config.api.default + input.url)
            }
        } catch (_) {
            reject(ERROR_CONFIG)
        }

        /*
         * Добавление query параметров
         */
        let newQueries = {}

        if (input.query) {
            for (let i in input.query) {
                let item = input.query[i]
                if (Array.isArray(item)) {
                    for (let k in item) {
                        let key = `${i}[${k}]`
                        newQueries[key] = item[k]
                    }
                } else if (item) {
                    newQueries[i] = item
                }
            }
        }

        /*
         * Добавление пагинации
         */
        if (input.pagination) {
            let p = input.pagination
            if (p.limit) {
                newQueries['limit'] = p.limit
            }

            if (p.page && p.page > 1) {
                newQueries['offset'] = (p.page - 1) * p.limit
            } else if (p.offset) {
                newQueries['offset'] = p.offset
            }
        }

        if (url) {
            url.set('query', newQueries)
        }

        /*
         * Создание тела запроса
         */
        switch (input.type) {
            case CONSTANTS.METHODS.POST:
            case CONSTANTS.METHODS.PUT:
                /*
                 * Проверка тела запроса
                 */
                if (!input.body) {
                    reject('Укажите тело запроса')
                }

                Vue.http[input.type.toLowerCase()](
                    url.href,
                    input.body,
                    {
                        headers,
                        credentials,
                        /* Настройка для кроссдоменных запросов */
                        withCredentials: false
                    }
                ).then(response => {
                    resolve(input.response ? response : response.body)
                }, error => {
                    if (error && error.status === 401) {
                        logoutUser(input)
                    }
                    reject(error)
                })
                break

            default:
                Vue.http[input.type.toLowerCase() || 'get'](
                    url.href,
                    {
                        headers,
                        credentials,
                        /* Настройка для кроссдоменных запросов */
                        withCredentials: false,

                        /* Принудительная остановка старых запросов */
                        before(request) {
                            if (window.vue.$store.state.cancelRequests && previousRequests.length) {
                                previousRequests.forEach(item => {
                                    item.abort()
                                })
                                window.vue.$store.commit('setCancelRequests', false)

                                previousRequests = []
                            }

                            if (input.cancelable) {
                                previousRequests.push(request)
                            }
                        }
                    }
                ).then(response => {
                    resolve(input.response ? response : response.body)
                }, error => {
                    if (error && error.status === 401) {
                        logoutUser(input)
                    } else if (error && error.status) {
                        reject(error)
                    }
                })
        }
    }, error => {
        reject(error)
    })
}

/*
 * Валидация запроса
 */
export async function validate ({input}) {
    /*
     * Проверка адреса запроса
     */
    if (!input.config && (input.config.api[input.apiName] || input.config.api.default)) {
        return {
            message: 'Укажите конфигурационные данные',
            status: false
        }
    }

    /*
     * Проверка адреса запроса
     */
    if (!input.url && !input.straightUrl) {
        return {
            message: 'Укажите адрес запроса',
            status: false
        }
    }

    /*
     * Проверка типа запроса
     */
    if (!input.type) {
        return {
            message: 'Укажите тип запроса',
            status: false
        }
    }

    /*
     * Проверка на существование сессии и
     * валидности токена
     */
    switch (true) {
        case input.auth && isLoggedIn():
            return {
                status: true
            }
            break

        case input.auth && isRefresh():
            if (refreshTokenRequest === null) {
                refreshTokenRequest = jwtRefresh(input.config)
            }

            let result = await refreshTokenRequest

            refreshTokenRequest = null

            if (result) {
                return {
                    status: true
                }
            } else {
                logoutUser(input.config)
            }
            break
        case input.auth && !isLoggedIn() && !isRefresh():
            logoutUser(input.config)
    }

    return {
        status: true
    }
}
