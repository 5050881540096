import {getUser} from '@/function/auth.js'

const accessAside = (item, d_root) => {
    /* Права на отображения */
    let userData = getUser()

    if (d_root) {
        return item.access === 'd' && userData.isRoot()
    } else {
        switch (item.access) {
            case 'r':
            case 'w':
            case 'd':
                return true
            // case 'd':
                // return userData.isRoot()
            default:
                return false
        }
    }
}

const getters = {
    getCookie: state => state.isCookieAccepted,
    asideData: state => input => {
        /* Принудительно отображаемые разделы */
        let view_routes = []

        if (state.aside) {
            let aside
            let projectId = state.active_project

            let base_menu

            if (input && input.dom) {
                aside = JSON.parse(JSON.stringify(input.dom))
            } else {
                aside = JSON.parse(JSON.stringify(state.aside))
            }

            /* Метод для манипуляций с роутами */
            const findRoute = list => list.map(item => {
              const getToProp = data => {
                let to = {...data.spa_route}

                if (to.params && to.params.activeProject) {
                  to.params.activeProject = projectId || data.params.projectId
                }

                return to
              }

                if (item.type === 'list') {
                  /* Проведение манипуляция со списками роутов */
                  item.items = findRoute(item.items)
                  item.to = getToProp(item)
                  item.actual_action = item.items.some(item => item.actual_action)
                  item.spa_actual_action = item.items.some(item => item.spa_actual_action) ||
                    (
                      input &&
                      input.mapRouter &&
                      item.to.name &&
                      input.mapRouter.indexOf(item.to.name) !== -1
                    )

                    return {
                        ...item,
                        view: accessAside(item)
                    }
                } else if (item.type === 'route') {
                  /* Проведение манипуляция с роутами */
                  item.to = getToProp(item)
                  /* Управление выделением пунктов меню */
                  if (
                    input &&
                    input.mapRouter &&
                    item.is_spa &&
                    item.to.name &&
                    input.mapRouter.indexOf(item.to.name) !== -1
                  ) {
                    item.actual_action = true
                    item.spa_actual_action = true
                  }

                  return {
                    ...item,
                    view: accessAside(item),
                    d_root: accessAside(item, true)
                  }
                } else {
                    return {
                        ...item,
                        view: accessAside(item),
                        d_root: accessAside(item, true)
                    }
                }
            })

            /* Основные разделы меню */
            if (aside.base_menu && aside.base_menu.length) {
                if (input && input.isNew) {
                    for (let item of aside.base_menu) {
                        item.section = true
                    }
                }
                base_menu = findRoute(aside.base_menu)
            }

            return {
                base_menu,
                view_routes,
                has_data: true
            }
        } else {
            return {
                has_data: false
            }
        }
    },

    getActiveProjectInMenu: state => {
        try {
            return state.aside.base_menu[0].params.project_id
        } catch (_) {
            return undefined
        }
    },

    /* Опции для фильтра по устройству */
    filtersDevices: state => {
        try {
            return state.filtersOptions.items.device
        } catch (_) {
            return []
        }
    },

    /* Опции для фильтра по региону */
    filtersRegions: state => (oldRegions = false) => {
        try {
            return state.filtersOptions.items[oldRegions ? 'demensions' : 'region']
        } catch (_) {
            return []
        }
    },

    /* Флаг отсутствия проектов у текущего пользователя */
    lackOfProjects: state => {
        try {
            return !(state.options.projects && state.options.projects.list && state.options.projects.list.length)
        } catch (_) {
            return false
        }
    }
}

export default getters
