import ROUTE_NAME from '@/router/routeName'

export default {
    routes: {
        bi: {
            [ROUTE_NAME.BI]: 'BI',
            [ROUTE_NAME.BI_COMPARISON]: 'Сравнение сегментов',
            [ROUTE_NAME.BI_COMPARISON_CORRELATION_SALES]: 'Корреляции продаж',
            [ROUTE_NAME.BI_COMPARISON_DYNAMICS_SALES]: 'Динамика продаж',
        }
    },

    components: {
        segments: {
            'Search': 'Поиск',
            'No segments found': 'Сегменты не найдены'
        }
    },

    modules: {
        pages: {
            'Error': 'Ошибка',
            'No access to projects': 'Отсутствуют доступы к проектам',
            'No projects': 'У вас еще нет созданного проекта',
            'Cancel': 'Отменить',
            'Yes': 'Да',
            'No': 'Нет',
            'Delete': 'Удалить'
        },
        searchEngines: {
            'Yandex': 'Яндекс',
            'Google': 'Google'
        },
        deviceTypes: {
            'Mobile': 'Mobile',
            'Desktop': 'Desktop'
        }
    },

    services: {
        bi: {
            comparison: {
                'No segments selected': 'Сегменты не выбраны',
                'Select segments to compare': 'Выберите сегменты для сравнения',
                'Remove this segment from the comparison': 'Убрать из сравнения',
                'Segment name': 'Название сегмента',
                'Creation date': 'Дата создания сегмента',
                'Traffic': 'Трафик',
                'Number of elements': 'Элементов',
                'Delete': 'Удалить',
                'Sales correlations': 'Корреляции продаж',
                'Transactions': 'Транзакции',
                'Conversion': 'Конверсия',
                'Sales dynamics': 'Динамика продаж',
                'date': 'дата',
                'Analysis start date': 'Дата начала анализа',
                'Analysis end date': 'Дата конца анализа',

                filters: {
                    'Search system': 'Поисковая система',
                    'Select SS': 'Выберите ПС',
                    'Select a metric': 'Выберите метрику',
                    'Visibility metrics': 'Метрика видимости',
                    'Sales metric': 'Метрика продаж',
                    'Select period': 'Выберите период',
                    'Collect BI': 'Построить BI',
                    'Control group': 'Контрольная группа',
                    'Select segment': 'Выбрать сегмент',
                    'Add segments': 'Добавить сегменты',
                    'All Site': 'Весь сайт',
                    'Dynamics': 'Динамика',
                    'Correlations': 'Корреляции',
                    'Sales': 'Продажи'
                }
            }
        },

        errors: {
            'Back to': 'Вернуться на',
            'the home page': 'главную страницу',
            'the previous page': 'предыдущую страницу'
        },

        segments: {
            table: {
                'Name': 'Название',
                'Type': 'Тип',
                'Status': 'Статус',
                'User': 'Пользователь',
                'Date': 'Дата',
                'Download': 'Скачать',
                'No data': 'Нет данных',
                'Delete': 'Удалено',
                'Success': 'Готово',
                'Error': 'Ошибка'
            },

            catalog: {
                'Project Data': 'Данные проекта',
                'Segmentation': 'Сегментирование',
                'Segments Catalog': 'Каталог сегментов',
                'Segments': 'Сегменты',
                'Completed tasks': 'Готовые задания',
                'URLs groups': 'Группы страниц',
                'Keywords groups': 'Группы запросов',
                'Categories': 'Категории',
                'All': 'Все',
                'New segment': 'Создать сегмент',
                'Last tasks': 'Последние задания',

                'Search by segment': 'Поиск по сегментам',
                'No segments found': 'Сегменты не найдены',

                'Back to segments catalog': 'Вернуться в каталог сегментов',
                'Back to segment selection': 'Вернуться к выбору сегмента',

                'Collect BI': 'Построить BI',
                'Segment comparison': 'Сравнить сегменты',
                'Selected': 'Выбрано',
                'Apply': 'Применить',
                'Search segments': 'Поиск сегмента',
                'Segment type': 'Тип сегмента',
                'Select a date': 'Выберите дату',
                'User': 'Автор',
                'Date': 'Дата',
                'Date of creation': 'Дата создания',
                'Search': 'Поиск',
                'Directory folder': 'Папка в каталоге',

                'Segment name': 'Название сегмента',
                'Go to': 'Перейти в',
                'Number of elements': 'Количество элементов',
                'Modified': 'Изменено',
                'Created': 'Создан',
                'Actions': 'Действия',
                'More': 'Подробнее',
                'Delete': 'Удалить',
                'Edit': 'Изменить',
                'Move': 'Переместить',
                'Save': 'Сохранить',
                'Cancel': 'Отмена',
                'Delete selected': 'Удалить выбранное',
                'Add to': 'Добавить',
                'Export': 'Скачать',

                'System': 'Системный',
                'Custom': 'Пользовательский',

                'pages': [
                    'страница',
                    'страницы',
                    'страниц'
                ],

                'URLs': 'Страницы',
                'Keywords': 'Запросы',
                'Segment element': 'Элемент сегмента',
                'Copy to clipboard': 'Скопировать в буфер обмена',

                'Dashboard visibility by segment': 'Видимость дашборд по сегменту',
                'Dashboard competitors by segment': 'Конкуренты дашборд по сегменту',
                'BI report by segment': 'BI отчет по сегменту',
                'Dashboard tech monitor by segment': 'Тех монитор дашборд по сегменту',
                'SEO analytics url': 'SEO аналитика страницы',
                'SEO analytics keywords': 'SEO аналитика запросы',

                'Are you sure you want to delete the segment': 'Вы уверенны, что хотите удалить сегмент',
                'Are you sure you want to move the segment': 'Вы уверенны, что хотите переместить сегмент',
                'Are you sure you want to delete': 'Вы уверенны, что хотите удалить',
                'Are you sure you want move to the segment': 'Вы уверенны, что хотите добавить в сегмент',

                'The "${segment.name}" segment has been changed': 'Сегмент «${segment.name}» изменён',
                'Segments moved': 'Сегменты перемещены',
                'The task to delete the segment "${segment.name}" is set': 'Задача на удаление сегмента «${segment.name}» поставлена',
                'The task to delete segments is set': 'Задача на удаление сегментов поставлена',
                'The task to delete the element "${item.name}" is set': 'Задача на удаление элемента «${item.name}» поставлена',
                'The data has been updated': 'Данные обновлены',
                'The segment is already in this folder': 'Сегмент уже находится в этой папке',
                'An error occurred while moving the segment': 'Произошла ошибка при перемещении сегмента',
                'The task of creating the segment is set': 'Задача на создание сегмента поставлена',
                'The current project is frozen': 'Текущий проект заморожен',
                'The task to add elements is set': 'Задача на добавление элементов поставлена',
                'The task to move elements is set': 'Задача на перемещение элементов поставлена',
                'The task to delete elements is set': 'Задача на удаление элементов поставлена',
                'Select segment': 'Выберите сегмент',

                'Creating a segment': 'Создание сегмента',
                'Name of segment': 'Название сегмента',
                'Select a project': 'Выберите проект',
                'Select date': 'Выберите дату',
                'Add items': 'Добавить элементы',
                'Format': 'Формат',
                'URL id | segment name': 'id документа | сегмент',
                'URL | segment name': 'url страницы | сегмент',
                'Keyword | segment name': 'запрос | выборка',
                'Download example': 'Скачать пример',
                'File': 'Файл',
                'List': 'Список',
                'URL list': 'Список URL',
                'List of keywords': 'Список запросов',
                'Validation error': 'Ошибка валидации',
                'An error occurred': 'Произошла ошибка',
                'File successfully uploaded': 'Файл успешно загружен',
                'Error loading the file': 'Ошибка при загрузке файла',

                'Adding keywords': 'Добавление запросов',
                'Adding URLs': 'Добавление URL страниц',
                'Adding segment elements': 'Добавление элементов сегмента',
                'Task name': 'Название задачи',

                'Editing a segment': 'Редактирование сегмента',
                'Segment name must be at least 4 characters': 'Не менее 4-х символов',
                'Maximum segment name length is 255 characters': 'Максимальная длина - 255 символов',
                'Field is required': 'Поле обязательно'
            }
        }
    },

    cookie: {
      'We use cookies on our site to provide you with the best user experience.': 'На нашем веб-сайте используются файлы cookies. Посещая его, вы даете согласие на использование файлов cookies в соответствии с настоящим уведомлением об их использовании. ',
      'Privacy Policy': 'Узнать подробнее',
      url: 'https://dataforce.io/confidential',
      Ok: 'Согласен'
    },
}
