var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"projects"},[_c('s-select',_vm._b({on:{"input":function($event){return _vm.editActiveProject($event)}},scopedSlots:_vm._u([{key:"value",fn:function({valueReal}){return [_c('div',{staticClass:"projects__value"},[_c('img',{staticClass:"projects__logo",attrs:{"src":'https://favicon.yandex.net/favicon/v2/' + valueReal.host.name,"alt":valueReal.label}}),_vm._v(" "+_vm._s(valueReal.label)+" ")])]}},{key:"list-item",fn:function({data}){return [_c('span',{staticClass:"select-item__data"},[_c('img',{staticClass:"projects__logo",attrs:{"width":"16","src":'https://favicon.yandex.net/favicon/v2/' + data.host.name,"alt":data.label}}),_vm._v(" "+_vm._s(data.label)+" ")])]}}]),model:{value:(_vm.activeProjectValue),callback:function ($$v) {_vm.activeProjectValue=$$v},expression:"activeProjectValue"}},'s-select',{
      placeholder: 'Выберите проект',
      viewOptionsName: 'label',
      viewOptionsNameList: 'label',
      search: true,
      staticWidthDrop: '350px',
      appendToBody: false,
      options: _vm.projects.list,
      searchOptions: _vm.projects.search.items,
      lazyLoad: _vm.lazyLoadList,
      searchLoad: _vm.lazyLoadList
    },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }