import Vue from 'vue'

const mutations = {
  saveProjects (state, payload) {
    let projects = {...state.projects}

    if (payload.projectId) {
      if (payload.items && Array.isArray(payload.items) && payload.items.length) {
        projects.items = [...payload.items, ...projects.items]
      }
      Vue.set(state.projects, 'items', projects.items)
    } else {
      let items = [...payload.items].filter(item => !projects.items.find(project => project.id === item.id))

      state.projects = {
        hasData: true,
        items: projects.items.concat(items),
        offset: projects.offset + projects.limit,
        limit: projects.limit,
        next: payload.next && !!payload.items.length,
        search: projects.search
      }
    }
  },

  saveProjectSearch (state, payload) {
    let projects = {...state.projects.search}

    Vue.set(state.projects, 'search', {
      limit: projects.limit,
      offset: projects.offset + projects.limit,
      next: payload.next && !!payload.items.length,
      items: projects.items.concat(payload.items)
    })
  },

  clearProjectSearch (state) {
    Vue.set(state.projects, 'search', {
      limit: state.projects.search.limit,
      offset: 0,
      next: true,
      items: []
    })
  },

  clearProjects (state) {
    state.projects = {
      hasData: false,
      items: [],
      limit: 100,
      offset: 0,
      next: true,

      search: {
        limit: 100,
        offset: 0,
        next: true,
        items: []
      }
    }
  }
}

export default mutations
