import {DATE_FORMAT} from '@/const'
import moment from 'moment-timezone'

function hmsToSeconds (hms) {
    const seconds = hms.split(':')
    const result = (+seconds[0]) * 3600 + (+seconds[1]) * 60 + (+seconds[2])
    return hms.includes('-') ? -result : result
}

function secondsToHms (seconds) {
    let date = new Date(null)
    const value = seconds < 0 ? -seconds : seconds
    date.setSeconds(value)
    const hms = date.toISOString().substring(11, 19)
    return seconds < 0 ? '-' + hms : hms
}

function utcToFormat (utc, format = DATE_FORMAT.BASE_FORMAT) {
    let date = moment.utc(utc)
    if (utc && date.isValid()) {
        return date.format(format)
    } else {
        return undefined
    }
}

export {
    hmsToSeconds,
    secondsToHms,
    utcToFormat
}
