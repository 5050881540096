import {findOptions, findKey, roundNumber} from '@/function/'
import CONSTANTS from '@/const/'

const getters = {
	/*
	 * Склеивание списков сегментов из данных по разным поисковым системам
	 * на вход идет массив сегментов в формате [{ id, type }]
	 * Геттер собирает данные из state по каждому сегменту
	 */
	segmentsListFrequency: state => segments => {
		const combineSegments = ({ category, tag, query_group }) => {
			return [
        { list: category, type: CONSTANTS.SEMANTIC_SECTIONS.CATEGORY },
        { list: tag, type: CONSTANTS.SEMANTIC_SECTIONS.GROUP },
        { list: query_group, type: CONSTANTS.SEMANTIC_SECTIONS.QUERY_GROUP }
      ].reduce((all, segments, i) => {
        if (segments && segments.list && Array.isArray(segments.list)) {
          all = all.concat(segments.list.map(segment => {
            return {
            	...segment,
            	id: segment.entity_id,
            	type: segments.type
            }
          }))
        }
        return all
      }, [])
		}

		/* Поиск объекта сегмента */
		const findSegment = ({ id, type, data }) => {
			try {
				return data.find(item => item.id === id && item.type === type)
			} catch (_) {
			}
		}

		/* Наличие данных по поисковым системам */
		let search_engines = {
			yandex: state.frequencyYandex.has_data,
			google: state.frequencyGoogle.has_data,
			all: state.frequency.has_data
		}

		/* Метрики видимости */
		let frequency = {}

		if (search_engines.google) {
			frequency.google = combineSegments(state.frequencyGoogle.data)
		}

		if (search_engines.yandex) {
			frequency.yandex = combineSegments(state.frequencyYandex.data)
		}

		if (search_engines.all) {
			frequency.all = combineSegments(state.frequency.data)
		}

		segments = segments.map(segment => {
      /* Сборка данных по трафику */
      if(state.traffic.full.has_data) {
        let trafficCounts = state.traffic.full.items.find(item => item.entity && item.entity.id && item.entity.id === segment.id && item.entity.type === segment.type)
        for (let item of ['traffic', 'transaction_revenue', 'transactions']) {
          try {
            segment[item] = trafficCounts.data.reduce((sum, point) => sum + point[item], 0);
          } catch (_) {}
        }
        try {
          segment.conversion = roundNumber((segment.transactions / segment.traffic) * 100, 1) || 0
        } catch (_) {
        }
      }

			/* Сборка данных по WS */
			try {
				segment.ws = ((data) => {
					let frequencyData = findSegment({
						id: segment.id,
						type: segment.type,
						data: data
					})

					/*
					 * Добавление названия сегмента на тот случай, если список сегментов в каталоге не загружен
					 */
					if (!segment.name) {
						segment.name = frequencyData.label
					}

					return frequencyData.items[frequencyData.items.length - 1].ws
				})(frequency.google || frequency.yandex || frequency.all)
			} catch (_) {}

			/* Сборка данных по видимостям */
			segment.visibilityAvg = {}

			try {
				if (search_engines.yandex) {
					segment.visibilityAvg.yandex = findSegment({
						id: segment.id,
						type: segment.type,
						data: frequency.yandex
					}).visibilityAvg
				}
			} catch (_) {}

			try {
				if (search_engines.google) {
					segment.visibilityAvg.google = findSegment({
						id: segment.id,
						type: segment.type,
						data: frequency.google
					}).visibilityAvg
				}
			} catch (_) {}

			return segment
		})

		return {
			list: segments,
			search_engines
		}
	},

	/*
	 * Склеивание списков сегментов из данных по разным поисковым системам
	 * на вход идет массив сегментов в формате [{ id, type }]
	 * Геттер собирает данные из state по каждому сегменту
	 */
	segmentsListFrequencyCorrelation: state => segments => {
		const combineSegments = ({ category, tag, query_group }) => {
			return [
				{ list: category, type: CONSTANTS.SEMANTIC_SECTIONS.CATEGORY },
				{ list: tag, type: CONSTANTS.SEMANTIC_SECTIONS.GROUP },
				{ list: query_group, type: CONSTANTS.SEMANTIC_SECTIONS.QUERY_GROUP }
			].reduce((all, segments, i) => {
				if (segments && segments.list && Array.isArray(segments.list)) {
					all = all.concat(segments.list.map(segment => {
						return {
							...segment,
							id: segment.entity_id,
							type: segments.type
						}
					}))
				}
				return all
			}, [])
		}

		/* Поиск объекта сегмента */
		const findSegment = ({ id, type, data }) => {
			try {
				return data.find(item => item.id === id && item.type === type)
			} catch (_) {
			}
		}

		/* Наличие данных по поисковым системам */
		let search_engines = {
			yandex: state.frequencyYandex.has_data,
			google: state.frequencyGoogle.has_data,
			all: state.frequency.has_data
		}

		/* Метрики видимости */
		let frequency = {}

		if (search_engines.google) {
			frequency.google = combineSegments(state.frequencyGoogle.data)
		}

		if (search_engines.yandex) {
			frequency.yandex = combineSegments(state.frequencyYandex.data)
		}

		if (search_engines.all) {
			frequency.all = combineSegments(state.frequency.data)
		}

		segments = segments.map(segment => {
			['yandex', 'google'].forEach(searchSystem => {
				segment[searchSystem] = {}
				/* Сборка данных по трафику */
				if (state.traffic[searchSystem].has_data) {
					let trafficCounts = state.traffic[searchSystem].items.find(item => item.entity && item.entity.id && item.entity.id === segment.id && item.entity.type === segment.type)

					for (let item of ['traffic', 'transaction_revenue', 'transactions']) {
						try {
							segment[searchSystem][item] = trafficCounts.data.reduce((sum, point) => sum + point[item], 0);
						} catch (_) {
						}
					}

					try {
						segment[searchSystem].conversion = roundNumber((segment[searchSystem].transactions / segment[searchSystem].traffic) * 100, 1) || 0
					} catch (_) {
					}
				}
			})

			/* Сборка данных по WS */
			try {
				segment.ws = ((data) => {
					let frequencyData = findSegment({
						id: segment.id,
						type: segment.type,
						data: data
					})

					/*
					 * Добавление названия сегмента на тот случай, если список сегментов в каталоге не загружен
					 */
					if (!segment.name) {
						segment.name = frequencyData.label
					}

					return frequencyData.items[frequencyData.items.length - 1].ws
				})(frequency.google || frequency.yandex || frequency.all)
			} catch (_) {}

			/* Сборка данных по видимостям */
			segment.visibilityAvg = {}

			try {
				if (search_engines.yandex) {
					segment.visibilityAvg.yandex = findSegment({
						id: segment.id,
						type: segment.type,
						data: frequency.yandex
					}).visibilityAvg
				}
			} catch (_) {}

			try {
				if (search_engines.google) {
					segment.visibilityAvg.google = findSegment({
						id: segment.id,
						type: segment.type,
						data: frequency.google
					}).visibilityAvg
				}
			} catch (_) {}

			return segment
		})

		return {
			list: segments,
			search_engines
		}
	}
}

export default getters
