export default {
    LEADERS_SALES_TOP5: 'leadersSalesTop5',
    LEADERS_TRAFFIC_TOP5: 'leadersTrafficTop5',
    LEADERS_CR_TOP5: 'leadersCRTop5',
    DEMAND: 'demand',
    DYNAMICS: 'dynamics',
    LEADERS_SALES_TOP3: 'leadersSalesTop3',
    OUTSIDERS_SALES: 'outsidersSales',
    LEADERS_CR_TOP3: 'leadersCRTop3',
    OUTSIDERS_CR: 'outsidersCR',
    LEADERS_TRAFFIC_TOP3: 'leadersTrafficTop3',
    OUTSIDERS_TRAFFIC: 'outsidersTraffic',
    LEADERS_DEMAND: 'leadersDemand',
    OUTSIDERS_DEMAND: 'outsidersDemand',
    GEO_DEMAND: 'geoDemand',
    GEO_TRAFFIC: 'geoTraffic',
    GEO_SALES: 'geoSales',
    GEO_CR: 'geoCR',
    GEO_SUMMARY: 'geoSummary',
    GEO_TRAFFIC_TOP5: 'geoTrafficTop5',
    GEO_TRANSACTION_REVENUE_TOP5: 'geoTransactionRevenueTop5',
    GEO_SALES_TOP5: 'geoSalesTop5',
    LEADERS_TRANSACTION_REVENUE_TOP5: 'leadersTransactionRevenueTop5',
    GEO_CR_TOP5: 'geoCRTop5',
    GEO_LEADERS_SALES_TOP3: 'geoLeadersSalesTop3',
    GEO_OUTSIDERS_SALES: 'geoOutsidersSales',
    GEO_LEADERS_CR_TOP3: 'geoLeadersCRTop3',
    GEO_OUTSIDERS_CR: 'geoOutsidersCR',
    GEO_LEADERS_TRAFFIC_TOP3: 'geoLeadersTrafficTop3',
    GEO_OUTSIDERS_TRAFFIC: 'geoOutsidersTraffic',
    GEO_LEADERS_DEMAND: 'geoLeadersDemand',
    GEO_OUTSIDERS_DEMAND: 'geoOutsidersDemand',
    SUMMARY: 'summary',
    TRAFFIC_CHANNELS_TOP5: 'trafficChannelsTop5',
    TRAFFIC_CHANNELS_TOP3_LEADERS: 'trafficChannelsTop3Leaders',
    TRAFFIC_CHANNELS_TOP3_OUTSIDERS: 'trafficChannelsTop3Outsiders',
    LEADERS_TRAFFIC_TOP3_PERIOD: 'leadersTrafficTop3Period',
    OUTSIDERS_TRAFFIC_PERIOD: 'outsidersTrafficPeriod',
    ENTITY_METRIC_SUMMARY: 'entityMetricSummary',
    ENTITY_CHANNELS_TRAFFIC: 'entityChannelsTraffic',
    ENTITY_CHANNELS_TRANSACTION_REVENUE: 'entityChannelsTransactionRevenue',
    LEADERS_SALES_ECOMM: 'leadersSalesEcomm',
    ECOMM_CHANNELS_TOP3_LEADERS: 'ecommChannelsTop3Leaders',
    ECOMM_CHANNELS_TOP3_OUTSIDERS: 'ecommChannelsTop3Outsiders',
    LEADERS_ECOMM_TOP3_PERIOD: 'leadersEcommTop3Period',
    OUTSIDERS_ECOMM_PERIOD: 'outsidersEcommPeriod',
    DEVICE_CHANNELS_REVENUE: 'deviceChannelsRevenue',
    DEVICE_CHANNELS_TRAFFIC: 'deviceChannelsTraffic',
    DEVICE_METRIC_SUMMARY: 'deviceMetricSummary',
    DEVICE_TRAFFIC_TOP5: 'deviceTrafficTop5',
    DEVICE_TRANSACTION_REVENUE_TOP5: 'deviceTransactionRevenueTop5',
    DEVICE_SALES_TOP5: 'deviceSalesTop5',
    DEVICE_CR_TOP5: 'deviceCRTop5'
}
