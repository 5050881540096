<template>
  <div class="error">
    <div class="error__container">
      <icon class="error__icon" name="bot-error"/>

      <div class="error__title">{{ title }}</div>
      <div class="error__subtitle">{{ subtitle }}</div>

      <slot
          v-if="showLinks"
          name="links"
      >
        <ul class="error__list">
          <li>
            {{ $t(contentPath)['Back to'] }}
            <router-link :to="{ name: 'index' }" class="error__link">{{ $t(contentPath)['the home page'] }}
            </router-link>
          </li>
          <li>
            {{ $t(contentPath)['Back to'] }}
            <a href="javascript:history.back();" class="error__link">{{ $t(contentPath)['the previous page'] }}</a>
          </li>
        </ul>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Ошибка 404'
    },

    subtitle: {
      type: String,
      default: 'Страница не найдена. Возможно Вы набрали неправильный адрес или страница была удалена.'
    },

    showLinks: {
      type: Boolean,
      default: true
    }
  },

  data () {
    let contentPath = 'services.errors'
    return {
      contentPath
    }
  }
}
</script>

<style lang="scss" src="./errors.scss" scoped></style>
