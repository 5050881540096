export default {
  /* Каталог сегментов SW */
  dataSW: {
    has_data: false,
    project_id: undefined,

    /* Плоский список */
    items: [],

    /* Древовидный список */
    tree: [],

    /* Хлебные крошки для каждой категории */
    breadcrumbs: []
  },

  /* Список всех сегментов */
  segments: {
    has_data: false,
    items: []
  },

  /* Каталог сегментов DF */
  dataDF: {
    has_data: false,
    project_id: undefined,

    /* Плоский список */
    items: [],

    /* Древовидный список */
    tree: []
  },

  /* Каталог кастомных сегментов DF */
  dataDFCustom: {
    has_data: false,
    project_id: undefined,

    /* Плоский список */
    items: [],

    /* Древовидный список */
    tree: []
  },

  /* Каталог сегментов */
  data: {
    has_data: false,
    project_id: undefined,

    /* Плоский список */
    items: [],

    /* Древовидный список */
    tree: [],

    /* Хлебные крошки для каждой категории */
    breadcrumbs: []
  },

  /* Последние задания */
  taskList: {
    has_data: false,
    items: []
  },

  /* Общее количество сегментов в разделах */
  segmentsSummary: []
}
