<template>
  <div class="title" v-if="primary || secondary">
    <slot name="breadcrumbs" v-if="breadcrumbs">
      <v-breadcrumbs
          class="title__breadcrumbs"
          :custom-route-list="customRouteList"
          :home="breadcrumbsHome"
          small-arrow-size
      />
    </slot>

    <div :class="['title__container', {'_mt-28': !breadcrumbs}]">
      <div class="title-info">
        <slot name="title">
          <h1
              :class="['title__primary', {_bold: !title.secondary}]"
              id="title-primary"
          >{{ title.primary }}</h1>

          <span class="title__dot" v-if="title.secondary && title.primary"> :&nbsp;</span>

          <h2 class="title__secondary" id="title-secondary">
            {{ title.secondary }}
          </h2>
        </slot>

        <div class="title__additional" v-if="$slots.additional">
          <slot name="additional"></slot>
        </div>

        <v-hint class="title__tooltip" v-if="tooltipView" icon="info_outline" tooltip-position="right-start">
          <slot name="tooltip">
            <template v-html="tooltip"></template>
          </slot>
        </v-hint>
      </div>

      <div
          v-if="$slots.right"
          class="title-right"
      >
        <slot name="right"/>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Компонент заголовка страницы
 */
import {mapState} from 'vuex'

export default {
  props: {
    /**
     * Первичный заголовок
     */
    primary: {
      type: String,
      default: undefined
    },

    /**
     * Вторичный заголовок
     */
    secondary: {
      type: String,
      default: undefined
    },

    /**
     * Данные о проекте
     */
    projectData: {
      type: Object,
      default: () => {
        return {}
      }
    },

    /**
     * Текст подсказки
     */
    tooltip: {
      type: String,
      default: undefined
    },

    /**
     * Флаг отображения хлебных крошек
     */
    breadcrumbs: {
      type: Boolean,
      default: false
    },

    /**
     * Флаг отображения "Главной" в хлебных крошках при кастомном списке
     */
    breadcrumbsHome: {
      type: Boolean,
      default: false
    },

    /**
     * Пользовательский список роутов
     */
    customRouteList: {
      type: [Array, Object],
      required: false
    }
  },

  data: () => ({
    contentPath: 'components.pageTitle'
  }),

  computed: {
    ...mapState({
      /* Конфигурация приложения */
      config: state => state.config,

      /* Данные пользователя */
      profile: state => state.profile,

      /* Опции */
      options: state => state.options
    }),

    tooltipView () {
      return !!(this.$slots.tooltip && this.$slots.tooltip.length)
    },

    title () {
      let primary
      let secondary

      if (this.projectData && this.projectData.label) {
        secondary = this.projectData.label
      }

      if (this.primary && this.secondary) {
        primary = this.primary
        secondary = this.secondary
      } else {
        primary = this.primary
      }

      return {
        primary,
        secondary
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  padding: 0 32px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0;
  }

  &._border-bottom {
    padding-bottom: 24px;
    border-bottom: 1px solid #E0E6F0;
  }

  &._mb-0 {
    margin-bottom: 0;
  }

  &._pt-0 {
    padding-top: 0;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    color: #424242;
    flex-grow: 1;
    font-size: 24px;
    line-height: 28px;
    font-weight: 300;
    margin-top: 16px;

    &._mt-28 {
      margin-top: 28px;
    }
  }

  &__secondary,
  ._bold {
    font-weight: 500;
    color: #455165;
    font-size: 24px;
    line-height: 28px;
  }

  &__additional {
    margin-left: 12px;
    font-size: 16px;
    line-height: 24px;

    &:not(:last-child) {
      .title__project-info {
        border-right: 1px solid #E0E6F0;
      }
    }
  }

  &__primary,
  &__dot, {
    font-weight: inherit;
    color: #9D99AA;
    font-size: 24px;
    line-height: 28px;
  }

  &__tooltip {
    margin-left: 12px;
    height: 18px;
  }

  &__link {
    margin-left: 12px;
    font-size: 12px;

    &-icon {
      margin-right: 3px;
    }
  }

  &-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-links {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
  }

  &-right {
    margin-left: auto;
  }

  &__breadcrumbs {
    margin-top: 18px;
  }
}
</style>
