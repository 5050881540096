import {CATALOG_ENTITY_TYPE, PERIOD_OPTIONS} from '@/const'

export const getDefaultState = () => ({
  periodOptions: PERIOD_OPTIONS,

  categories: [],

  widgetConfig: {
    projectId: undefined,
    data: []
  },

  itemsDefault: {
    hasData: false,
    items: []
  },

  summaryDefault: {
    hasData: false,
    data: {}
  },

  summaryPeriod: {
    df: {
      [PERIOD_OPTIONS.WEEK_TO_WEEK]: {
        hasData: false,
        data: {}
      },
      [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: {
        hasData: false,
        data: {}
      },
      [PERIOD_OPTIONS.MONTH_TO_MONTH]: {
        hasData: false,
        data: {}
      },
      [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: {
        hasData: false,
        data: {}
      },
      [PERIOD_OPTIONS.DAY_TO_DAY]: {
        hasData: false,
        data: {}
      },
      [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: {
        hasData: false,
        data: {}
      }
    },
    ga: {
      [PERIOD_OPTIONS.WEEK_TO_WEEK]: {
        hasData: false,
        data: {}
      },
      [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: {
        hasData: false,
        data: {}
      },
      [PERIOD_OPTIONS.MONTH_TO_MONTH]: {
        hasData: false,
        data: {}
      },
      [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: {
        hasData: false,
        data: {}
      },
      [PERIOD_OPTIONS.DAY_TO_DAY]: {
        hasData: false,
        data: {}
      },
      [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: {
        hasData: false,
        data: {}
      }
    }
  },

  summaryGeo: {},

  summaryTopGeo: {
    sessions: {},
    transaction_revenue: {},
    transactions: {},
    conversions: {},
  },

  summaryGeoMetrics: [
    {id: 'ws2', name: 'Спрос'},
    {id: 'count_all', name: 'Трафик'},
    {id: 'transactions', name: 'Продажи'},
    {id: 'conversions', name: '%CR', percent: true},
    {id: 'ptraf', name: 'Видимость'},
    {id: 'transaction_revenue', name: 'Выручка'},
  ],

  summaryTopGeoMetrics: [
    {id: 'count_all', name: 'Трафик', query: 'sessions'},
    {id: 'transaction_revenue', name: 'Выручка', query: 'transaction_revenue'},
    {id: 'transactions', name: 'Продажи', query: 'transactions'},
    {id: 'conversions', name: '%CR', percent: true, query: 'conversions'},
  ],

  itemsGeo: {},

  itemsTopGeo: {
    sessions: {},
    transaction_revenue: {},
    transactions: {},
    conversions: {},
  },

  topRegions: {
    sessions: {
      hasData: false,
      items: []
    },
    transaction_revenue: {
      hasData: false,
      items: []
    },
    transactions: {
      hasData: false,
      items: []
    },
    conversions: {
      hasData: false,
      items: []
    },
  },

  summaryChannels: {},
  itemsChannels: {},

  summaryChannelsPeriod: {
    df: {
      [PERIOD_OPTIONS.WEEK_TO_WEEK]: {},
      [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: {},
      [PERIOD_OPTIONS.MONTH_TO_MONTH]: {},
      [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: {},
      [PERIOD_OPTIONS.DAY_TO_DAY]: {},
      [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: {},
    },
    ga: {
      [PERIOD_OPTIONS.WEEK_TO_WEEK]: {},
      [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: {},
      [PERIOD_OPTIONS.MONTH_TO_MONTH]: {},
      [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: {},
      [PERIOD_OPTIONS.DAY_TO_DAY]: {},
      [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: {},
    }
  },
  itemsChannelsPeriod: {
    [PERIOD_OPTIONS.WEEK_TO_WEEK]: {},
    [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: {},
    [PERIOD_OPTIONS.MONTH_TO_MONTH]: {},
    [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: {},
    [PERIOD_OPTIONS.DAY_TO_DAY]: {},
    [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: {}
  },

  itemsPeriod: {
    [PERIOD_OPTIONS.WEEK_TO_WEEK]: {
      hasData: false,
      items: []
    },
    [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: {
      hasData: false,
      items: []
    },
    [PERIOD_OPTIONS.MONTH_TO_MONTH]: {
      hasData: false,
      items: []
    },
    [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: {
      hasData: false,
      items: []
    },
    [PERIOD_OPTIONS.DAY_TO_DAY]: {
      hasData: false,
      items: []
    },
    [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: {
      hasData: false,
      items: []
    }
  },

  entitiesSummaryPeriod: {
    [PERIOD_OPTIONS.WEEK_TO_WEEK]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.MONTH_TO_MONTH]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.DAY_TO_DAY]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: {
      hasData: false,
      data: {}
    }
  },

  catalogEntities: [
    {id: CATALOG_ENTITY_TYPE.LISTING, name: 'Листинги'},
    {id: CATALOG_ENTITY_TYPE.TAG_LISTING, name: 'Теговые листинги'},
    {id: CATALOG_ENTITY_TYPE.OFFER, name: 'Карточки'},
  ],

  summaryEntityMetrics: [
    {id: 'ws2', name: 'Спрос'},
    {id: 'count_all', name: 'Сессии'},
    {id: 'transactions', name: 'Транзакции'},
    {id: 'conversions', name: '%CR', percent: true},
    {id: 'transaction_revenue', name: 'Выручка'},
  ],

  entitiesChannelsPeriod: {
    [PERIOD_OPTIONS.WEEK_TO_WEEK]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.MONTH_TO_MONTH]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.DAY_TO_DAY]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: {
      hasData: false,
      data: {}
    }
  },

  itemsEcomDefault: {
    hasData: false,
    items: []
  },

  summaryEcomDefault: {
    hasData: false,
    data: {}
  },

  itemsEcomPeriod: {
    [PERIOD_OPTIONS.WEEK_TO_WEEK]: {
      hasData: false,
      items: []
    },
    [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: {
      hasData: false,
      items: []
    },
    [PERIOD_OPTIONS.MONTH_TO_MONTH]: {
      hasData: false,
      items: []
    },
    [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: {
      hasData: false,
      items: []
    },
    [PERIOD_OPTIONS.DAY_TO_DAY]: {
      hasData: false,
      items: []
    },
    [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: {
      hasData: false,
      items: []
    }
  },

  itemsEcomChannelsPeriod: {
    [PERIOD_OPTIONS.WEEK_TO_WEEK]: {},
    [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: {},
    [PERIOD_OPTIONS.MONTH_TO_MONTH]: {},
    [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: {},
    [PERIOD_OPTIONS.DAY_TO_DAY]: {},
    [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: {}
  },

  summaryEcomPeriod: {
    df: {
      [PERIOD_OPTIONS.WEEK_TO_WEEK]: {
        hasData: false,
        data: {}
      },
      [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: {
        hasData: false,
        data: {}
      },
      [PERIOD_OPTIONS.MONTH_TO_MONTH]: {
        hasData: false,
        data: {}
      },
      [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: {
        hasData: false,
        data: {}
      },
      [PERIOD_OPTIONS.DAY_TO_DAY]: {
        hasData: false,
        data: {}
      },
      [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: {
        hasData: false,
        data: {}
      }
    }
  },

  summaryEcomChannelsPeriod: {
    df: {
      [PERIOD_OPTIONS.WEEK_TO_WEEK]: {},
      [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: {},
      [PERIOD_OPTIONS.MONTH_TO_MONTH]: {},
      [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: {},
      [PERIOD_OPTIONS.DAY_TO_DAY]: {},
      [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: {},
    }
  },

  devicesSummaryPeriod: {
    [PERIOD_OPTIONS.WEEK_TO_WEEK]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.MONTH_TO_MONTH]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.DAY_TO_DAY]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: {
      hasData: false,
      data: {}
    }
  },

  devicesChannelsPeriod: {
    [PERIOD_OPTIONS.WEEK_TO_WEEK]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.WEEK_TO_WEEK_PAST_YEAR]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.MONTH_TO_MONTH]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.MONTH_TO_MONTH_PAST_YEAR]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.DAY_TO_DAY]: {
      hasData: false,
      data: {}
    },
    [PERIOD_OPTIONS.DAY_TO_DAY_PAST_WEEK]: {
      hasData: false,
      data: {}
    }
  },

  itemsDevices: {},

  summaryDevices: {}
})
