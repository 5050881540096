import AUTH from './auth.js'

/* Методы запросов */
export const METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
    DOWNLOAD: 'DOWNLOAD'
};

/* Имена записей в локальное хранилище */
const LOCAL_STORAGE_DATA = {
    LOCKER_MENU: 'sidebarLockerBlocked',
    /* ID активного проекта */
    ACTIVE_PROJECT: 'active_project'
};

export const DATE_FORMAT = {
    DAY_MONTH_SHORT_YEAR_DOT: 'DD.MM.YY',
    BASE_FORMAT: 'YYYY-MM-DD',
    BASE_FORMAT_REVERSE: 'DD-MM-YYYY',
    BASE_FORMAT_REVERSE_DOT: 'DD.MM.YYYY',
    FULL_YEAR: 'YYYY',
    DAY_FULL_MONTH: 'D MMMM',
    DAY_FULL_MONTH_FULL_YEAR: 'D MMMM YYYY'
}

export const SEMANTIC_SECTIONS = {
    CATEGORY: 'category',
    GROUP: 'group',
    QUERY_GROUP: 'query_group',
    QUERY_GROUP_CAMELCASE: 'queryGroup',
    URL: 'url',
    QUERY: 'query'
}

export const SEARCH_SYSTEM = {
    YANDEX: 'yandex',
    GOOGLE: 'google'
}

export const NOTIFY_STATUS = {
    SUCCESS: 'success',
    ERROR: 'error'
}

/* Query переменные для отображения данных в режиме разработки */
export const DEV_PARAMS = {
    /* Feature флаг */
    FEATURE_FLAG: 'feature-flag'
}

export const CATALOG_ENTITY_TYPE = {
    LISTING: 'listing',
    TAG_LISTING: 'tag_listing',
    OFFER: 'offer'
}

export const PERIOD_OPTIONS = {
  WEEK_TO_WEEK: 'week_to_week',
  WEEK_TO_WEEK_PAST_YEAR: 'week_to_week_past_year',
  MONTH_TO_MONTH: 'month_to_month',
  MONTH_TO_MONTH_PAST_YEAR: 'month_to_month_past_year',
  DAY_TO_DAY: 'day_to_day',
  DAY_TO_DAY_PAST_WEEK: 'day_to_day_past_week'
}

export const PROJECT_WITH_ALL_WS_TYPES = 9082

export default {
    METHODS,
    LOCAL_STORAGE_DATA,
    DATE_FORMAT,
    SEMANTIC_SECTIONS,
    SEARCH_SYSTEM,
    NOTIFY_STATUS,
    DEV_PARAMS,
    CATALOG_ENTITY_TYPE,
    PERIOD_OPTIONS,
    PROJECT_WITH_ALL_WS_TYPES,
    ...AUTH
};
