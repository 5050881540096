import Vue from 'vue'
import { findKey } from '@/function'

const mutations = {
    /* Сохранение каталога */
    saveCatalog(state, payload) {
        if (payload.hasData) {
            let catalogItems = [...state.catalog.items]
            let parentCatalogItem = catalogItems[payload.parentIndex]
            let result = []

            for (let id in payload.data) {
                let newCatalogItem = payload.data[id]
                newCatalogItem.id = +id
                newCatalogItem.level = payload.level
                newCatalogItem.open = false
                newCatalogItem.view = true
                newCatalogItem.catalogLength = 0

                if (payload.catalogId === '0') {
                    newCatalogItem.parentIds = [0]
                } else {
                    newCatalogItem.active = true
                    newCatalogItem.parentIds = [...parentCatalogItem.parentIds, payload.catalogId]
                }

                result.push(newCatalogItem)
            }

            if (payload.catalogId === '0') {
                Vue.set(state, 'catalog', {
                    has_data: !!(result && result.length),
                    items: result,
                    total: payload.total
                })
            } else {
                let previousItemIndex = payload.parentIndex + parentCatalogItem.catalogLength + 1

                parentCatalogItem.catalogLength += result.length
                parentCatalogItem.hasMore = payload.total > parentCatalogItem.catalogLength

                if (payload.moreData) {
                    catalogItems.forEach((item, i) => {
                        if (i < payload.parentIndex || i > previousItemIndex) {
                            item.active = false
                        } else if (i >= payload.parentIndex && i <= previousItemIndex) {
                            item.active = true
                        }
                    })

                    result[result.length - 1].hasNext = parentCatalogItem.hasMore

                    parentCatalogItem.active = true
                    parentCatalogItem.open = true
                    parentCatalogItem.hasData = true

                    if (payload.mainFlag) {
                        catalogItems.splice(previousItemIndex + 1, 1, ...result)
                    } else {
                        catalogItems.splice(previousItemIndex, 1, ...result)
                    }
                } else {
                    catalogItems.forEach(item => {
                        item.active = false
                    })

                    result[result.length - 1].hasNext = state.catalog.items[payload.parentIndex].hasMore
                    parentCatalogItem.active = true
                    parentCatalogItem.open = true
                    parentCatalogItem.hasData = true

                    if (payload.mainFlag) {
                        result.unshift({
                            ...parentCatalogItem,
                            id: 0,
                            listingPage: true,
                            is_listing: false,
                            level: payload.level,
                            active: true,
                            view: true,
                            catalogLength: 0,
                            parentIds: [...parentCatalogItem.parentIds, payload.catalogId],
                            hasData: false
                        })
                    }

                    catalogItems.splice(payload.parentIndex + 1, 1, ...result)
                }

                Vue.set(state, 'catalog', {
                    has_data: !!(catalogItems && catalogItems.length),
                    items: catalogItems,
                    total: state.catalog.total
                })
            }
        } else if (payload.catalogId === '0') {
            Vue.set(state, 'catalog', {
                has_data: false,
                items: [],
                total: 0
            })
        }
    },

    /* Установка видимости прелоудера */
    setPreloader(state, payload) {
        if (!payload.onInit) {
            let catalogItems = [...state.catalog.items]

            if (!payload.active) {
                catalogItems.forEach(item => {
                    item.load = false
                })
            } else {
                let lastItemIndex

                if (payload.mainFlag) {
                    lastItemIndex = findKey(payload.catalogId, catalogItems, 'catalog_id')
                } else {
                    lastItemIndex = payload.index + payload.offset
                }

                if (payload.moreData) {
                    catalogItems[lastItemIndex].hasNext = false
                }

                catalogItems.forEach((item, i) => {
                    if (i > lastItemIndex) {
                        item.load = true
                    }
                })

                catalogItems.splice(lastItemIndex + 1, 0, { preloader: true, view: true })
            }

            Vue.set(state, 'catalog', {
                has_data: true,
                items: catalogItems,
                total: state.catalog.total
            })
        }

        Vue.set(state, 'preloaderTable', payload.active)
    },

    /* Переключение видимости строк */
    toggleTableRows (state, payload) {
        let updatedCatalogItems = [...state.catalog.items]
        let selectedItem = updatedCatalogItems[payload.index]
        selectedItem.active = !payload.open
        selectedItem.open = !payload.open

        for (let i = payload.index + 1; i < updatedCatalogItems.length; i++) {
            let item = updatedCatalogItems[i]

            if ((payload.open && item.parentIds && item.parentIds.includes(payload.catalogId)) || (!payload.open && item.parentIds && item.parentIds.indexOf(payload.catalogId) === item.parentIds.length - 1)) {
                item.open = false
                item.active = !payload.open
                item.view = !payload.open
            }
        }

        Vue.set(state, 'catalog', {
            has_data: true,
            items: updatedCatalogItems,
            total: state.catalog.total
        })
    },

    /* Сохранение данных итого */
    saveSummary (state, payload) {
        const name = payload.traffic ? 'summaryGA' : 'summary'
        if (payload.hasData) {
            Vue.set(state, name, {
                has_data: true,
                data: payload.data || {}
            })
        } else {
            Vue.set(state, name, {
                has_data: false,
                data: {}
            })
        }
    }
}

export default mutations
