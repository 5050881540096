<template>
    <div
        class="notifications"
        :style="styles"
    >
        <component
            :is="componentName"
            :name="animationName"
            @enter="enter"
            @leave="leave"
            @after-leave="clean"
        >
            <div
                v-for="item in active"
                class="notification-wrapper"
                :style="notifyWrapperStyle(item)"
                :key="item.id"
                :data-id="item.id"
            >
                <slot
                    name="body"
                    :class="[classes, item.type]"
                    :item="item"
                    :close="() => destroy(item)"
                >
                    <!-- Default slot template -->
                    <div
                        :class="notifyClass(item)"
                    >
                        <div class="notification-icon" v-if="item.icon">
                            <svg :fill="item.icon.fill">
                                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                                     :xlink:href="'#' + item.icon.name"></use>
                            </svg>
                        </div>

                        <div class="notification-text">
                            <div
                                v-if="item.title"
                                class="notification-title"
                                v-html="item.title"
                            >
                            </div>
                            <div
                                v-if="item.text"
                                class="notification-content"
                                v-html="item.text"
                            >
                            </div>
                        </div>

                        <div v-if="item.closed" class="notification-close" @click="destroy(item)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path
                                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                            </svg>
                        </div>
                    </div>
                </slot>
            </div>
        </component>
    </div>
</template>
<script>
    import plugin from './index'
    import {events} from './events'
    import {Id, split, listToDirection} from './util'
    import defaults from './defaults'
    import VelocityGroup from './VelocityGroup.vue'
    import CssGroup from './CssGroup.vue'
    import parseNumericValue from './parser'

    const STATE = {
        IDLE: 0,
        DESTROYED: 2
    };

    const Component = {
        name: 'Notifications',
        components: {
            VelocityGroup,
            CssGroup
        },
        props: {
            typeClass: {
                type: String,
                default: undefined
            },

            group: {
                type: String,
                default: ''
            },

            icon: {
                type: Object
            },

            width: {
                type: [Number, String],
                default: 424
            },

            reverse: {
                type: Boolean,
                default: false
            },

            position: {
                type: [String, Array],
                default: () => {
                    return defaults.position
                }
            },

            classes: {
                type: String,
                default: 'vue-notification'
            },

            animationType: {
                type: String,
                default: 'css',
                validator(value) {
                    return value === 'css' || value === 'velocity'
                }
            },

            animation: {
                type: Object,
                default() {
                    return defaults.velocityAnimation
                }
            },

            animationName: {
                type: String,
                default: defaults.cssAnimation
            },

            speed: {
                type: Number,
                default: 300
            },
            /* Todo */
            cooldown: {
                type: Number,
                default: 0
            },

            duration: {
                type: Number,
                default: 3000
            },

            delay: {
                type: Number,
                default: 0
            },

            max: {
                type: Number,
                default: Infinity
            },

            closeOnClick: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                list: [],
                velocity: plugin.params.velocity
            }
        },
        mounted() {
            events.$on('add', this.addItem);
        },
        computed: {
            actualWidth() {
                return parseNumericValue(this.width)
            },
            /**
             * isVelocityAnimation
             */
            isVA() {
                return this.animationType === 'velocity'
            },

            componentName() {
                return this.isVA
                    ? 'VelocityGroup'
                    : 'CssGroup'
            },

            styles() {
                const {x, y} = listToDirection(this.position);
                const width = this.actualWidth.value;
                const suffix = this.actualWidth.type;

                let styles = {
                    width: width + suffix,
                    [y]: '0px',
                    // top: `${this.$sizes.headerHeight || 0}px`
                };

                if (x === 'center') {
                    styles['left'] = `calc(50% - ${width / 2}${suffix})`
                } else {
                    styles[x] = '0px'
                }

                return styles
            },

            active() {
                return this.list.filter(v => v.state !== STATE.DESTROYED)
            },

            botToTop() {
                return this.styles.hasOwnProperty('bottom')
            },
        },
        methods: {
            addItem(event) {
                event.group = event.group || '';

                if (this.group !== event.group) {
                    return
                }

                if (event.clean || event.clear) {
                    this.destroyAll();
                    return
                }

                const duration = typeof event.duration === 'number'
                    ? event.duration
                    : this.duration;

                const speed = typeof event.speed === 'number'
                    ? event.speed
                    : this.speed;

                let {title, text, type, data, closed} = event;

                const item = {
                    id: Id(),
                    title,
                    text,
                    type,
                    state: STATE.IDLE,
                    speed,
                    length: duration + 2 * speed,
                    data
                };

                if (closed !== false) {
                    item.closed = true
                }

                let dataIcon = {};

                if (this.icon) {
                    dataIcon.name = item.iconName
                } else {
                    switch (type) {
                        case 'error':
                            dataIcon.name = 'error';
                            break;
                        case 'success':
                            dataIcon.name = 'done';
                            break;
                        case 'warning':
                            dataIcon.name = 'cancel';
                            break;
                        case 'delete':
                            dataIcon.name = 'delete';
                            break;
                    }
                }

                if (item.iconFill) {
                    dataIcon.fill = item.iconFill
                } else {
                    switch (type) {
                        case 'error':
                            dataIcon.fill = '#F44336';
                            break;
                        case 'success':
                            dataIcon.fill = '#4CAF50';
                            break;
                        case 'warning':
                            dataIcon.fill = '#F44336';
                            break;
                        case 'delete':
                            dataIcon.fill = '#2196F3';
                            break;
                        default:
                            dataIcon.fill = '#000';
                            break;
                    }
                }

                if (dataIcon.name) {
                    item.icon = dataIcon
                }

                if (duration >= 0) {
                    item.timer = setTimeout(() => {
                        this.destroy(item)
                    }, item.length)
                }

                let direction = this.reverse
                    ? !this.botToTop
                    : this.botToTop;

                let indexToDestroy = -1;

                if (direction) {
                    this.list.push(item);

                    if (this.active.length > this.max) {
                        indexToDestroy = 0
                    }
                } else {
                    this.list.unshift(item);

                    if (this.active.length > this.max) {
                        indexToDestroy = this.active.length - 1
                    }
                }

                if (indexToDestroy !== -1) {
                    this.destroy(this.active[indexToDestroy])
                }
            },

            notifyClass(item) {
                return [
                    'vue-notification-template',
                    this.classes,
                    item.type
                ]
            },

            notifyWrapperStyle(item) {
                return this.isVA
                    ? null
                    : {
                        transition: `all ${item.speed}ms`
                    }
            },

            destroy(item) {
                clearTimeout(item.timer);
                item.state = STATE.DESTROYED;

                if (!this.isVA) {
                    this.clean()
                }
            },

            destroyAll() {
                this.active.forEach(this.destroy)
            },

            getAnimation(index, el) {
                const animation = this.animation[index];

                return typeof animation === 'function'
                    ? animation.call(this, el)
                    : animation
            },

            enter({el, complete}) {
                const animation = this.getAnimation('enter', el);

                this.velocity(el, animation, {
                    duration: this.speed,
                    complete
                })
            },

            leave({el, complete}) {
                let animation = this.getAnimation('leave', el);

                this.velocity(el, animation, {
                    duration: this.speed,
                    complete
                })
            },

            clean() {
                this.list = this.list.filter(v => v.state !== STATE.DESTROYED)
            }
        }
    };

    export default Component
</script>
<style lang="scss" scoped>
    .notifications {
        display: block;
        position: fixed;
        z-index: 5000;
    }

    .notification-wrapper {
        display: block;
        width: 100%;
        margin: 0;
        overflow: visible;
        padding: 16px 24px 16px 0;
    }

    .notification-title {
        font-weight: 600;
    }

    .notification-content {
        margin-top: 5px;
    }

    .vue-notification-template {
        display: block;
        box-sizing: border-box;
        background: white;
        text-align: left;
    }

    .vue-notification {
        padding: 16px;
        border-radius: 6px;
        background-color: #FFFFFF;
        border: 0;
        color: #7D7D7D;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
        0 1px 10px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px 0 rgba(0, 0, 0, 0.2);

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .notification-icon {
        flex-basis: 24px;
        min-width: 24px;
        width: 24px;
        height: 24px;
        margin-right: 16px;

        & svg {
            width: 100%;
            height: 100%;
        }
    }

    .notification-close {
        fill: #7D7D7D;
        margin-left: auto;

        &:hover {
            fill: #2196F3;
        }

        & svg {
            flex-basis: 16px;
            min-width: 16px;
            width: 16px;
            height: 16px;
            margin-left: 16px;
        }
    }

    .vn-fade-enter-active, .vn-fade-leave-active, .vn-fade-move {
        transition: all .5s;
    }

    .vn-fade-enter, .vn-fade-leave-to {
        opacity: 0;
    }
</style>
