import constants from '@/const/'
import errors  from '@/store/errors'

const actions = {
    /* Загрузка каталога */
    getCatalog ({commit, rootState}, input) {
        if (input.query.summary) {
            commit('saveSummary', {
                hasData: false
            })
        } else if (input.query.catalog_id === '0') {
            commit('saveCatalog', {
                hasData: false,
                catalogId: '0'
            })
        }

        return new Promise((resolve, reject) => {
            rootState.request({
                type: constants.METHODS.GET,
                url: input.url || '/catalog',
                auth: true,
                apiName: 'main',
                query: input.query,
                cancelable: true
            }).then(result => {
                if (result.has_data) {
                    if (input.query.summary) {
                        commit('saveSummary', {
                            hasData: true,
                            data: result.data.summary,
                            traffic: input.query.traffic_all
                        })
                    } else {
                        commit('saveCatalog', {
                            hasData: result.has_data,
                            catalogId: input.query.catalog_id,
                            data: result && result.data && result.data.items || [],
                            total: result.data.total,
                            level: input.level,
                            parentIndex: input.index,
                            moreData: input.moreData,
                            mainFlag: input.query.main
                        })
                    }
                } else {
                    if (input.query.summary) {
                        commit('saveSummary', {
                            hasData: false,
                            traffic: input.query.traffic_all
                        })
                    } else {
                        commit('saveCatalog', {
                            hasData: false,
                            catalogId: input.query.catalog_id
                        })
                    }
                }
                resolve(true)
            }, () => {
                reject(new Error(errors.GET_DATA))
            })
        })
    }
}

export default actions
