import DIRECTORIES from '../directories.js'
import ROUTE_NAME from '../routeName.js'

const clearContainer = () => import('@/services/clearContainer.vue')

const biComparisonCorrelationSales = () => import('@/services/bi/pages/comparison/pages/correlationSales/')
const biComparisonDynamicsSales = () => import('@/services/bi/pages/comparison/pages/dynamicsSales/')
const biComparePeriods = () => import('@/services/bi/pages/comparePeriods/')
const biTraction = () => import('@/services/bi/pages/traction/')
const biCompareChannels = () => import('@/services/bi/pages/compareChannels/')
const biCrossSales = () => import('@/services/bi/pages/crossSales/')
const biOverview = () => import('@/services/bi/pages/overview/')

export function bi (i18n) {
  let contentPath = 'routes.bi'

  return {
    title: i18n.t(contentPath)[ROUTE_NAME.BI],
    name: ROUTE_NAME.BI,
    path: DIRECTORIES.BI,
    component: clearContainer,
    redirect: {name: ROUTE_NAME.BI_COMPARISON},
    meta: {main: true},
    notLink: true,
    children: [
      {
        title: i18n.t(contentPath)[ROUTE_NAME.BI_COMPARISON],
        name: ROUTE_NAME.BI_COMPARISON,
        path: DIRECTORIES.BI + '/comparison/:projectId(\\d+)?',
        component: clearContainer,
        redirect: {name: ROUTE_NAME.BI_COMPARISON_CORRELATION_SALES},
        notLink: true,
        children: [
          {
            title: i18n.t(contentPath)[ROUTE_NAME.BI_COMPARISON_CORRELATION_SALES],
            name: ROUTE_NAME.BI_COMPARISON_CORRELATION_SALES,
            path: DIRECTORIES.BI + '/comparison/correlation-sales/:projectId(\\d+)?',
            component: biComparisonCorrelationSales
          },
          {
            title: i18n.t(contentPath)[ROUTE_NAME.BI_COMPARISON_DYNAMICS_SALES],
            name: ROUTE_NAME.BI_COMPARISON_DYNAMICS_SALES,
            path: DIRECTORIES.BI + '/comparison/dynamics-sales/:projectId(\\d+)?',
            component: biComparisonDynamicsSales
          }
        ]
      },
      {
        title: i18n.t(contentPath)[ROUTE_NAME.BI_CATEGORY],
        name: ROUTE_NAME.BI_CATEGORY,
        path: DIRECTORIES.BI + '/overview/:activeProject(\\d+)?',
        component: biOverview
      },
      {
        title: i18n.t(contentPath)[ROUTE_NAME.BI_COMPARE_PERIODS],
        name: ROUTE_NAME.BI_COMPARE_PERIODS,
        path: DIRECTORIES.BI + '/compare-periods/:activeProject(\\d+)?',
        component: biComparePeriods
      },
      {
        title: i18n.t(contentPath)[ROUTE_NAME.BI_TRACTION],
        name: ROUTE_NAME.BI_TRACTION,
        path: DIRECTORIES.BI + '/traction/:activeProject(\\d+)?',
        component: biTraction
      },
      {
        title: i18n.t(contentPath)[ROUTE_NAME.BI_COMPARE_CHANNELS],
        name: ROUTE_NAME.BI_COMPARE_CHANNELS,
        path: DIRECTORIES.BI + '/compare-channels/:activeProject(\\d+)?',
        component: biCompareChannels
      },
      {
        name: ROUTE_NAME.BI_CROSS_SALES,
        path: DIRECTORIES.BI + '/cross-sales/:activeProject(\\d+)?',
        component: biCrossSales
      },
      {
        name: ROUTE_NAME.BI_COMMERCE_OVERVIEW,
        path: DIRECTORIES.BI + '/commerce-overview/:activeProject(\\d+)?',
        component: biOverview
      }
    ]
  }
}
