import Vue from 'vue'
import errors  from '@/store/errors'

import constants from '@/const/'

const actions = {
    /* Загрузка профиля */
    getProfile: function({state, commit, getters, rootState}) {
        return new Promise((resolve, reject) => {
            rootState.request({
                type: constants.METHODS.GET,
                apiName: 'main',
                url: '/user/profile',
                auth: true
            }).then(result => {
                commit('getProfile', result.data);
                resolve(true)
            }, error => {
                // reject(errors.PROFILE)
            });
        });
    },

    /* Запрос списка меню */
    getAsideList: function({state, commit, getters, rootState}, input) {
        return new Promise((resolve, reject) => {
            rootState.request({
                type: constants.METHODS.GET,
                apiName: 'main',
                url: '/menu',
                auth: true
            }).then(result => {
                if (result.data) {
                    commit('setAsideList', result.data);
                    resolve(true)
                } else {
                    // reject(errors.GET_ASIDE)
                }
            }, error => {
                // reject(errors.GET_ASIDE)
            });
        });
    },

    /* Получение регионов */
    getRegions({commit, rootState}, input) {
        return new Promise((resolve, reject) => {
            rootState.request({
                type: constants.METHODS.GET,
                url: '/dictionary/region/yandex',
                query: {
                    q: input.search,
                    metrics: 'semantics'
                },
                auth: true,
                apiName: 'seowork',
            }).then(result => {
                commit('saveRegions', {
                    data: result.data
                });
                resolve(true)
            }, error => {
                // reject(errors.GET_DATA)
            })
        })
    },

    /* Загрузка файлов */
    fileUpload: function ({rootState}, input) {
        return new Promise((resolve, reject) => {
            rootState.request({
                type: constants.METHODS.POST,
                apiName: 'dataforce',
                url: '/file/upload',
                auth: true,
                body: input
            }).then(response => {
                resolve(response)
            }, () => {
                reject(false)
            })
        })
    },

    /* Получение списка клиентов */
    getClients ({commit, rootState}, input) {
        return new Promise((resolve, reject) => {
            rootState.request({
                type: constants.METHODS.GET,
                url: '/clients',
                auth: true,
                apiName: 'seowork',
                query: {
                    active: input && input.query && input.query.active ? input.query.active : 1
                }
            }).then(result => {
                commit('saveClients', result)
                resolve(true)
            }, () => {
                reject(errors.GET_DATA)
            })
        })
    },

    /* Запрос списка проектов */
    getProjects: function ({state, commit, getters, rootState}, input) {
        return new Promise((resolve, reject) => {
            let projects = { ...state.options.projects }
            let query = {}

            if (input && input.search) {
                if (input.firstPage) {
                    commit('clearProjectsSearch')
                }

                query.limit = projects.search.limit
                query.offset = projects.search.offset
                query.q = input && input.search ? input.search : undefined
            } else {
                if (!state.options.projects.next) {
                    resolve({next: false})
                }

                query.limit = projects.limit
                query.offset = projects.offset
            }

            rootState.request({
                type: constants.METHODS.GET,
                apiName: 'main',
                url: '/projects',
                query: {
                    active: 1,
                    ...query
                },
                auth: true
            }).then(result => {
                if (input && input.search && input.search !== '') {
                    commit('pushProjectsSearch', {
                        has_data: result.has_data,
                        next: result.data.has_more,
                        items: result.data.items
                    })
                } else {
                    commit('pushProjects', {
                        has_data: result.has_data,
                        next: result.data.has_more,
                        items: result.data.items
                    })
                }

                resolve({ next: result.data.has_more && !!result.data.items.length })
            }, error => {
                reject(false)
            })
        })
    },

    /* Запрос данных по проекту */
    getProject: function ({state, commit, getters, rootState}, input) {
        return new Promise((resolve, reject) => {
            rootState.request({
                type: constants.METHODS.GET,
                apiName: 'main',
                url: '/projects/additional',
                auth: true,
                query: {
                    project_id: input.id
                }
            }).then(result => {
                commit('getProject', {})
                try {
                    if(result.data && result.data.id) {
                        commit('getProject', result.data)
                        commit('tableSettings/setFrequencyTypeNames', {
                            ...result.data,
                            key: 'catalogTableCellsConfig'
                        }, {root: true})
                        commit('tableSettings/setFrequencyTypeNames', {
                            ...result.data,
                            key: 'listingTableCellsConfig'
                        }, {root: true})
                        commit('tableSettings/setFrequencyTypeNames', {
                            ...result.data,
                            key: 'cardTableCellsConfig'
                        }, {root: true})
                        resolve(true)
                    } else {
                        throw ''
                    }
                } catch (_) {
                    reject(errors.NO_DATA_AVAILABLE)
                }
            }, () => {
                reject(errors.GET_DATA)
            })
        })
    },

    /* Запрос данных для виджета Intercom */
    getIntercom: function ({state, commit, getters, rootState}, input) {
        return new Promise((resolve, reject) => {
            rootState.request({
                type: constants.METHODS.GET,
                url: '/interaction/intercom',
                auth: true,
                apiName: 'main'
            }).then(result => {
                resolve(result.data)
            }, error => {
                reject(errors.GET_DATA)
            })
        })
    },

    /* Загрузка опций для фильтров */
    getFilters ({commit, rootState}, input) {
        commit('saveFilters', {hasData: false})

        return new Promise((resolve, reject) => {
            rootState.request({
                type: constants.METHODS.GET,
                url: '/demensions',
                auth: true,
                apiName: 'main',
                query: input.query
            }).then(result => {
                if (result.has_data) {
                    commit('saveFilters', {
                        hasData: result.has_data,
                        data: result.data
                    })
                    resolve(true)
                } else {
                    reject(new Error(errors.NO_DATA_AVAILABLE))
                }
            }, () => {
                reject(errors.GET_DATA)
            })
        })
    },

    /* Загрузка опций для фильтра по трафику */
    getFiltersTraffic ({state, commit, rootState}, input) {
        return new Promise((resolve, reject) => {
            let data = {...state.filtersTraffic[input.query.dimension]}

            let query = {...input.query}

            if (input.firstPage) {
                commit('clearFiltersTraffic', input.query.dimension)
                data = {...state.filtersTraffic[input.query.dimension]}
            } else {
                query.offset = data.offset
            }

            query.limit = data.limit

            if (input && input.query && input.query.q) {
                if (input.firstPage) {
                    commit('clearFiltersTrafficSearch', input.query.dimension)
                }

                query.limit = data.search.limit
                query.offset = data.search.offset
            } else {
                if (!state.filtersTraffic[input.query.dimension].next) {
                    resolve({next: false})
                }
                query.limit = input.query.limit || data.limit
                query.offset = data.offset
            }

            rootState.request({
                type: constants.METHODS.GET,
                url: '/demensions/traffic',
                auth: true,
                apiName: 'main',
                query,
                cancelable: true
            }).then(result => {
                if (result.has_data) {
                    commit('saveFiltersTraffic', {
                        hasData: result.has_data,
                        data: result.data,
                        name: input.query.dimension,
                        search: !!input.query.q
                    })

                    resolve({next: result.data.has_more && !!result.data.items.length})
                } else {
                    reject(new Error(errors.NO_DATA_AVAILABLE))
                }
            }, () => {
                reject(errors.GET_DATA)
            })
        })
    },

    /* Поиск регионов */
    searchRegions ({state, commit, rootState}, input) {
        return new Promise((resolve, reject) => {
            rootState.request({
                type: constants.METHODS.GET,
                url: '/search_regions',
                auth: true,
                apiName: 'main',
                query: input.query,
                cancelable: true
            }).then(result => {
                if (result.has_data) {
                    commit('saveRegionSearchList', {
                        hasData: result.has_data,
                        data: result.data
                    })
                }
                resolve(true)
            }, () => {
                reject(new Error(errors.GET_DATA))
            })
        })
    }
}

export default actions
