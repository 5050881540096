import bi from './bi/'
import segments from './segments/'
import semantics from './semantics/'
import dashboard from './dashboard/'

export default {
    bi,
    segments,
    semantics,
    dashboard
}
