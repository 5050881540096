const state = {
  /* Каталог */
  catalog: {
    hasData: false,
    items: [],
    total: 0
  },

  /* Сегменты пользователя */
  userSegments: {
    hasData: false,
    items: [],
    total: 0
  },

  /* Данные итого по проекту DF */
  summary: {
    has_data: false,
    data: {}
  },
}

export default state
