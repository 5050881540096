import DIRECTORIES from '../directories.js'
import ROUTE_NAME from '../routeName'

/* Контейнер страницы */
const clearContainer = () => import('@/services/clearContainer')

const containerCatalog = () => import('@/services/dashboard/pages/segments/content/container/')

const segmentsCatalog = () => import('@/services/dashboard/pages/segments/content/pages/catalog/')
const segmentsCatalogList = () => import('@/services/dashboard/pages/segments/content/pages/list/')
const segmentsCatalogItem = () => import('@/services/dashboard/pages/segments/content/pages/item/')

export function segments () {
    return {
        title: 'Segments',
        name: ROUTE_NAME.SEGMENTS,
        path: DIRECTORIES.SEGMENTS + '/content/:activeProject(\\d+)?',
        component: clearContainer,
        redirect: {name: ROUTE_NAME.SEGMENTS_CONTENT},
        children: [
            {
                title: 'Content',
                name: ROUTE_NAME.SEGMENTS_CONTENT,
                path: DIRECTORIES.SEGMENTS + '/content/:activeProject(\\d+)',
                component: containerCatalog,
                redirect: {name: ROUTE_NAME.SEGMENTS_CONTENT_INDEX},
                children: [
                    {
                        title: 'Content Index',
                        name: ROUTE_NAME.SEGMENTS_CONTENT_INDEX,
                        path: DIRECTORIES.SEGMENTS + '/content/:activeProject(\\d+)',
                        component: segmentsCatalog
                    },
                    {
                        title: 'Segment List',
                        name: ROUTE_NAME.SEGMENTS_CONTENT_LIST,
                        path: DIRECTORIES.SEGMENTS + '/content/:activeProject(\\d+)/list/:catalogId(\\d+)',
                        component: segmentsCatalogList
                    },
                    {
                        title: 'Segment',
                        name: ROUTE_NAME.SEGMENTS_CONTENT_ITEM,
                        path: DIRECTORIES.SEGMENTS + '/content/:activeProject(\\d+)/list/:catalogId(\\d+)/item/:segmentId(\\d+)',
                        component: segmentsCatalogItem
                    }
                ]
            },
            {
                name: ROUTE_NAME.SEGMENTS_EXPERIMENTS,
                path: '/segments/experiments/:activeProject(\\d+)?',
                component: () => import('@/services/dashboard/pages/segments/experiments/')
            },
        ]
    }
}
