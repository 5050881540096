import Vue from 'vue'

const mutations = {
  saveProjectFlags(state, data) {
      Vue.set(state, 'projectFlags', data && data.data && data.data.items || [])
  },

  saveProjectsByChunks (state, payload) {
    let data = payload.projects
    data.items.forEach((project, i) => {
      project.report = (payload.report && payload.report[i] && payload.report[i].data && payload.report[i].data.items) || {}
    })
    state.projects.items.push(data.items)
    state.projects.items = state.projects.items.flat()
    state.projects.total = data.total
  },

  clearProjects(state) {
    state.projects = {
      items: [],
      total: 0
    }
  }
}

export default mutations
