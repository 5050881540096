import CONSTANTS from '@/const/'
import errors  from '@/store/errors'

const actions = {
    /* Загрузка списка сущностей */
    getUserSegments: function ({commit, rootState}, input) {
        return new Promise((resolve, reject) => {
            if (input.query.summary) {
                commit('saveSummary', {
                    hasData: false
                })
            } else  {
                commit('saveSegments', {
                    has_data: false
                })
            }

            rootState.request({
                type: CONSTANTS.METHODS.GET,
                url: '/user_segments',
                auth: true,
                apiName: 'main',
                query: input.query
            }).then(result => {
                if (result && result.data) {
                    if (input.query.summary) {
                        commit('saveSummary', {
                            hasData: true,
                            data: result.data.summary
                        })
                    } else {
                        commit('saveSegments', {
                            hasData: result.has_data,
                            data: result.data
                        })
                    }
                }
                resolve(true)
            }, () => {
                reject(new Error(errors.GET_DATA))
            })
        })
    }
}

export default actions
