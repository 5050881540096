import CONSTANTS from '@/const/'
import errors from '@/store/errors.js'
import Url from 'url-parse'

export default {
  getSegmentData: function ({state, commit, getters, rootState}, input) {
    return new Promise((resolve, reject) => {
      rootState.request({
        type: CONSTANTS.METHODS.GET,
        apiName: 'main',
        url: `/segment/catalog/${input.catalogId}`,
        auth: true,
        query: input.query
      }).then(result => {
        if (result.has_data) {
          commit('setSegmentData', {
            segment_id: input.query.id,
            has_data: result.has_data,
            data: result.data
          })
        }
        resolve(true)
      }, () => {
        reject(new Error(errors.GET_DATA))
      })
    })
  },

  getSegmentsItems: function ({state, commit, getters, rootState}, input) {
    return new Promise((resolve, reject) => {
      let url = `/segment/custom/${input.segmentId}`

      if (input.query.download || input.query.format) {
        let urlLink = Url(rootState.config.api['main'] + url)

        let newQueries = {}
        if (input.query) {
          for (let i in input.query) {
            let item = input.query[i]
            if (Array.isArray(item)) {
              for (let k in item) {
                let key = `${i}[${k}]`
                newQueries[key] = item[k]
              }
            } else if (item) {
              newQueries[i] = item
            }
          }
        }

        urlLink.set('query', newQueries)
        window.location.replace(urlLink.href)
      } else {
        commit('setList', [])

        rootState.request({
          type: CONSTANTS.METHODS.GET,
          apiName: 'main',
          url: url,
          auth: true,
          query: {
            offset: (+input.page - 1) * state.data.limit,
            limit: state.data.limit,
            ...input.query
          }
        }).then(result => {
          commit('setList', {
            has_data: result.has_data,
            has_more: result.data.has_more,
            items: result.data.items.map(item => ({
              ...item,
              selected: false
            })),
            total: result.data.total
          })
          resolve(true)
        }, () => {
          reject(new Error(errors.GET_DATA))
        })
      }
    })
  },

  /* Удаление элемента */
  removeSegmentItems: function ({rootState}, input) {
    return new Promise((resolve, reject) => {
      rootState.request({
        type: CONSTANTS.METHODS.POST,
        url: '/segment/custom/remove',
        auth: true,
        apiName: 'main',
        query: input.query,
        body: input.body
      }).then(() => {
        resolve(true)
      }, () => {
        reject(new Error(errors.GET_DATA))
      })
    })
  },

  relocateSegment: function ({rootState}, input) {
    return new Promise((resolve, reject) => {
      rootState.request({
        type: CONSTANTS.METHODS.POST,
        apiName: 'main',
        url: `/segment/custom/${input.segmentId}/relocate`,
        auth: true,
        query: input.query,
        body: input.body
      }).then(result => {
        if (result.has_data) {
          resolve(true)
        } else {
          reject(new Error(errors.GET_DATA))
        }
      }, () => {
        reject(new Error(errors.GET_DATA))
      })
    })
  },

  renameSegment: function ({rootState}, input) {
    return new Promise((resolve, reject) => {
      rootState.request({
        type: CONSTANTS.METHODS.POST,
        apiName: 'main',
        url: `/segment/custom/${input.segmentId}/rename`,
        auth: true,
        query: input.query,
        body: input.body
      }).then(result => {
        if (result.has_data) {
          resolve(true)
        } else {
          reject(new Error(errors.UPDATE))
        }
      }, error => {
        if (error && error.body && error.body.data && error.body.data.errors) {
          reject(error.body.data.errors)
        } else {
          reject(errors.UPDATE)
        }
      })
    })
  }
}
