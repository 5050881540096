import constants from '@/const/'
import errors from '@/store/errors.js'

const requestList = (rootState, input, config) => {
  return rootState.request({
    type: constants.METHODS.GET,
    apiName: 'main',
    url: `/segment/catalog/${input.catalogId}`,
    auth: true,
    query: config.query
  }).then(result => {
    return result
  }, () => {
    return false
  })
}

export default {
  getList: function ({state, commit, getters, rootState}, input) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('setList', [])
        let payload = await requestList(rootState, input, {
          query: {
            offset: (+input.page - 1) * state.data.limit,
            limit: state.data.limit,
            type: input.type,
            ...input.query
          }
        })

        if (payload) {
          commit('setList', {
            ...payload.data,
            has_data: payload.has_data,
            counts: {
              [input.type]: payload.data.total
            }
          })
          resolve(true)
        } else {
          reject(new Error(errors.GET_DATA))
        }
      })()
    })
  },

  /* Получение списка пользователей */
  getAuthors ({state, commit, rootState}, input) {
    return new Promise((resolve, reject) => {
      if (!state.autors.client_id || state.autors.client_id !== input.client_id) {
        rootState.request({
          type: constants.METHODS.GET,
          url: '/user/list',
          auth: true,
          apiName: 'main',
          query: {
            client_id: input.client_id
          }
        }).then(result => {
          if (result.has_data) {
            commit('setAuthors', {
              client_id: input.client_id,
              payload: result
            })
          }
          resolve(true)
        }, () => {
          reject(new Error(errors.GET_DATA))
        })
      } else {
        resolve(true)
      }
    })
  },

  removeSegment: function ({commit, rootState}, input) {
    return new Promise((resolve, reject) => {
      rootState.request({
        type: constants.METHODS.POST,
        url: `/segment/custom/${input.segmentId}/remove`,
        auth: true,
        apiName: 'main',
        query: input.query,
        body: {}
      }).then(_ => {
        resolve(true)
      }, _ => {
        reject(new Error(errors.GET_DATA))
      })
    })
  }
}
