import {mapActions} from 'vuex'
import {required, minLength, maxLength, requiredIf} from 'vuelidate/lib/validators'
import {transformationInTreeV2, validateUrl} from '@/function'
import MODAL_TITLE from '@/modals/modalTitle'
import ROUTE_NAME from '@/router/routeName'
import {NOTIFY_STATUS} from '@/const'

import error from '@/services/errors/block'

const contentPathSegmentsCatalog = 'services.segments.catalog'

const listToArray = valueString => {
  const valueArray = valueString
      .split('\n')
      .filter(item => item !== '')

  return valueArray.filter((item, pos) => valueArray.indexOf(item) === pos)
}

const validUrls = value => listToArray(value).every(item => validateUrl(item))

const DATA_TYPE = {
  FILE: 'file',
  LIST: 'list'
}

export default {
  name: 'segmentCreate',

  components: {
    'v-error': error
  },

  props: {
    projectId: {
      type: Number,
      required: true
    },

    catalogId: {
      type: Number
    }
  },

  data () {
    return {
      contentPathSegmentsCatalog,

      preloader: false,

      tabs: {
        value: DATA_TYPE.FILE,
        options: {
          options: [
            {
              id: DATA_TYPE.LIST,
              name: this.$t(contentPathSegmentsCatalog)['List']
            },
            {
              id: DATA_TYPE.FILE,
              name: this.$t(contentPathSegmentsCatalog)['File']
            }
          ]
        }
      },

      dropzone: {
        method: 'post',
        maxFiles: 1,
        autoProcessQueue: false
      },

      form: {
        catalog_id: null,
        name: ''
      },

      catalog: {
        items: [],
        options: []
      },

      fileForm: undefined,
      urls: '',
      DATA_TYPE
    }
  },

  validations: {
    form: {
      catalog_id: {
        required
      },

      name: {
        requiredIf: requiredIf(function () {
          return this.tabs.value === DATA_TYPE.LIST
        }),
        minLength: minLength(4),
        maxLength: maxLength(255)
      }
    },

    fileForm: {
      requiredIf: requiredIf(function () {
        return this.tabs.value === DATA_TYPE.FILE
      })
    },

    urls: {
      requiredIf: requiredIf(function () {
        return this.tabs.value === DATA_TYPE.LIST
      }),
      validUrls
    }
  },

  computed: {
    formInvalid () {
      return this.$v.form.$invalid ||
          (this.tabs.value === DATA_TYPE.FILE && this.$v.fileForm.$invalid) ||
          (this.tabs.value === DATA_TYPE.LIST && this.$v.urls.$invalid)
    }
  },

  watch: {
    'tabs.value' () {
      this.fileForm = undefined
      this.urls = ''
    }
  },

  created () {
    this.initData()
  },

  methods: {
    ...mapActions({
      getCatalog: 'segments/catalog/getCatalog',
      createTask: 'segments/catalog/createTask'
    }),

    async submit () {
      if (this.$v.form.$invalid) {
        this.$notify({type: NOTIFY_STATUS.ERROR, title: this.$t(this.contentPathSegmentsCatalog)['Validation error']})
      } else {
        try {
          let form = JSON.parse(JSON.stringify(this.form))
          let payload = {
            query: {project_id: this.projectId}
          }

          if (this.tabs.value === DATA_TYPE.FILE) {
            payload.headers = {'Content-Type': 'multipart/form-data'}
            payload.body = new FormData()
            payload.body.append('catalog_id', form.catalog_id)
            payload.body.append('file', this.fileForm)
          } else {
            payload.query.scenario = 'urls'
            payload.body = {
              segment_name: form.name,
              catalog_id: form.catalog_id,
              items: listToArray(this.urls)
            }
          }

          await this.createTask(payload)
            .then(() => {
              this.$modal.open({
                name: MODAL_TITLE.FORM_SUCCESS,
                right: true,
                props: {
                  title: 'Новый сегмент отправлен в обработку',
                  link: {
                    linkText: 'Посмотреть список заданий',
                    to: {
                      name: ROUTE_NAME.SEGMENTS_CONTENT_INDEX,
                      params: {activeProject: this.projectId}
                    }
                  }
                }
              })
            })
            .catch(e => {
              throw e
          })
        } catch ({message}) {
          this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
        }
      }
    },

    async initData () {
      this.preloader = true

      await this.getCatalogData()

      if (this.catalogId) {
        this.$set(this.form, 'catalog_id', String(this.catalogId))
      }

      this.preloader = false
    },

    /* Загрузка каталога */
    async getCatalogData () {
      if (this.projectId) {
        try {
          let result = await this.getCatalog({
            modals: true,
            query: {
              project_id: this.projectId
            }
          })

          if (result.has_data) {
            let categories = result.data.items

            this.$set(this, 'catalog', {
              items: categories,
              options: transformationInTreeV2(categories, true)
            })
          } else {
            throw new Error('Каталог сегментов не найден')
          }
        } catch (error) {
          this.$notify({type: NOTIFY_STATUS.ERROR, title: error.message})
        }
      }
    },

    uploadFile (file) {
      this.fileForm = file
    },

    deleteFile () {
      if (this.fileForm) {
        this.fileForm = undefined
      }
      this.$refs.dropzone.$children[0].removeAllFiles()
    },

    setName (value) {
      if (value) {
        this.form.name = value
        this.$v.form.name.$touch()
      }
    }
  }
}
