import constants from '@/const/'
const PROJECT_WITH_ALL_WS_TYPES = constants.PROJECT_WITH_ALL_WS_TYPES

const state = {
    catalogTableCellsConfig: [
        {
            name: 'Спрос',
            id: 'demand',
            items: [
                {
                    name: 'WS',
                    id: 'ws1',
                    visible: false,
                    needFormat: true,
                    filterName: 'ws1',
                    availableFor: PROJECT_WITH_ALL_WS_TYPES
                },
                {
                  name: '«WS»',
                  id: 'ws2',
                  visible: false,
                  needFormat: true,
                  filterName: 'ws2'
              },
              {
                  name: '«!WS»',
                  id: 'ws3',
                  visible: false,
                  needFormat: true,
                  filterName: 'ws3',
                  availableFor: PROJECT_WITH_ALL_WS_TYPES
              }
            ]
        },
        {
            name: 'Трафик',
            id: 'traffic',
            items: [
                {
                    name: 'Сессии',
                    id: 'count_all',
                    visible: true,
                    needFormat: true,
                    disabled: true,
                    filterName: 'sessions'
                },
                {
                    name: 'Новые пользователи',
                    id: 'new_users',
                    visible: false,
                    needFormat: true,
                    filterName: 'new_users'
                }
            ]
        },
        {
            name: 'Продажи',
            id: 'sales',
            items: [
                {
                    name: 'Транзакции',
                    id: 'transactions',
                    visible: true,
                    needFormat: true,
                    filterName: 'transactions'
                },
                {
                    name: '%CR',
                    id: 'conversions',
                    visible: true,
                    needFormat: false,
                    additional: '%',
                    filterName: 'conversions'
                },
                {
                    name: 'Выручка (GA)',
                    id: 'transaction_revenue',
                    visible: true,
                    needFormat: true,
                    filterName: 'transaction_revenue'
                }
            ]
        },
        {
            name: 'Выкуп (CRM)',
            id: 'crm',
            items: [
                {name: 'Транзакции', id: 'transactions_crm', visible: false, needFormat: true},
                {name: '%Выкупа', id: 'buying', visible: false, needFormat: true},
                {name: 'Выручка (CRM)', id: 'transaction_revenue_crm', visible: false, needFormat: true}
            ]
        }
    ],

    listingTableCellsConfig: [
        {
            name: 'Спрос',
            id: 'demand',
            items: [
                {
                    name: 'WS',
                    id: 'ws1',
                    visible: false,
                    needFormat: true,
                    filterName: 'ws1',
                    availableFor: PROJECT_WITH_ALL_WS_TYPES
                },
                {
                    name: '«WS»',
                    id: 'ws2',
                    visible: false,
                    needFormat: true,
                    filterName: 'ws2'
                },
                {
                    name: '«!WS»',
                    id: 'ws3',
                    visible: false,
                    needFormat: true,
                    filterName: 'ws3',
                    availableFor: PROJECT_WITH_ALL_WS_TYPES
                }
            ]
        },
        {
            name: 'Трафик',
            id: 'traffic',
            items: [
                {
                    name: 'Сессии',
                    id: 'count_all',
                    visible: true,
                    needFormat: true,
                    disabled: true,
                    filterName: 'sessions'
                },
                {
                    name: 'Пользователи',
                    id: 'users',
                    visible: false,
                    needFormat: true,
                    filterName: 'users'
                },
                {
                    name: 'Новые пользователи',
                    id: 'new_users',
                    visible: false,
                    needFormat: true,
                    filterName: 'new_users'
                }
            ]
        },
        {
            name: 'Продажи',
            id: 'sales',
            items: [
                {
                    name: 'Транзакции',
                    id: 'transactions',
                    visible: true,
                    needFormat: true,
                    filterName: 'transactions'
                },
                {
                    name: '%CR',
                    id: 'conversions',
                    visible: true,
                    needFormat: false,
                    additional: '%',
                    filterName: 'conversions'
                },
                {
                    name: 'Выручка (GA)',
                    id: 'transaction_revenue',
                    visible: true,
                    needFormat: true,
                    filterName: 'transaction_revenue'
                }
            ]
        },
        {
            name: 'Выкуп (CRM)',
            id: 'crm',
            items: [
                {name: 'Транзакции', id: 'transactions_crm', visible: false, needFormat: true},
                {name: '%Выкупа', id: 'buying', visible: false, needFormat: true},
                {name: 'Выручка (CRM)', id: 'transaction_revenue_crm', visible: false, needFormat: true}
            ]
        }
    ],

    cardTableCellsConfig: [
        {
            name: 'Спрос',
            id: 'demand',
            items: [
                {
                    name: 'WS',
                    id: 'ws1',
                    visible: false,
                    needFormat: true,
                    filterName: 'ws1',
                    availableFor: PROJECT_WITH_ALL_WS_TYPES
                },
                {
                    name: '«WS»',
                    id: 'ws2',
                    visible: false,
                    needFormat: true,
                    filterName: 'ws2'
                },
                {
                    name: '«!WS»',
                    id: 'ws3',
                    visible: false,
                    needFormat: true,
                    filterName: 'ws3',
                    availableFor: PROJECT_WITH_ALL_WS_TYPES
                }
            ]
        },
        {
            name: 'Трафик',
            id: 'traffic',
            items: [
                {
                    name: 'Сессии',
                    id: 'count_all',
                    visible: true,
                    needFormat: true,
                    disabled: true,
                    filterName: 'sessions'
                },
                {
                    name: 'Пользователи',
                    id: 'users',
                    visible: false,
                    needFormat: true,
                    filterName: 'users'
                },
                {
                    name: 'Новые пользователи',
                    id: 'new_users',
                    visible: false,
                    needFormat: true,
                    filterName: 'new_users'
                },
                {
                    name: 'Брендовый трафик',
                    id: 'brand_traffic',
                    visible: false,
                    needFormat: true
                }
            ]
        },
        {
            name: 'Продажи',
            id: 'sales',
            items: [
                {
                    name: 'Транзакции',
                    id: 'transactions',
                    visible: true,
                    needFormat: true,
                    filterName: 'transactions'
                },
                {
                    name: '%CR',
                    id: 'conversions',
                    visible: true,
                    needFormat: false,
                    additional: '%',
                    filterName: 'conversions'
                },
                {
                    name: 'Выручка (GA)',
                    id: 'transaction_revenue',
                    visible: true,
                    needFormat: true,
                    filterName: 'transaction_revenue'
                },
                {
                    name: 'Просмотры товаров',
                    id: 'product_detail_views',
                    visible: false,
                    filterName: 'product_detail_views'
                },
                {
                    name: 'Добавления в корзину',
                    id: 'product_adds_to_cart',
                    visible: false,
                    filterName: 'product_adds_to_cart'
                },
                {
                    name: 'Checkout',
                    id: 'product_checkouts',
                    visible: false,
                    filterName: 'product_checkouts'
                },
                {
                    name: 'Куплено товаров',
                    id: 'item_quantity',
                    visible: false,
                    filterName: 'item_quantity'
                },
                {
                    name: 'Выручка',
                    id: 'item_revenue',
                    visible: false,
                    filterName: 'item_revenue'
                }
            ]
        },
        {
            name: 'ECOM',
            id: 'ecom',
            items: [
                {
                    name: 'Товар маркетплейса',
                    id: 'merchant_is_marketplace',
                    visible: false,
                    filterName: 'merchant_is_marketplace'
                }
            ]
        }
    ],

    commerceAnalysisTableCellsConfig: [
        {
            name: 'Спрос',
            id: 'demand',
            items: [
                {
                    name: 'WS',
                    id: 'ws1',
                    visible: true,
                    filterName: 'ws1',
                    disabled: true
                },
                {
                    name: '«WS»',
                    id: 'ws2',
                    visible: true,
                    filterName: 'ws2',
                }
            ]
        },
        {
            name: 'Товар маркетплейса',
            id: 'marketplace',
            items: [
                {
                    name: 'Товар маркетплейса',
                    id: 'merchant_is_marketplace',
                    visible: true,
                    filterName: 'merchant_is_marketplace'
                }
            ]
        },
        {
            name: 'Продажи',
            id: 'sales',
            items: [
                {
                    name: 'Просмотры товаров',
                    id: 'product_detail_views',
                    visible: true,
                    filterName: 'product_detail_views'
                },
                {
                    name: 'Добавления в корзину',
                    id: 'product_adds_to_cart',
                    visible: true,
                    filterName: 'product_adds_to_cart'
                },
                {
                    name: 'Checkout',
                    id: 'product_checkouts',
                    visible: true,
                    filterName: 'product_checkouts'
                },
                {
                    name: 'Куплено товаров',
                    id: 'item_quantity',
                    visible: true,
                    filterName: 'item_quantity'
                },
                {
                    name: 'Выручка',
                    id: 'item_revenue',
                    visible: true,
                    filterName: 'item_revenue'
                }
            ]
        }
    ],

    contentAnalysisTableCellsConfig: [
        {
            name: 'UX',
            id: 'ux',
            items: [
                {
                    name: 'Кол-во вопросов',
                    id: 'count_questions',
                    visible: true,
                    filterName: 'count_questions'
                },
                {
                    name: 'Кол-во ответов',
                    id: 'number_of_answers',
                    visible: true,
                    filterName: 'number_of_answers'
                },
                {
                    name: 'Кол-во комментариев',
                    id: 'number_of_comments',
                    visible: true,
                    filterName: 'number_of_comments'
                },
                {
                    name: 'Рейтинг',
                    id: 'rating',
                    visible: true,
                    filterName: 'rating'
                },
                {
                    name: 'Кол-во отзывов',
                    id: 'count_review',
                    visible: true,
                    filterName: 'count_review'
                },
                {
                    name: 'Кол-во позитивных отзывов',
                    id: 'count_positive_review',
                    visible: true,
                    filterName: 'count_positive_review'
                },
                {
                    name: 'Кол-во негативных отзывов',
                    id: 'count_negative_review',
                    visible: true,
                    filterName: 'count_negative_review'
                },
                {
                    name: 'Объем описания самого полезного отзыва',
                    id: 'length_most_helpful_review',
                    visible: true,
                    filterName: 'length_most_helpful_review'
                }
            ]
        },
        {
            name: 'MEDIA',
            id: 'media',
            items: [
                {
                    name: 'Кол-во фото',
                    id: 'count_photos',
                    visible: true,
                    filterName: 'count_photos'
                },
                {
                    name: 'Кол-во видео',
                    id: 'count_videos',
                    visible: true,
                    filterName: 'count_videos'
                },
                {
                    name: 'Кол-во видео 360',
                    id: 'count_video_360',
                    visible: true,
                    filterName: 'count_video_360'
                }
            ]
        },
        {
            name: 'ECOM',
            id: 'ecom',
            items: [
                {
                    name: 'Розничная цена',
                    id: 'price',
                    visible: true,
                    filterName: 'price'
                },
                {
                    name: 'Товар маркетплейса',
                    id: 'merchant_is_marketplace',
                    visible: true,
                    filterName: 'merchant_is_marketplace'
                }
            ]
        },
        {
            name: 'Спрос',
            id: 'demand',
            items: [
                {
                    name: 'WS',
                    id: 'ws1',
                    visible: true,
                    filterName: 'ws1',
                    availableFor: PROJECT_WITH_ALL_WS_TYPES
                },
                {
                    name: '«WS»',
                    id: 'ws2',
                    visible: true,
                    filterName: 'ws2'
                },
                {
                    name: '«!WS»',
                    id: 'ws3',
                    visible: true,
                    filterName: 'ws3',
                    availableFor: PROJECT_WITH_ALL_WS_TYPES
                }
            ]
        },
        {
            name: 'Трафик',
            id: 'traffic',
            items: [
                {
                    name: 'Сессии',
                    id: 'count_all',
                    visible: true,
                    disabled: true,
                    filterName: 'sessions'
                },
                {
                    name: 'Пользователи',
                    id: 'users',
                    visible: true,
                    filterName: 'users'
                },
                {
                    name: 'Новые пользователи',
                    id: 'new_users',
                    visible: true,
                    filterName: 'new_users'
                }
            ]
        },
        {
            name: 'Продажи',
            id: 'sales',
            items: [
                {
                    name: 'Транзакции',
                    id: 'transactions',
                    visible: true,
                    filterName: 'transactions'
                },
                {
                    name: '%CR',
                    id: 'conversions',
                    visible: true,
                    additional: '%',
                    filterName: 'conversions'
                },
                {
                    name: 'Выручка (GA)',
                    id: 'transaction_revenue',
                    visible: true,
                    filterName: 'transaction_revenue'
                }
            ]
        },
        {
            name: 'Выкуп (CRM)',
            id: 'crm',
            items: [
                {name: 'Транзакции', id: 'transactions_crm', visible: false},
                {name: '%Выкупа', id: 'buying', visible: false},
                {name: 'Выручка (CRM)', id: 'transaction_revenue_crm', visible: false}
            ]
        }
    ],

    abcAnalysisTableCellsConfig: [
        {
            name: 'Спрос',
            id: 'demand',
            items: [
                {
                    name: '«WS»',
                    id: 'ws2',
                    visible: false,
                    needFormat: true,
                    filterName: 'ws2'
                }
            ],
        },
        {
            name: 'Трафик',
            id: 'traffic',
            items: [
                {
                    name: 'Сессии',
                    id: 'count_all',
                    visible: true,
                    needFormat: true,
                    disabled: true,
                    filterName: 'sessions'
                },
                {
                    name: 'Пользователи',
                    id: 'users',
                    visible: false,
                    needFormat: true,
                    filterName: 'users'
                },
                {
                    name: 'Новые пользователи',
                    id: 'new_users',
                    visible: false,
                    needFormat: true,
                    filterName: 'new_users'
                }
            ]
        },
        {
            name: 'Продажи',
            id: 'sales',
            items: [
                {
                    name: 'Транзакции',
                    id: 'transactions',
                    visible: true,
                    needFormat: true,
                    filterName: 'transactions'
                },
                {
                    name: '%CR',
                    id: 'conversions',
                    visible: true,
                    needFormat: false,
                    additional: '%',
                    filterName: 'conversions'
                },
                {
                    name: 'Выручка (GA)',
                    id: 'transaction_revenue',
                    visible: true,
                    needFormat: true,
                    filterName: 'transaction_revenue'
                }
            ]
        }
    ],

    commerceOverviewTableCellsConfig: [
      {
          name: 'Продажи',
          id: 'ecom',
          disabled: true,
          items: [
              {
                  name: 'Просмотры товаров',
                  id: 'product_detail_views',
                  visible: true,
                  needFormat: true,
                  filterName: 'product_detail_views'
              },
              {
                  name: 'Добавление в корзину',
                  id: 'product_adds_to_cart',
                  visible: true,
                  needFormat: true,
                  filterName: 'product_adds_to_cart'
              },
              {
                  name: 'Сheckout',
                  id: 'product_checkouts',
                  visible: true,
                  needFormat: true,
                  filterName: 'product_checkouts'
              },
              {
                  name: 'Куплено товаров',
                  id: 'item_quantity',
                  visible: true,
                  needFormat: true,
                  filterName: 'item_quantity'
              },
              {
                  name: 'Выручка',
                  id: 'item_revenue',
                  visible: true,
                  needFormat: true,
                  filterName: 'item_revenue'
              }
          ]
      }
  ],
}

export default state
