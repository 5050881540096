<template>
  <div class="tags">
    <div v-if="startSlot" class="tags__start">
      <slot name="start"></slot>
    </div>

    <v-tags-item
        v-for="(tag, i) in tags"
        v-bind="{
          ...tag,
          clearable
        }"
        :key="`tag-${i}-${tag.name}`"
    />
  </div>
</template>

<script>
export default {
  components: {
    'v-tags-item': () => import('./tagItem')
  },
  props: {
    tags: {
      type: Array
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      isLoad: false
    }
  },

  computed: {
    startSlot () {
      return !!(this.isLoad && this.$slots.start)
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.isLoad = true
    })
  }
}
</script>

<style lang="scss" scoped>
  .tags {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 5px;
    padding: 32px;

    &__start {
      margin-right: 8px;
      color: #455165;
      font-size: 14px;
    }

    &._p-0 {
      padding: 0;
    }
  }
</style>
