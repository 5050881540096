<template>
  <div
    :style="componentStyle"
    :class="[
            'filters__item',
            { '_right': right },
            { '_left': left },
            { '_noline': noline },
            { '_bottom': bottom}
        ]"
  >
    <slot/>
  </div>
</template>

<script>
/**
   * Компонент блока фильтров
   */
export default {
  props: {
    width: {
      type: String
    },

    right: {
      type: Boolean,
      default: false
    },

    left: {
      type: Boolean,
      default: false
    },

    border: {
      type: Boolean,
      default: false
    },

    bottom: {
      type: Boolean,
      default: false
    },

    noline: {
      type: Boolean,
      default: false
    },

    col: {
      type: [Number, String]
    },

    customStyle: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    componentStyle () {
      let result = {}

      if (this.width) result.width = this.width

      if (this.col) result.flexBasis = `${100 / this.col}%`

      if (this.bottom) result.alignItems = 'flex-end'

      if (Object.keys(this.customStyle).length) result = {...result, ...this.customStyle}

      return result
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped/>
