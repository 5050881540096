import { checkSelection, treeFilter, transformationInTree, transformationInTreeV2, findOptions } from '@/function'

export default {
  segmentItem: state => ({id, type}) => {
    if (state.segments.has_data) {
      return state.segments.items.find(item => item.id === id && item.type === type)
    } else {
      return undefined
    }
  },

  nestedCatalogSegmentsSW: state => ({ search }) => {
    let tree = []

    if (state.dataSW.has_data && state.segments.has_data) {
      let catalog = JSON.parse(JSON.stringify(state.dataSW.items))
      let segments = JSON.parse(JSON.stringify(state.segments.items))

      /* Фильтруем сегменты */
      segments = segments.filter(segment => {
        return String(segment.name).toLowerCase().indexOf(search.toLowerCase()) !== -1
      })

      /* Добавляем сегменты в разделы */
      catalog = checkSelection(catalog).map(section => {
        if (section.is_selection) {
          section.segments = segments.filter(segment => {
            try {
              return segment.catalog.id === section.id
            } catch (_) {
              return false
            }
          })
        }

        return section
      })

      /* Фильтрация пустых разделов */
      catalog = catalog.filter(section => !section.is_selection || (!!section.is_selection && section.segments && section.segments.length))

      /* Трансформация в дерево */
      tree = treeFilter({ items: transformationInTree(catalog) }).items
    }

    return tree
  },

  nestedCatalogSegmentsDF: state => ({ search, isCustom }) => {
    let tree = []

    const name = isCustom ? 'dataDFCustom' : 'dataDF'

    if (state[name].has_data) {
      let catalog = JSON.parse(JSON.stringify(state[name].items))

      /* Фильтруем сегменты */
      catalog = catalog.filter(segment => {
        return String(segment.name).toLowerCase().indexOf(search.toLowerCase()) !== -1
      })

      if (isCustom) {
        catalog = catalog.map(segment => {
          return {
            ...segment,
            selectable: !state.data.items.find(item => item.id === String(segment.id)),
            isCustom: true
          }
        })
      }

      /* Трансформация в дерево */
      tree = {items: transformationInTreeV2(catalog)}
    }

    if (search) {
      tree.items.sort((a, b) => {
        const itemsA = a.items && a.items.length
        const itemsB = b.items && b.items.length

        if (itemsA && !itemsB) {
          return -1
        } else if (!itemsA && itemsB) {
          return 1
        } else {
          return 0
        }
      })

      return tree.items
    } else if (tree.items && tree.items.length === 1) {
      return tree.items[0].items
    }

    return tree.items
  },

  itemCatalog: state => id => {
    if (state.data.has_data) {
      let data = JSON.parse(JSON.stringify(state.data))
      return findOptions(String(id), data.items)
    } else {
      return undefined
    }
  },

  breadcrumbsCatalog: state => id => {
    if (state.data.has_data) {
      let data = JSON.parse(JSON.stringify(state.data))
      return findOptions(String(id), data.breadcrumbs)
    } else {
      return undefined
    }
  },

  listCatalog: state => {
    if (state.data.has_data) {
      let data = JSON.parse(JSON.stringify(state.data))
      return {
        has_data: data.has_data,
        project_id: data.project_id,
        items: data.tree
      }
    } else {
      return state.data
    }
  }
}
