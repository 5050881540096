function findIdInSegmentsTree (segments, id) {
  if (!segments || !segments.length || !id) {
    return false
  }

  return segments.find((segment) => {
    if (segment && segment.items && segment.items.length) {
      findIdInSegmentsTree(segment.items, id)
    }

    return segment.id === id
  })
}

function findSegmentIds(segments, ids) {
  if (!segments || !segments.items || !ids || !ids.length) {
     return []
  }

  return ids.filter((id) => findIdInSegmentsTree(segments.items, id))
}

export function getSegmentQueryParams (customSegments, catalogSegments, urlFilters) {
  if (!customSegments || !catalogSegments || !urlFilters) {
    return
  }

  let customSegmentsIds = findSegmentIds(customSegments, urlFilters.catalog_ids)
  let catalog_ids = findSegmentIds(catalogSegments, urlFilters.catalog_ids)

  if (urlFilters.control_group) {
    const isCustomCG = findIdInSegmentsTree(customSegments.items, urlFilters.control_group)

    if (isCustomCG) {
      customSegmentsIds = [urlFilters.control_group, ...customSegmentsIds]
    } else {
      catalog_ids = [urlFilters.control_group, ...catalog_ids]
    }
  }

  return {
    segments: customSegmentsIds.length ? customSegmentsIds : undefined,
    catalog_ids: catalog_ids.length ? catalog_ids : undefined
  }
}
