<template>
  <div class="cards-container _custom-scrollbar">
    <v-card-add-item
        v-for="card of cardsFiltered"
        :key="card.id"
        :data="card"
        :single-card-visible="singleCardVisible"
        style="min-width: 230px"
        @toggle-active="$emit('toggle-active', $event)"
    />
  </div>
</template>

<script>
import {findOptions} from '@/function'
import CARD_GROUP_NAME from '../../../cardGroupName'

import cardAddItem from './cardAddItem.vue'

export default {
  name: 'cardAdd',

  components: {
    'v-card-add-item': cardAddItem
  },

  props: {
    cards: {
      type: Object,
      required: true
    },

    cardConfig: {
      type: Array,
      required: true
    },

    cardSearch: {
      type: String,
      default: ''
    },

    singleCardVisible: {
      type: Boolean,
      default: false
    },

    cardGroups: {
      type: Array,
      default: () => ([])
    }
  },

  computed: {
    cardsFiltered () {
      let temp = []
      let cards = [...Object.values(this.cards), ...this.cardGroups].map(item => {
              return {
                id: item.id,
                title: item.title,
                items: item.items ? [...item.items] : undefined,
                parent: item.parent || []
              }
            })

      cards.forEach(item => {
          const newItem = {...item}
          const configItem = findOptions(item.id, this.cardConfig)
          if (configItem) {
            newItem.visible = configItem.visible
          } else {
            newItem.visible = temp
                .filter(card => card.parent && card.parent.includes(item.id))
                .some(card => card.visible)
          }
          temp.push(newItem)
    })

      let tree = [...temp]

      if (this.cardSearch) {
        tree = tree.filter(card => card.title.toLowerCase().includes(this.cardSearch.toLowerCase()))
      }

      tree = tree
          .reduce((a, c) => {
            const items = temp.filter(i => i.parent && i.parent.includes(c.id))
            const itemsIds = items.map(i => i.id)

            tree.forEach(item => {
              if (itemsIds.includes(item.id)) {
                item.hasParent = true
              }
            })
            c.items = tree.filter(i => i.parent && i.parent.includes(c.id))

            a.push(c)

            return a
      }, [])
          .filter(i => !i.hasParent)

      return tree
    }
  }
}
</script>

<style scoped lang="scss">
.cards-container {
  height: 285px;
  padding: 8px 0;
  overflow-y: scroll;
}
</style>
