<template>
    <div
        class="preloader"
        :class="{
          '_static': static
        }"
    >
        <div class="preloader__main">
            <div class="preloader__square"><span></span><span></span><span></span></div>
            <div class="preloader__square"><span></span><span></span><span></span></div>
            <div class="preloader__square"><span></span><span></span><span></span></div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    static: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style src="./preloader.scss" lang="scss" scoped></style>
