import constants from '@/const/'
import errors from '@/store/errors.js'

const actions = {
  /* Запрос апдейтов */
  getUpdates ({rootState, commit, dispatch}, input) {
    return new Promise((resolve, reject) => {
      rootState.request({
        type: constants.METHODS.GET,
        apiName: 'seowork',
        url: '/semantics/updates',
        auth: true,
        query: input.query
      }).then(result => {
        if (result.has_data) {
          resolve({
            has_data: result.has_data,
            items: result.data.items
          })
        } else {
          reject(new Error(errors.GET_DATA))
        }
      }, () => {
        reject(new Error(errors.GET_DATA))
      })
    })
  }
}

export default actions
