import constants from '@/const/'
import errors  from '@/store/errors'

const actions = {
  /* Создание проекта */
  createProject({rootState}, input) {
      return new Promise((resolve, reject) => {
          rootState.request({
              type: constants.METHODS.POST,
              url: '/projects',
              auth: true,
              body: input,
              logs: true,
              apiName: 'main'
          }).then(result => {
              resolve(result)
          }, error => {
              try {
                  if (error.body.data.errors) {
                      throw error.body.data.errors
                  } else {
                      throw errors.GET_DATA
                  }
              } catch(error) {
                  reject(error)
              }
          });
      })
  },

  /* Получение флагов сайта */
  getProjectFlags({commit, rootState}) {
      return new Promise((resolve, reject) => {
          rootState.request({
              type: constants.METHODS.GET,
              url: '/project/flags',
              auth: true,
              apiName: 'seowork'
          }).then(result => {
              commit('saveProjectFlags', result)
              resolve(true)
          })
      })
  },

  async getProjects ({commit, rootState, dispatch}, input) {
    const result = await rootState.request({
      type: constants.METHODS.GET,
      url: '/projects',
      auth: true,
      apiName: 'main',
      query: input.query
    })

    if (result && result.data && result.data.items && result.data.items.length) {
      await Promise
        .all(result.data.items.map(project => dispatch('getProjectReport', {project_id: project.id})))
        .then(report => {
          commit('saveProjectsByChunks', {
            projects: result.data,
            report
          })
      })
    }
  },

  async getProjectReport ({rootState}, input) {
    try {
      return await rootState.request({
        type: constants.METHODS.GET,
        url: '/project/report',
        query: {
          skip_regionality: 1,
          ...input
        },
        auth: true,
        apiName: 'main'
      })
    } catch (_) {
      return undefined
    }
  }
}

export default actions
