<template>
  <div class="cards-table-wrapper _custom-scrollbar">
    <table class="cards-table">
      <colgroup>
        <col width="36px">
        <col width="10%">
        <col width="90%">
        <col width="65px">
      </colgroup>
      <thead>
        <tr>
          <th
            v-for="(cell, i) of headCells"
            :key="i"
            class="cards-table__cell"
          >
            {{ cell }}
          </th>
          <th
              class="cards-table__cell"
              align="center"
          >
            <v-button
                class="cards-table__button"
                type="text-base"
                icon="delete"
                padding-left="0"
                padding-right="0"
                v-tippy
                content="Удалить все"
                width="24px"
                height="24px"
                :disabled="cardsTemp.length === 1"
                @click="deleteAllCards"
            />
          </th>
        </tr>
      </thead>

      <v-draggable
        v-model="cardsTemp"
        v-bind="dragOptions"
        tag="tbody"
        handle=".cards-table__handler"
        @start="drag = true"
        @end="reorder"
      >
        <tr
          v-for="(card, i) of cardsTemp"
          :key="card.id"
        >
          <td
            class="cards-table__cell"
            align="center"
          >
            <icon
              :class="['cards-table__handler', {_grabbed: drag}]"
              name="reorder"
              fill="#BDBDBD"
            />
          </td>
          <td
            class="cards-table__cell _index"
            align="right"
          >
            {{ ++i }}
          </td>
          <td class="cards-table__cell">
            {{ card.name }}
          </td>
          <td
            class="cards-table__cell"
            align="center"
          >
            <v-button
              class="cards-table__delete"
              type="text-base"
              icon="delete"
              padding-right="0"
              padding-left="0"
              width="24px"
              height="24px"
              :disabled="cardsTemp.filter(item => item.visible).length === 1"
              @click="deleteCard(card.id)"
            />
          </td>
        </tr>
      </v-draggable>
    </table>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'cardList',

  components: {
    'v-draggable': draggable
  },

  props: {
    cards: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {
      headCells: [
        '',
        '#',
        'Стикер',
      ],

      cardsTemp: [],

      drag: false,

      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },

  watch: {
    cards (val) {
      this.cardsTemp = val
    }
  },

  created () {
    this.cardsTemp = this.cards
  },

  methods: {
    deleteCard (id) {
      this.$emit('delete-card', {id, value: false})
    },

    reorder () {
      this.drag = false
      this.$emit('reorder', this.cardsTemp.map(item => {
        return {
          id: item.id,
          visible: item.visible
        }
      }))
    },

    deleteAllCards() {
      if (this.cards.length > 1) {
        this.$emit('delete-all')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.cards-table {
  width: 100%;
  border-collapse: collapse;

  &-wrapper {
    height: 396px;
    overflow-y: scroll;
  }

  th, td {
    padding: 8px;
    border-bottom: 1px solid #E0E6F0;
  }

  th {
    background: #FAFAFA;
    color: #2D3646;
    font-size: 12px;
    font-weight: 500;
  }

  tr:last-child td {
    border-bottom: none;
  }

  &__cell {
    &._index {
      font-size: 12px !important;
    }
  }

  &__delete {
    & /deep/ .button__icon {
      width: 20px;
      height: 20px;
    }
  }

  &__handler {
    &:hover {
      cursor: pointer;
    }

    &._hovered {
      transition: all .3s ease-out;
    }

    &._grabbed {
      cursor: grab;
    }
  }

  &__button {
    font-size: 12px;

    &:hover {
      background-color: rgba(157, 153, 170, .12) !important;
    }

    & /deep/ .button__icon {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
