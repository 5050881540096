<template>
  <div class="wrapper" v-if="showModal">
    <div class="modal">
        <p class="text">{{ $t(contentPath)['We use cookies on our site to provide you with the best user experience.']}} <a :href="$t(contentPath).url || ''" target="_blank">{{ $t(contentPath)['Privacy Policy'] }}</a></p>
        <v-button
          class="button"
          :name="$t(contentPath).Ok"
          @click="acceptCookie()"
          dataforce
        />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    contentPath () {
      return 'cookie'
    },

    cookieModal () {
      return this.$cookie.get('cookie_modal')
    },

    showModal () {
      return !this.$store.getters.getCookie && !this.cookieModal
    }
  },

  methods: {
    acceptCookie () {
      this.$cookie.set('cookie_modal', true)
      this.$store.commit('acceptCookie', true)
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
