import {mapActions, mapGetters} from 'vuex'
import {required, minLength, maxLength} from 'vuelidate/lib/validators'
import {getUser} from '@/function/auth'
import {NOTIFY_STATUS} from '@/const'

import error from '@/services/errors/block'

const contentPathSegmentsCatalog = 'services.segments.catalog'

export default {
  components: {
    'v-error': error
  },

  props: {
    segment: {
      type: Object
    },

    projectId: {
      type: Number
    },

    catalogId: {
      type: Number
    },

    callback: {
      type: Function
    }
  },

  data: () => ({
    contentPathSegmentsCatalog,

    preloader: false,

    form: {
      new_catalog_id: undefined,
      name: undefined,
      segment_id: undefined
    }
  }),

  validations: {
    form: {
      new_catalog_id: {
        required
      },

      name: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(255)
      }
    }
  },

  computed: {
    ...mapGetters({
      listCatalog: 'segments/catalog/listCatalog'
    }),

    isRootUser () {
      try {
        return getUser().isRoot()
      } catch (_) {}
    },

    catalog () {
      return this.listCatalog
    },

    viewModal () {
      return !!this.projectId && !!this.segment.id
    }
  },

  async created () {
    if (this.viewModal) {
      this.form = {
        new_catalog_id: String(this.catalogId),
        name: this.segment.name,
        segment_id: this.segment.id
      }

      await this.initData()
    }
  },

  methods: {
    ...mapActions({
      getCatalog: 'segments/catalog/getCatalog',
      relocateSegment: 'segments/catalog/item/relocateSegment',
      renameSegment: 'segments/catalog/item/renameSegment'
    }),

    async submit () {
      if (this.$v.form.$invalid) {
        this.$notify({type: NOTIFY_STATUS.ERROR, title: this.$t(contentPathSegmentsCatalog)['Validation error']})
      } else {
        try {
          let result = {}

          if (this.segment.name !== this.form.name) {
            result.rename = await this.renameSegment({
              segmentId: this.segment.id,
              query: {
                project_id: this.projectId,
                skip_regionality: 1,
                catalog_id: this.catalogId
              },
              body: {segment_name: this.form.name}
            })
          }

          /* Перемещение сегмента */
          if (this.form.new_catalog_id !== this.catalogId) {
            result.relocate = await this.relocateSegment({
              segmentId: this.segment.id,
              query: {
                project_id: this.projectId,
                skip_regionality: 23,
                catalog_id: this.catalogId
              },
              body: {catalog_id: this.form.new_catalog_id}
            })
          }

          this.callback(result, {
            id: this.segment.id,
            name: this.form.name
          })
        } catch (error) {
          if (typeof error === 'string') {
            this.$notify({type: NOTIFY_STATUS.ERROR, title: error})
          } else {
            for (let key in error) {
              let errorItem = error[key]
              if (Array.isArray(errorItem)) {
                errorItem.forEach(item => {
                  this.$notify({type: NOTIFY_STATUS.ERROR, title: item})
                })
              }
            }
          }
        }
      }
    },

    async initData () {
      if (!this.catalog.has_data || this.catalog.project_id !== this.projectId) {
        this.preloader = true

        try {
          await this.getCatalog({
            modals: true,
            query: {
              project_id: this.projectId
            }
          })
        } catch ({message}) {
          this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
        }

        this.preloader = false
      }
    }
  }
}
