import {findKey} from '@/function'

const getAbcCategory = value => {
    switch(true) {
        case value < 80:
            return 'a'
        case value >= 80 && value < 95:
            return 'b'
        default:
            return 'c'
    }
}

const getters = {
    data: (state, getters, rootState) => {
        const catalog = rootState.catalog.catalog
        let metrics = []

        rootState.tableSettings.abcAnalysisTableCellsConfig.forEach(section => section.items.forEach(metric => metrics.push(metric.id)))

        if (catalog.has_data && catalog.items && catalog.items.length) {
            const data = catalog.items.map(item => {
                return {
                    id: item.catalog_id,
                    url_title: item.url_title,
                    is_listing: item.is_listing,
                    listingPage: item.listingPage,
                    type_id: item.type_id,
                    count_elements: item.count_elements,
                    count_listings: item.count_listings,
                    count_tag_listings: item.count_tag_listings,
                    count_offers: item.count_offers
                }
            })

            metrics.forEach(metric => {
                const sumByMetric = catalog.items.reduce((acc, el) => {
                    acc += el[metric]
                    return acc
                }, 0)

                const catalogSortedByMetric = [...catalog.items]
                    .sort((a, b) => b[metric] - a[metric])
                    .map(item => {
                        return {
                            id: item.catalog_id,
                            [metric]: item[metric],
                            prc: (item[metric] / sumByMetric) * 100
                        }
                    })

                catalogSortedByMetric.forEach((item, i, array) => {
                    const dataIndex = findKey(item.id, data)
                    data[dataIndex][metric] = {
                        value: item[metric]
                    }
                    if (i - 1 < 0) {
                        item.prcAcc = item.prc
                        data[dataIndex][metric].prcAcc = +item.prc.toFixed(2)
                        data[dataIndex][metric].category = getAbcCategory(data[dataIndex][metric].prcAcc)
                    } else {
                        item.prcAcc = item.prc + array[i - 1].prcAcc
                        data[dataIndex][metric].prcAcc = +item.prcAcc.toFixed(2)
                        data[dataIndex][metric].category = getAbcCategory(data[dataIndex][metric].prcAcc)
                    }
                })
            })

            return data
        } else {
            return []
        }
    }
}

export default getters
