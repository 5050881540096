<template>
  <div class="right-modal-success">
    <div class="right-modal-success__icon">
      <icon
          width="56px"
          fill="#00C853"
          height="56px"
          name="check_circle"
      />
    </div>
    <div
        v-if="title"
        class="right-modal-success__title"
    >
      {{ title }}
    </div>
    <button
        v-if="link && link.to && link.linkText"
        class="right-modal-success__link"
        @click="goToLink"
    >
      {{ link.linkText }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'formSuccess',
  props: {
    title: {
      type: String,
      default: 'Данные отправлены'
    },

    link: {
      type: Object
    }
  },

  methods: {
    closeModal () {
      this.$modal.close()
    },

    goToLink () {
      this.closeModal()
      if (this.$route.name !== this.link.to.name) {
        this.$router.push(this.link.to)
      }
    }
  }
}

</script>

<style lang="scss" scoped>
  .right-modal-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 0;

    &__icon {
      margin-bottom: 15px;
    }

    &__title {
      margin-bottom: 32px;
      line-height: 28px;
      text-align: center;
      color: #000;
      font-size: 24px;
      font-weight: 500;
    }

    &__link {
      font-size: 16px;
      color: #4158D9;
    }
  }
</style>
