import CONSTANTS from '@/const/'
import errors from '@/store/errors.js'

let SEMANTIC_TYPES = [
  CONSTANTS.SEMANTIC_SECTIONS.CATEGORY,
  CONSTANTS.SEMANTIC_SECTIONS.GROUP,
  CONSTANTS.SEMANTIC_SECTIONS.QUERY_GROUP
]

const requestData = (rootState, input, config, callback = () => {}) => {
  return new Promise((resolve, reject) => {
    rootState.request({
      type: CONSTANTS.METHODS.GET,
      apiName: 'main',
      url: config.url,
      auth: true,
      query: input.query
    }).then(result => {
      callback(result)
      resolve(input.modals ? result : true)
    }, error => {
      if (error && +error.status === 403) {
        const data = {accessDenied: true}
        callback(data)
        resolve(true)
        return
      }
      reject(new Error(errors.GET_DATA))
    })
  })
}

const requestSegmentsList = (rootState, query) => rootState.request({
  type: CONSTANTS.METHODS.GET,
  apiName: 'seowork',
  url: '/segments/list',
  auth: true,
  query
}).then(result => {
  return result
}, () => {
  return false
})

export default {
  /* Загрузка каталога сегментов SW */
  getCatalogSW: function ({state, commit, getters, rootState}, input) {
    if (input.modals) {
      return requestData(rootState, input, {
        apiName: 'main',
        url: '/segments/catalog'
      })
    } else {
      let oldProjectId = state.dataSW.project_id
      let newProjectId = input.query.project_id

      if (!oldProjectId || oldProjectId !== newProjectId) {
        commit('setCatalogSW')

        return requestData(rootState, input, {
          apiName: 'seowork',
          url: '/segments/catalog'
        }, (result) => {
          commit('setCatalogSW', {
            ...result,
            project_id: input.query.project_id
          })
        })
      } else {
        return new Promise((resolve) => {
          resolve(true)
        })
      }
    }
  },

  getAllSegments: function ({state, commit, getters, rootState}, input) {
    return new Promise(async (resolve, reject) => {
      try {
        let segments = []
        for (let type of input.types || SEMANTIC_TYPES) {
          let result = await requestSegmentsList(rootState, {
            /* Максимальное ограничение на бекенде */
            limit: 15000,
            type: type,
            ...input.query
          })

          if (result && result.has_data && result.data.items) {
            segments = segments.concat(result.data.items)
          }
        }

        commit('setAllSegments', {
          segments: segments,
          project_id: input.query.project_id
        })
      } catch (_) {
        reject(false)
      }

      resolve(true)
    })
  },

  /* Загрузка древовидного каталога сегментов DF */
  getTreeCatalog: function ({state, commit, getters, rootState}, input) {
    const dataName = !!input.url ? 'dataDFCustom' : 'dataDF'
    let oldProjectId = state[dataName].project_id
    let newProjectId = input.query.project_id

    if (!oldProjectId || oldProjectId !== newProjectId || !state[dataName].has_data) {
      commit('setTreeCatalog')

      return requestData(rootState, input, {
        apiName: 'main',
        url: input.url || '/tree_catalog'
      }, (result) => {
        commit('setTreeCatalog', {
          ...result,
          project_id: input.query.project_id,
          isCustom: !!input.url
        })
      })
    } else {
      return new Promise((resolve) => {
        resolve(true)
      })
    }
  },

  /* Загрузка каталога сегментов */
  getCatalog: function ({state, commit, getters, rootState}, input) {
    if (input.modals) {
      return requestData(rootState, input, {
        apiName: 'main',
        url: '/segment/catalog/list'
      })
    } else {
      let oldProjectId = state.data.project_id
      let newProjectId = input.query.project_id

      if (!oldProjectId || oldProjectId !== newProjectId) {
        commit('setCatalog')

        return requestData(rootState, input, {
          apiName: 'main',
          url: '/segment/catalog/list'
        }, (result) => {
          commit('setCatalog', {
            ...result,
            project_id: input.query.project_id
          })
        })
      } else {
        return new Promise((resolve) => {
          resolve(true)
        })
      }
    }
  },

  getTreeUserSegments ({state, commit, getters, rootState}, input) {
    let oldProjectId = state.dataDFCustom.project_id
    let newProjectId = input.query.project_id

    if (!oldProjectId || oldProjectId !== newProjectId || !state.dataDFCustom.has_data) {
      commit('setTreeCatalog', {isCustom: true})

      return requestData(rootState, input, {
        apiName: 'main',
        url: '/tree_user_segments'
      }, (result) => {
        commit('setTreeCatalog', {
          ...result,
          project_id: input.query.project_id,
          isCustom: true
        })
      })
    } else {
      return new Promise((resolve) => {
        resolve(true)
      })
    }
  },

  /* Загрузка последних заданий */
  getTaskList: function ({commit, rootState}, input) {
    return requestData(rootState, {
      query: input.query
    }, {
      url: `/task/list`
    }, (result) => {
      commit('setTaskList', result)
    })
  },

  createTask ({rootState}, payload) {
    return new Promise((resolve, reject) => {
      rootState.request({
        type: CONSTANTS.METHODS.POST,
        url: '/segment/custom/add',
        body: payload.body,
        apiName: 'main',
        query: {
          ...payload.query,
          skip_regionality: 1
        },
        headers: payload.headers,
        auth: true,
        logs: true
      }).then(result => {
        resolve(result)
      }, () => {
        reject(new Error(errors.CREATE))
      })
    })
  },
}
