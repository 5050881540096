import Vue from 'vue'

const mutations = {
    saveCatalog (state, payload) {
        if (payload.hasData) {
            Vue.set(state, 'catalog', {
                hasData: true,
                items: payload.items,
                total: payload.total
            })
        } else {
            Vue.set(state, 'catalog', {
                hasData: false,
                items: [],
                total: 0
            })
        }
    },

    saveUserSegments (state, payload) {
      if (payload.hasData) {
        Vue.set(state, 'userSegments', {
            hasData: true,
            items: payload.items,
            total: payload.total
        })
      } else {
        Vue.set(state, 'userSegments', {
            hasData: false,
            items: [],
            total: 0
        })
      }
    },

    clearData (state) {
      state.catalog = {
        hasData: false,
        catalogId: '0',
        items: [],
        total: 0
      }

      state.userSegments = {
        hasData: false,
        items: [],
        total: 0
      }
    }
}

export default mutations
