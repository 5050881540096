export default function(queryValue, options, isNumber = false, required = false, defaultOption) {
  if (queryValue && options && options.length) {
    const value = isNumber
      ? +queryValue
      : queryValue
    const option = options.find(item => item.id === value)

    if (option) {
      return value
    } else if (required) {
      return defaultOption || options[0].id
    } else {
      return null
    }
  }
}
