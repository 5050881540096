import { render, staticRenderFns } from "./settingsTableCells.vue?vue&type=template&id=766b7830&scoped=true"
import script from "./settingsTableCells.vue?vue&type=script&lang=js"
export * from "./settingsTableCells.vue?vue&type=script&lang=js"
import style0 from "./settingsTableCells.vue?vue&type=style&index=0&id=766b7830&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "766b7830",
  null
  
)

export default component.exports