const SUMMARY_TYPES = [
  {
    id: 'sumDF',
    title: 'Итого по проекту DF',
    name: 'df'
  },
  {
    id: 'sumGA',
    title: 'Итого по всему сайту (GA)',
    name: 'ga'
  }
]

const getters = {
  metricData: state => ({
                          name,
                          two = false,
                          diff = false,
                          summary = false,
                          count = 5,
                          positive = false,
                          negative = false,
                          asc = false,
                          period,
                          ecom = false
                        }) => {
    let result = []
    const itemsData = period
      ? ecom
        ? state.itemsEcomPeriod[period]
        : state.itemsPeriod[period]
      : ecom
        ? state.itemsEcomDefault
        : state.itemsDefault

    if (itemsData.hasData) {
      result = [...itemsData.items]

      if (positive) {
        result = result.filter(item => item[`${name}_diff`] > 0)
      }

      if (negative) {
        result = result.filter(item => item[`${name}_diff`] < 0)
      }

      result = result
        .sort((a, b) => {
          if (diff) {
            const aDiff = a[`${name}_diff`]
            const bDiff = b[`${name}_diff`]

            if (asc) {
              return aDiff - bDiff
            } else {
              return bDiff - aDiff
            }
          } else {
            const aOne = a[`${name}_one`]
            const bOne = b[`${name}_one`]

            if (asc) {
              return aOne - bOne
            } else {
              return bOne - aOne
            }
          }
        })
        .slice(0, count)
        .map(item => {
          let newItem = {
            id: item.catalog_id,
            name: item.catalog_name,
            [`${name}_one`]: item[`${name}_one`]
          }

          if (two) {
            newItem[`${name}_two`] = item[`${name}_two`]
          }

          if (diff) {
            newItem[`${name}_diff`] = item[`${name}_diff`]
          }

          return newItem
        })

      if (summary && state.summaryDefault.hasData) {
        let summaryItem = {
          id: 'sum',
          name: 'Всего по проекту',
          [`${name}_one`]: state.summaryDefault.data[`${name}_one`]
        }

        if (two) {
          summaryItem[`${name}_two`] = state.summaryDefault.data[`${name}_two`]
        }

        if (diff) {
          summaryItem[`${name}_diff`] = state.summaryDefault.data[`${name}_diff`]
        }

        result.push(summaryItem)
      }
    }

    return result
  },

  metricsData: state => ({
                           metrics,
                           summary = false,
                           sortBy
                         }) => {
    let result = []
    const itemsData = state.itemsEcomDefault
    if (itemsData.hasData) {
      result = [...itemsData.items]

      if (sortBy) {
        result = result
          .sort((a, b) => {
            const aOne = a[`${sortBy}_one`]
            const bOne = b[`${sortBy}_one`]

            return bOne - aOne
          })
      }

      result = result
        .slice(0, 5)
        .map(item => {
          let newItem = {
            id: item.catalog_id,
            name: item.catalog_name
          }

          metrics.forEach(name => {
            newItem[`${name}_one`] = item[`${name}_one`]
          })

          return newItem
        })

      if (summary && state.summaryEcomDefault.hasData) {
        let summaryItem = {
          id: 'sum',
          name: 'Всего по проекту',
        }

        metrics.forEach(name => {
          summaryItem[`${name}_one`] = state.summaryEcomDefault.data[`${name}_one`]
        })

        result.push(summaryItem)
      }
    }

    return result
  },

  summaryPeriod: state => (period, type, ecom) => {
    const stateData = ecom
      ? state.summaryEcomPeriod[type][period]
      : state.summaryPeriod[type][period]
    if (stateData && stateData.hasData) {
      return stateData.data
    } else {
      return {}
    }
  },

  summaryChannelsPeriod: state => (period, type, ecom = false) => {
    const stateData = ecom
      ? state.summaryEcomChannelsPeriod[type][period]
      : state.summaryChannelsPeriod[type][period]
    if (stateData) {
      return stateData
    } else {
      return {}
    }
  },

  summaryGeo: (state, getters, rootState, rootGetters) => (metric) => {
    let geoDataArray
    let regions
    let summaryGeoMetrics
    if (metric) {
      geoDataArray = Object.values(state.summaryTopGeo[metric])
      regions = getters.top5RegionsByMetric(metric)
      summaryGeoMetrics = state.summaryTopGeoMetrics
    } else {
     geoDataArray = Object.values(state.summaryGeo)
     regions = rootGetters.filtersRegions(true)
     summaryGeoMetrics = state.summaryGeoMetrics
    }

    let result = []

    if (
      geoDataArray.length &&
      geoDataArray.some(item => item.hasData) &&
      regions.length
    ) {
      result = regions.map(region => {
        let newItem = {
          id: region.id,
          name: region.name
        }

        let regionSummaryData

        if (metric) {
          regionSummaryData = state.summaryTopGeo[metric][region.id]
        } else {
          regionSummaryData = state.summaryGeo[region.id]
        }
        if (regionSummaryData && regionSummaryData.hasData) {
          summaryGeoMetrics.forEach(({id}) => {
            newItem[`${id}_one`] = regionSummaryData.data[`${id}_one`]
            newItem[`${id}_two`] = regionSummaryData.data[`${id}_two`]
          })
        }

        return newItem
      })

      if (state.summaryDefault.hasData) {
        let summaryItem = {
          id: 'sum',
          name: 'Всего по проекту'
        }

        summaryGeoMetrics.forEach(({id}) => {
          summaryItem[`${id}_one`] = state.summaryDefault.data[`${id}_one`]
          summaryItem[`${id}_two`] = state.summaryDefault.data[`${id}_two`]
        })

        result.push(summaryItem)
      }
    }

    return result
  },

  metricDataGeo: (state, getters, rootState, rootGetters) => ({
                                                                name,
                                                                two = false,
                                                                diff = false,
                                                                summary = false,
                                                                count = 5,
                                                                positive = false,
                                                                negative = false,
                                                                asc = false,
                                                                metric = null
                                                              }) => {
    let result = getters.metricData({
      name,
      summary,
      diff,
      count,
      positive,
      two,
      negative,
      asc
    })

    if (result.length) {
      let geoDataArray
      let regions
      let geoItems

      if (metric) {
        geoDataArray = Object.values(state.itemsTopGeo[metric])
        regions = getters.top5RegionsByMetric(metric)
        geoItems = state.itemsTopGeo[metric]
      } else {
        geoDataArray = Object.values(state.itemsGeo)
        regions = rootGetters.filtersRegions(true)
        geoItems = state.itemsGeo
      }

      if (
        geoDataArray.length &&
        geoDataArray.some(item => item.hasData) &&
        regions.length
      ) {
        result = result.map(item => {
          let newItem = {
            ...item,
            regions: {}
          }

          regions.forEach(region => {
            const regionData = geoItems[region.id]

            if (regionData && regionData.hasData) {
              const currentSegmentItem = regionData.items.find(regionDataItem => regionDataItem.catalog_id === item.id)

              if (currentSegmentItem) {
                newItem.regions[region.id] = {}
                newItem.regions[region.id][`${name}_one`] = currentSegmentItem[`${name}_one`]

                if (two) {
                  newItem.regions[region.id][`${name}_two`] = currentSegmentItem[`${name}_two`]
                }

                if (diff) {
                  newItem.regions[region.id][`${name}_diff`] = currentSegmentItem[`${name}_diff`]
                }
              }
            }
          })

          return newItem
        })

        if (summary) {
          let summaryGeo = {}
          let savedSummaryGeo

          if (metric) {
            savedSummaryGeo = getters.summaryGeo(metric)
          } else {
            savedSummaryGeo = getters.summaryGeo()
          }

          regions.forEach(region => {
            if (savedSummaryGeo.length) {
              const regionSummaryData = savedSummaryGeo.find(summaryGeoItem => summaryGeoItem.id === region.id)

              if (regionSummaryData) {
                summaryGeo[region.id] = {}
                summaryGeo[region.id][`${name}_one`] = regionSummaryData[`${name}_one`]

                if (two) {
                  summaryGeo[region.id][`${name}_two`] = regionSummaryData[`${name}_two`]
                }

                if (diff) {
                  summaryGeo[region.id][`${name}_diff`] = regionSummaryData[`${name}_diff`]
                }
              }
            }
          })

          result[result.length - 1].regions = summaryGeo
        }
      }
    }

    return result
  },

  summaryChannels: (state, getters, rootState) => {
    const channelDataArray = Object.values(state.summaryChannels)
    const channels = rootState.filtersTraffic.medium.items
    let result = []

    if (
      channelDataArray.length &&
      channelDataArray.some(item => item.hasData) &&
      channels.length
    ) {
      result = channels.map(channel => {
        let newItem = {
          id: channel,
          name: channel
        }

        const channelSummaryData = state.summaryChannels[channel]

        if (channelSummaryData && channelSummaryData.hasData) {
          state.summaryGeoMetrics.forEach(({id}) => {
            newItem[`${id}_one`] = channelSummaryData.data[`${id}_one`]
            newItem[`${id}_two`] = channelSummaryData.data[`${id}_two`]
          })
        }

        return newItem
      })

      if (state.summaryDefault.hasData) {
        let summaryItem = {
          id: 'sum',
          name: 'Всего по проекту'
        }

        state.summaryGeoMetrics.forEach(({id}) => {
          summaryItem[`${id}_one`] = state.summaryDefault.data[`${id}_one`]
          summaryItem[`${id}_two`] = state.summaryDefault.data[`${id}_two`]
        })

        result.push(summaryItem)
      }
    }

    return result
  },

  metricDataChannels: (state, getters, rootState) => ({
                                                        name, two = false,
                                                        diff = false,
                                                        summary = false,
                                                        count = 5,
                                                        positive = false,
                                                        negative = false,
                                                        asc = false,
                                                        period
                                                      }) => {
    let result = getters.metricData({
      name,
      summary,
      diff,
      count,
      positive,
      two,
      negative,
      asc
    })

    if (result.length) {
      const channelsDataArray = Object.values(state.itemsChannels)
      const channels = rootState.filtersTraffic.medium.items

      if (
        channelsDataArray.length &&
        channelsDataArray.some(item => item.hasData) &&
        channels.length
      ) {
        result = result.map(item => {
          let newItem = {
            ...item,
            channels: {}
          }

          channels.forEach(channel => {
            const channelData = state.itemsChannels[channel]

            if (channelData && channelData.hasData) {
              const currentSegmentItem = channelData.items.find(channelDataItem => channelDataItem.catalog_id === item.id)

              if (currentSegmentItem) {
                newItem.channels[channel] = {}
                newItem.channels[channel][`${name}_one`] = currentSegmentItem[`${name}_one`]

                if (two) {
                  newItem.channels[channel][`${name}_two`] = currentSegmentItem[`${name}_two`]
                }

                if (diff) {
                  newItem.channels[channel][`${name}_diff`] = currentSegmentItem[`${name}_diff`]
                }
              }
            }
          })

          return newItem
        })

        if (summary) {
          let summaryChannels = {}

          channels.forEach(channel => {
            if (getters.summaryChannels.length) {
              const channelSummaryData = getters.summaryChannels.find(summaryChannelItem => summaryChannelItem.id === channel)

              if (channelSummaryData) {
                summaryChannels[channel] = {}
                summaryChannels[channel][`${name}_one`] = channelSummaryData[`${name}_one`]

                if (two) {
                  summaryChannels[channel][`${name}_two`] = channelSummaryData[`${name}_two`]
                }

                if (diff) {
                  summaryChannels[channel][`${name}_diff`] = channelSummaryData[`${name}_diff`]
                }
              }
            }
          })

          result[result.length - 1].channels = summaryChannels
        }
      }
    }

    return result
  },

  metricDataChannelsPeriod: (state, getters, rootState) => ({
                                                              name, two = false,
                                                              diff = false,
                                                              summary = false,
                                                              count = 5,
                                                              positive = false,
                                                              negative = false,
                                                              asc = false,
                                                              period,
                                                              ecom = false
                                                            }) => {
    let result = getters.metricData({
      name,
      diff,
      count,
      positive,
      two,
      negative,
      asc,
      period,
      ecom
    })

    if (result.length) {
      const itemsChannelsPeriodData = ecom
        ? state.itemsEcomChannelsPeriod[period]
        : state.itemsChannelsPeriod[period]
      const channels = rootState.filtersTraffic.medium.items

      if (itemsChannelsPeriodData) {
        if (channels.length) {
          result = result.map(item => {
            let newItem = {
              ...item,
              channels: {}
            }

            channels.forEach(channel => {
              const channelData = itemsChannelsPeriodData[channel]

              if (channelData && channelData.items && channelData.items.length) {
                const segmentItem = channelData.items.find(channelSegment => channelSegment.catalog_id === item.id)

                if (segmentItem) {
                  newItem.channels[channel] = {
                    [`${name}_one`]: segmentItem[`${name}_one`],
                    [`${name}_two`]: segmentItem[`${name}_two`],
                    [`${name}_diff`]: segmentItem[`${name}_diff`]
                  }
                }
              }
            })

            return newItem
          })
        }
      }

      SUMMARY_TYPES
        .filter(type => {
          if (ecom) {
            return type.name !== 'ga'
          }

          return true
        })
        .forEach(type => {
          const summary = getters.summaryPeriod(period, type.name, ecom)

          if (summary) {
            let summaryItem = {
              id: type.id,
              name: type.title,
              [`${name}_one`]: summary[`${name}_one`],
              [`${name}_two`]: summary[`${name}_two`],
              [`${name}_diff`]: summary[`${name}_diff`],
              channels: {}
            }

            const summaryChannels = getters.summaryChannelsPeriod(period, type.name, ecom)

            if (summaryChannels) {
              channels.forEach(channel => {
                const summaryData = summaryChannels[channel]

                if (summaryData && summaryData.hasData) {
                  summaryItem.channels[channel] = {
                    [`${name}_one`]: summaryData.data[`${name}_one`],
                    [`${name}_two`]: summaryData.data[`${name}_two`],
                    [`${name}_diff`]: summaryData.data[`${name}_diff`]
                  }
                }
              })
            }

            result.unshift(summaryItem)
          }
        })
    }

    return result
  },

  summaryEntitiesPeriod: (state, getters, rootState, rootGetters) => (period, device = false) => {
    const entitiesSummaryPeriod = device
      ? state.devicesSummaryPeriod[period]
      : state.entitiesSummaryPeriod[period]
    let result = []

    if (entitiesSummaryPeriod.hasData) {
      const entities = device
        ? rootGetters.filtersDevices
        : state.catalogEntities

      result = entities.map(({id, name}) => {
        let newItem = {
          id,
          name
        }

        const entityItemSummary = entitiesSummaryPeriod.data[id]

        if (entityItemSummary) {
          state.summaryEntityMetrics.forEach(metric => {
            newItem[`${metric.id}_one`] = entityItemSummary[`${metric.id}_one`]
            newItem[`${metric.id}_two`] = entityItemSummary[`${metric.id}_two`]
            newItem[`${metric.id}_diff`] = entityItemSummary[`${metric.id}_diff`]
          })
        }

        return newItem
      })

      SUMMARY_TYPES.forEach(type => {
        const summary = getters.summaryPeriod(period, type.name)

        if (summary) {
          let summaryItem = {
            id: type.id,
            name: type.title
          }
          state.summaryEntityMetrics.forEach(metric => {
            summaryItem[`${metric.id}_one`] = summary[`${metric.id}_one`]
            summaryItem[`${metric.id}_two`] = summary[`${metric.id}_two`]
            summaryItem[`${metric.id}_diff`] = summary[`${metric.id}_diff`]
          })

          result.push(summaryItem)
        }
      })
    }

    return result
  },

  entitiesChannelsPeriod: (state, getters, rootState) => (period, metric, device) => {
    let result = getters.summaryEntitiesPeriod(period, device)
    const channels = rootState.filtersTraffic.medium.items

    if (result.length) {
      const entitiesChannelsPeriod = device
        ? state.devicesChannelsPeriod[period]
        : state.entitiesChannelsPeriod[period]

      if (entitiesChannelsPeriod) {
        if (channels.length) {
          result = result.map(item => {
            let newItem = {
              ...item,
              channels: {}
            }

            channels.forEach(channel => {
              const entityData = entitiesChannelsPeriod.data[item.id]

              if (entityData) {
                const channelData = entityData[channel]

                if (channelData) {
                  newItem.channels[channel] = {
                    [`${metric}_one`]: channelData[`${metric}_one`],
                    [`${metric}_two`]: channelData[`${metric}_two`],
                    [`${metric}_diff`]: channelData[`${metric}_diff`]
                  }
                }
              }
            })

            return newItem
          })
        }
      }
    }

    SUMMARY_TYPES.forEach(type => {
      const idx = result.findIndex(item => item.id === type.id)

      if (idx !== -1) {
        const summary = getters.summaryPeriod(period, type.name)

        if (summary) {
          let channelsData = {}

          const summaryChannels = getters.summaryChannelsPeriod(period, type.name)

          if (summaryChannels) {
            channels.forEach(channel => {
              const summaryData = summaryChannels[channel]

              if (summaryData && summaryData.hasData) {
                channelsData[channel] = {
                  [`${metric}_one`]: summaryData.data[`${metric}_one`],
                  [`${metric}_two`]: summaryData.data[`${metric}_two`],
                  [`${metric}_diff`]: summaryData.data[`${metric}_diff`]
                }
              }
            })
          }

          result[idx].channels = channelsData
        }
      }
    })

    return result
  },

  summaryDevices: (state, getters, rootState, rootGetters) => {
    const devicesDataArray = Object.values(state.summaryDevices)
    const devices = rootGetters.filtersDevices
    let result = []

    if (
      devicesDataArray.length &&
      devicesDataArray.some(item => item.hasData) &&
      devices.length
    ) {
      result = devices.map(device => {
        let newItem = {
          id: device.id,
          name: device.name
        }

        const deviceSummaryData = state.summaryDevices[device.id]

        if (deviceSummaryData && deviceSummaryData.hasData) {
          state.summaryGeoMetrics.forEach(({id}) => {
            newItem[`${id}_one`] = deviceSummaryData.data[`${id}_one`]
            newItem[`${id}_two`] = deviceSummaryData.data[`${id}_two`]
          })
        }

        return newItem
      })

      if (state.summaryDefault.hasData) {
        let summaryItem = {
          id: 'sum',
          name: 'Всего по проекту'
        }

        state.summaryGeoMetrics.forEach(({id}) => {
          summaryItem[`${id}_one`] = state.summaryDefault.data[`${id}_one`]
          summaryItem[`${id}_two`] = state.summaryDefault.data[`${id}_two`]
        })

        result.push(summaryItem)
      }
    }

    return result
  },

  metricDataDevices: (state, getters, rootState, rootGetters) => ({
                                                                    name,
                                                                    two = false,
                                                                    diff = false,
                                                                    summary = false,
                                                                    count = 5,
                                                                    positive = false,
                                                                    negative = false,
                                                                    asc = false
                                                                  }) => {
    let result = getters.metricData({
      name,
      summary,
      diff,
      count,
      positive,
      two,
      negative,
      asc
    })

    if (result.length) {
      const devicesDataArray = Object.values(state.itemsDevices)
      const devices = rootGetters.filtersDevices

      if (
        devicesDataArray.length &&
        devicesDataArray.some(item => item.hasData) &&
        devices.length
      ) {
        result = result.map(item => {
          let newItem = {
            ...item,
            devices: {}
          }

          devices.forEach(device => {
            const deviceData = state.itemsDevices[device.id]

            if (deviceData && deviceData.hasData) {
              const currentSegmentItem = deviceData.items.find(deviceDataItem => deviceDataItem.catalog_id === item.id)

              if (currentSegmentItem) {
                newItem.devices[device.id] = {}
                newItem.devices[device.id][`${name}_one`] = currentSegmentItem[`${name}_one`]

                if (two) {
                  newItem.devices[device.id][`${name}_two`] = currentSegmentItem[`${name}_two`]
                }

                if (diff) {
                  newItem.devices[device.id][`${name}_diff`] = currentSegmentItem[`${name}_diff`]
                }
              }
            }
          })

          return newItem
        })

        if (summary) {
          let summaryDevices = {}

          devices.forEach(device => {
            if (getters.summaryDevices.length) {
              const deviceSummaryData = getters.summaryDevices.find(summaryDeviceItem => summaryDeviceItem.id === device.id)

              if (deviceSummaryData) {
                summaryDevices[device.id] = {}
                summaryDevices[device.id][`${name}_one`] = deviceSummaryData[`${name}_one`]

                if (two) {
                  summaryDevices[device.id][`${name}_two`] = deviceSummaryData[`${name}_two`]
                }

                if (diff) {
                  summaryDevices[device.id][`${name}_diff`] = deviceSummaryData[`${name}_diff`]
                }
              }
            }
          })

          result[result.length - 1].devices = summaryDevices
        }
      }
    }

    return result
  },

  top5RegionsByMetric: (state, getters, rootState) => (metric) => {
    const topRegionsByMetric = state.topRegions[metric]
    if (topRegionsByMetric.hasData === true) {
      const isWestProject = rootState.active_project_data.properties && rootState.active_project_data.properties.is_west_project || false
      return topRegionsByMetric.items.slice(0, 5).map((region) => {
        return  {
            id: region.google_region_id,
            name: !isWestProject && region.ru_name ? region.ru_name : region.en_name
          }
        })
    } else {
      return []
    }
  },

  widgetConfig: (state) => {
    return {
      projectId: state.widgetConfig.projectId,
      data: state.widgetConfig.data.filter((widget) => widget.id !== 'visibility' && widget.id !== 'geoVisibility')
    }
  }
}

export default getters
