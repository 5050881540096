import Vue from 'vue'

const mutations = {
    saveData (state, payload) {
        if (payload.hasData) {
            Vue.set(state.data, `channels${payload.index}`, {
                hasData: true,
                items: payload.items,
                total: payload.total
            })
        } else {
            Vue.set(state.data, `channels${payload.index}`, {
                hasData: false,
                items: [],
                total: 0
            })
        }
    },

    saveCustomData (state, payload) {
      if (payload.hasData) {
        Vue.set(state.customData, `channels${payload.index}`, {
            hasData: true,
            items: payload.items,
            total: payload.total
        })
    } else {
        Vue.set(state.customData, `channels${payload.index}`, {
            hasData: false,
            items: [],
            total: 0
        })
    }
    },

    saveSummary (state, payload) {
      if (payload.hasData) {
          Vue.set(state.summary, `channels${payload.index}`, {
              hasData: true,
              data: payload.data
          })
      } else {
          Vue.set(state.summary, `channels${payload.index}`, {
              hasData: false,
              data: {}
          })
      }
    },

    clearData (state) {
        state.data = {
            channels1: {
                hasData: false,
                items: [],
                total: 0
            },
            channels2: {
                hasData: false,
                items: [],
                total: 0
            }
        }

        state.customData = {
          channels1: {
              hasData: false,
              items: [],
              total: 0
          },
          channels2: {
              hasData: false,
              items: [],
              total: 0
          }
      }
    },

    clearSummary (state) {
        state.summary = {
            channels1: {
                hasData: false,
                data: {}
            },
            channels2: {
                hasData: false,
                data: {}
            }
        }
    }
}

export default mutations
