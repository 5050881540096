import constants from '@/const/'
import errors from '@/store/errors'

const actions = {
  getDataPeriodStatic({commit, rootState}, input) {
    const url = input.name === 'itemsEcomDefault' || input.name === 'summaryEcomDefault'
      ? '/ecomm'
      : '/catalog'

    return new Promise((resolve, reject) => {
      rootState.request({
        type: constants.METHODS.GET,
        url,
        auth: true,
        apiName: 'main',
        query: input.query,
        cancelable: true
      }).then(result => {
        if (result.has_data) {
          commit('saveDataPeriodStatic', {
            name: input.name,
            hasData: true,
            data: result.data
          })
        }
        resolve(true)
      }, () => {
        reject(new Error(errors.GET_DATA))
      })
    })
  },

  getDataPeriodDynamic({commit, rootState}, input) {
    const url = input.name === 'itemsEcomPeriod' || input.name === 'summaryEcomPeriod'
      ? '/ecomm'
      : '/catalog'

    return new Promise((resolve, reject) => {
      rootState.request({
        type: constants.METHODS.GET,
        url,
        auth: true,
        apiName: 'main',
        query: input.query,
        cancelable: true
      }).then(result => {
        if (result.has_data) {
          commit('saveDataPeriodDynamic', {
            name: input.name,
            hasData: true,
            data: result.data,
            period: input.periodSelected,
            type: input.type
          })
        }
        resolve(true)
      }, () => {
        reject(new Error(errors.GET_DATA))
      })
    })
  },

  getDataGeo({commit, rootState}, input) {
    return new Promise((resolve, reject) => {
      rootState.request({
        type: constants.METHODS.GET,
        url: '/catalog',
        auth: true,
        apiName: 'main',
        query: input.query,
        cancelable: true
      }).then(result => {
        if (result.has_data) {
          commit('saveDataGeo', {
            name: input.name,
            hasData: true,
            data: result.data,
            regionId: input.query.region_id
          })
        }
        resolve(true)
      }, () => {
        reject(new Error(errors.GET_DATA))
      })
    })
  },

  getDataTopGeo({commit, rootState}, input) {
    return new Promise((resolve, reject) => {
      rootState.request({
        type: constants.METHODS.GET,
        url: '/catalog',
        auth: true,
        apiName: 'main',
        query: input.query,
        cancelable: true
      }).then(result => {
        if (result.has_data) {
          commit('saveDataTopGeo', {
            name: input.name,
            hasData: true,
            data: result.data,
            regionId: input.query.google_region_id,
            metric: input.metric
          })
        }
        resolve(true)
      }, () => {
        reject(new Error(errors.GET_DATA))
      })
    })
  },

  getDataChannels({commit, rootState}, input) {
    return new Promise((resolve, reject) => {
      rootState.request({
        type: constants.METHODS.GET,
        url: '/catalog',
        auth: true,
        apiName: 'main',
        query: input.query,
        cancelable: true
      }).then(result => {
        if (result.has_data) {
          commit('saveDataChannels', {
            name: input.name,
            hasData: true,
            data: result.data,
            medium: input.query.medium
          })
        }
        resolve(true)
      }, () => {
        reject(new Error(errors.GET_DATA))
      })
    })
  },

  getDataChannelsPeriodDynamic({commit, rootState}, input) {
    const url = input.name === 'itemsEcomChannelsPeriod' || input.name === 'summaryEcomChannelsPeriod'
      ? '/ecomm'
      : '/catalog'

    return new Promise((resolve, reject) => {
      rootState.request({
        type: constants.METHODS.GET,
        url,
        auth: true,
        apiName: 'main',
        query: input.query,
        cancelable: true
      }).then(result => {
        if (result.has_data) {
          commit('saveDataChannelsPeriod', {
            name: input.name,
            hasData: true,
            data: result.data,
            period: input.periodSelected,
            type: input.type,
            channel: input.query.medium
          })
        }
        resolve(true)
      }, () => {
        reject(new Error(errors.GET_DATA))
      })
    })
  },

  getDataEntity({commit, rootState}, input) {
    const device = input.name === 'devicesSummaryPeriod'

    return new Promise((resolve, reject) => {
      rootState.request({
        type: constants.METHODS.GET,
        url: device ? '/catalog' : '/items',
        auth: true,
        apiName: 'main',
        query: input.query,
        cancelable: true
      }).then(result => {
        if (result.has_data) {
          commit('saveDataEntity', {
            hasData: true,
            data: result.data,
            type: device ? input.query.is_mobile : input.query.types[0],
            period: input.periodSelected,
            name: input.name
          })
        }
        resolve(true)
      }, () => {
        reject(new Error(errors.GET_DATA))
      })
    })
  },

  getDataEntityChannelsPeriodDynamic({commit, rootState}, input) {
    const device = input.name === 'devicesChannelsPeriod'

    return new Promise((resolve, reject) => {
      rootState.request({
        type: constants.METHODS.GET,
        url: device ? '/catalog' : '/items',
        auth: true,
        apiName: 'main',
        query: input.query,
        cancelable: true
      }).then(result => {
        if (result.has_data) {
          commit('saveDataEntityChannel', {
            hasData: true,
            data: result.data,
            type: device ? input.query.is_mobile : input.query.types[0],
            period: input.periodSelected,
            channel: input.query.medium,
            name: input.name
          })
        }
        resolve(true)
      }, () => {
        reject(new Error(errors.GET_DATA))
      })
    })
  },

  getDataDevices({commit, rootState}, input) {
    return new Promise((resolve, reject) => {
      rootState.request({
        type: constants.METHODS.GET,
        url: '/catalog',
        auth: true,
        apiName: 'main',
        query: input.query,
        cancelable: true
      }).then(result => {
        if (result.has_data) {
          commit('saveDataDevices', {
            name: input.name,
            hasData: true,
            data: result.data,
            type: input.query.is_mobile
          })
        }
        resolve(true)
      }, () => {
        reject(new Error(errors.GET_DATA))
      })
    })
  },

  getWidgetConfig({commit, rootState}, input) {
    commit('saveWidgetConfig', {hasData: false})

    return new Promise((resolve, reject) => {
      rootState.request({
        type: constants.METHODS.GET,
        apiName: 'main',
        url: '/user/widget',
        auth: true,
        query: input.query
      })
        .then(result => {
          if (result.has_data) {
            commit('saveWidgetConfig', {
              hasData: true,
              projectId: input.query.project_id,
              data: result.data
            })
          }

          resolve(true)
        }, () => {
          reject(errors.GET_DATA)
        })
    })
  },

  updateWidgetConfig({rootState}, input) {
    return new Promise((resolve, reject) => {
      rootState.request({
        type: constants.METHODS.POST,
        apiName: 'main',
        url: '/user/widget',
        auth: true,
        query: input.query,
        body: input.body
      })
        .then(result => {
          resolve(result)
        }, () => {
          reject(errors.UPDATE)
        })
    })
  },

  getTopRegionsByMetric({commit, rootState}, input) {
    return new Promise((resolve, reject) => {
      rootState.request({
        type: constants.METHODS.GET,
        url: '/top_traffic_regions',
        auth: true,
        apiName: 'main',
        query: input.query,
      }).then(result => {
        if (result.has_data) {
          commit('saveTopRegions', {
            hasData: true,
            data: result.data,
            metric: input.metric,
          })
        }
        resolve(true)
      }, () => {
        reject(new Error(errors.GET_DATA))
      })
    })
  }
}

export default actions
