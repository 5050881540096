export default function () {
  return {
    segment: {
      segment_id: undefined,
      has_data: false,
      data: {}
    },

    data: {
      has_data: false,
      limit: 100,
      offset: 0,
      next: true,
      items: []
    }
  }
}
