var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
      'aside',
      {
          '_narrow': _vm.menuNarrow,
          '_new': _vm.menuNarrow
      }
  ]},[(_vm.asideData.has_data && _vm.asideData.base_menu && _vm.asideData.base_menu.length && !_vm.preloader)?_c('ul',{class:[
      'aside-menu',
      {
        '_new': _vm.menuNarrow
      }
    ]},_vm._l((_vm.asideData.base_menu),function(item,i){return _c('item',{key:`aside-${i}`,attrs:{"index":i,"data":item,"flagViewTags":_vm.flagViewTags,"isActive":_vm.isActive,"menuNarrow":!!_vm.menuNarrow,"parent-index":i},on:{"updateList":function($event){return _vm.updateActiveRoute($event)}}})}),1):_vm._e(),_c('div',{staticClass:"aside__footer"},[_c('div',{class:['aside__toogle', {_center: _vm.menuNarrow}],on:{"click":function($event){return _vm.menuSlideToggle()}}},[_c('icon',{attrs:{"name":"keyboard_arrow_left"}}),_c('span',[_vm._v("Свернуть")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }