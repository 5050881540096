import Vue from 'vue'


import preloader from '../components/preloader/'
Vue.component('v-preloader', preloader)

import dashboard from '../components/dashboard/'
Vue.component('v-dashboard', dashboard)

import aside from '../components/aside/'
Vue.component('v-aside', aside)

import header from '../components/header/'
Vue.component('v-header', header)

import footer from '../components/footer'
Vue.component('v-footer', footer)

/* Заголовок страницы */
import pageTitle from '../components/pageTitle/'
Vue.component('v-page-title', pageTitle)

/* Блок пагинации */
import pagination from '../components/pagination/'
Vue.component('v-pagination', pagination)

/* Блок фильтров */
import pageFilters from '../components/pageFilters/'
Vue.use(pageFilters)

/* Блок выбора проекта */
import projectsSelect from '@/components/projectsSelect'
Vue.component('v-projects-select', projectsSelect)

import pageTags from '@/components/pageTags'
Vue.component('v-page-tags', pageTags)

/* Модальные окна */
import modals from '@/components/modals'
Vue.use(modals)

import {codemirror} from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
Vue.component('v-codemirror', codemirror)

import dialog from '@/components/dialog'
Vue.component('v-dialog', dialog)

import cookie from '@/components/cookie'
Vue.component('v-cookie', cookie)
