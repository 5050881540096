import Vue from 'vue'

import { treeCount, transformationInTree, transformationInTreeV2, generateBreadCrumbs, findKey } from '@/function'

export default {
  /* Сохранение данных каталога SW */
  setCatalogSW (state, payload) {
    if (payload && payload.has_data && payload.data.items && Array.isArray(payload.data.items)) {
      let categories = payload.data.items
      let tree = transformationInTree(categories)

      try {
        tree = treeCount(tree)
      } catch (_) {}

      let breadcrumbs = categories.map(category => {
        return {
          id: category.id,
          name: category.name,
          breadcrumbs: generateBreadCrumbs(category.id, categories)
        }
      })

      /* Сохранение данных */
      Vue.set(state, 'dataSW', {
        has_data: payload.has_data,
        project_id: payload.project_id,
        items: categories,
        tree: tree,
        breadcrumbs: breadcrumbs
      })
    } else {
      /* Очистка списка */
      Vue.set(state, 'dataSW', {
        has_data: false,
        items: []
      })
    }
  },

  /* Сохранение данных каталога */
  setAllSegments (state, payload) {
    if (payload && payload.segments && payload.segments.length) {
      let items = payload.segments.map(segment => {
        segment.segment_type_id = JSON.stringify({
          id: segment.id,
          type: segment.type
        })
        return segment
      })

      /* Сохранение данных */
      Vue.set(state, 'segments', {
        has_data: true,
        project_id: payload.project_id,
        items: items
      })
    } else {
      /* Очистка списка */
      Vue.set(state, 'segments', {
        has_data: false,
        items: []
      })
    }
  },

  /* Сохранение данных древовидного каталога */
  setTreeCatalog (state, payload) {
    if (payload && payload.has_data && payload.data.items && Array.isArray(payload.data.items)) {
      let segments = payload.data.items
      let tree = transformationInTreeV2(segments)

      const setLevel = (items, level) => {
        return items.forEach(item => {
          const idx = findKey(item.id, segments)
          segments[idx].level = level

          if (item.items && item.items.length) {
            setLevel(item.items, ++level)
            --level
          }
        })
      }

      setLevel(tree, 1)

      try {
        tree = treeCount(tree)
      } catch (_) {}

      const name = payload.isCustom ? 'dataDFCustom' : 'dataDF'

      /* Сохранение данных */
      Vue.set(state, name, {
        has_data: payload.has_data,
        project_id: payload.project_id,
        items: segments,
        tree: tree
      })
    } else {
      /* Очистка списка */
      Vue.set(state, name, {
        has_data: false,
        items: []
      })
    }
  },

  /* Сохранение данных каталога */
  setCatalog (state, payload) {
    if (payload && payload.has_data && payload.data.items && Array.isArray(payload.data.items)) {
      let categories = payload.data.items
      let tree = transformationInTree(categories, true)

      try {
        tree = treeCount(tree)
      } catch (_) {}

      let breadcrumbs = categories.map(category => {
        return {
          id: category.id,
          name: category.name,
          breadcrumbs: generateBreadCrumbs(category.id, categories)
        }
      })

      /* Сохранение данных */
      Vue.set(state, 'data', {
        has_data: payload.has_data,
        project_id: payload.project_id,
        items: categories,
        tree: tree,
        breadcrumbs: breadcrumbs
      })
    } else {
      /* Очистка списка */
      Vue.set(state, 'data', {
        has_data: false,
        items: []
      })
    }
  },

  /* Сохранение последних заданий */
  setTaskList (state, input) {
    try {
      Vue.set(state, 'taskList', {
        has_data: input.has_data,
        items: input.data.items
      })
    } catch (_) {
      Vue.set(state, 'taskList', {
        has_data: input.has_data,
        items: []
      })
    }
  },
}
