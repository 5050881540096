export default {
	frequency: {
		has_data: false
	},

	frequencyYandex: {
		has_data: false
	},

	frequencyGoogle: {
		has_data: false
	},

	traffic: {
		yandex: {
			has_data: false
		},
		google: {
			has_data: false
		}
	},

	/* Данные по проекту */
	project_data: {},
}
