const state = {
    /* Каталог */
    catalog: {
        has_data: false,
        items: [],
        total: 0
    },

    /* Прелоудер в таблице */
    preloaderTable: false,

    /* Типы сущностей в каталоге */
    catalogItemTypes: {
        CATEGORY: 1,
        CARD: 2,
        TAG_LISTING: 3
    },

    /* Данные итого по проекту DF */
    summary: {
        has_data: false,
        data: {}
    },

    /* Данные итого по всему сайту (GA) */
    summaryGA: {
        has_data: false,
        data: {}
    }
}

export default state
