import Vue from 'vue'
import VueCookie from 'vue-cookie'

/**
 * Компонент меню
 */
import item from './item.vue'

import constants from '@/const/'
import {mapState} from 'vuex'

Vue.use(VueCookie)
const Cookies = Vue.cookie

export default {
    data () {
        return {
            /* Отображение узкого меню */
            menuNarrow: true,

            /* Задержка при отображении тегов */
            timeOutViewTags: null,

            /* Время задержки перехода меню (ms) */
            timeOutTime: 400,

            /* Флаг отображение тегов */
            flagViewTags: false,

            /* Активные списки */
            isActive: {},

            preloader: true
        }
    },

    components: {
        'item': item
    },

    computed: {
        ...mapState({
            config: state => state.config,
            projectId: state => state.active_project,
            profile: state => state.profile
        }),

        /* Дерево роутов */
        mapRouter () {
            return this.$route && this.$route.matched.map((item) => item.name).reverse()
        },

        /* Список разделов меню */
        asideData () {
            return this.$store.getters.asideData({
                mapRouter: this.mapRouter,
                isNew: true
            })
        }
    },

    async created () {
        this.preloader = true

        if (this.asideData.has_data && this.asideData.base_menu) {
            try {
                for (let i = 0; i < this.asideData.base_menu.length; i++) {
                    this.$set(this.isActive, i, {})
                }
            } catch (_) {
            }
        }

        this.preloader = false
    },

    mounted () {
        this.getFlagMenu()

        document.addEventListener('keydown', this.keydownEscape)
    },

    destroyed () {
        document.removeEventListener('keydown', this.keydownEscape)
    },

    methods: {
        /* Открытие модального окна Intercom */
        interComstartTour (modalId) {
            Intercom('startTour', modalId)
        },

        /* Получение флага отображения меню */
        getFlagMenu () {
            let refreshData = Cookies.get(constants.LOCAL_STORAGE_DATA.LOCKER_MENU)
            if (refreshData === '1' || refreshData === 'true') {
                this.menuNarrow = false
            }
        },

        /* Переключение режима меню */
        menuSlideToggle() {
            this.menuNarrow ^= true
            Cookies.set(constants.LOCAL_STORAGE_DATA.LOCKER_MENU, !this.menuNarrow)
        },

        /* Установка активного пункта меню */
        updateActiveRoute (data) {
            try {
                this.$set(this.isActive[data.parentIndex], data.level, this.isActive[data.parentIndex][data.level] !== data.index || data.fast ? data.index : null)

                for (let item in this.isActive) {
                    if (+item !== data.parentIndex) {
                        this.$set(this.isActive[item], data.level, null)
                    }
                }
            } catch (_) {
            }
        },

        /* Метод при нажатии на Escape */
        keydownEscape (event) {
            if (event.code === 'Escape') {
                if (this.menuHelp) {
                    this.toggleHelpMenu()
                }
                if (this.menuSettings) {
                    this.toggleSettingsMenu()
                }
            }
        },

        /* Отображение меню помощь */
        toggleHelpMenu (flag) {
            if (flag) {
                this.menuHelp ^= true
            } else {
                this.menuHelp = Boolean(flag)
            }
        },

        /* Отображение меню настроек */
        toggleSettingsMenu (flag) {
            if (flag) {
                this.menuSettings ^= true
            } else {
                this.menuSettings = Boolean(flag)
            }
        }
    }
}
