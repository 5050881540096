import {updateTrafficSourceName} from '@/function'

const getters = {
  getTableCellsConfigCatalog: (state, getters, rootState) => {
    return updateTrafficSourceName(state.catalogTableCellsConfig, rootState.trafficSourceSelectedName)
  },
  getTableCellsConfigListing: (state, getters, rootState) => {
    return updateTrafficSourceName(state.listingTableCellsConfig, rootState.trafficSourceSelectedName)
  },
  getTableCellsConfigCard: (state, getters, rootState) => {
    return updateTrafficSourceName(state.cardTableCellsConfig, rootState.trafficSourceSelectedName)
  },
  getTableCellsConfigContentAnalysis: (state, getters, rootState) => {
    return updateTrafficSourceName(state.contentAnalysisTableCellsConfig, rootState.trafficSourceSelectedName)
  },
  getTableCellsConfigAbcAnalysis: (state, getters, rootState) => {
    return updateTrafficSourceName(state.abcAnalysisTableCellsConfig, rootState.trafficSourceSelectedName)
  }
}

export default getters
