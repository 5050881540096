<template>
  <fragment>
    <div :class="[ classes, 'filters' ]">
      <slot name="default"/>

      <div
        v-if="slide"
        :class="[
	                'filters-toggle',
	                '_right',
	                { '_active': curtainView }
	            ]"
        @click="curtainView ^= true"
      >
        <slot name="button">
          <icon class="filters-toggle__icon" name="baseline-tune"/>
          <span>Фильтры</span>
          <icon class="filters-toggle__arrow" name="arrow_drop_down"/>
        </slot>
      </div>
    </div>

    <slot v-if="curtainView" name="slide"/>
  </fragment>
</template>

<script>
export default {
  props: {
    slide: {
      type: Boolean,
      default: false
    },
    classes: {
      type: [String, Array]
    }
  },

  data () {
    return {
      curtainView: false
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped/>
