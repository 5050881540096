<template>
  <li
      v-if="data.view || typeIntercom"
      :class="classList"
  >
    <!-- Раздел -->
    <template v-if="typeSection">
      <span
          v-if="data.title"
          class="aside-item__section-title"
      >
        {{ data.title }}
      </span>
      <item
          v-for="(item, i) in data.items"
          :key="`aside-${i}`"
          :index="i"
          :data="item"
          :flagViewTags="flagViewTags"
          :isActive="isActive"
          :menuNarrow="menuNarrow"
          :level="level + 1"
          :parent-index="parentIndex"
          @updateList="updateList($event)"
      />
    </template>

    <!-- Список -->
    <template v-if="typeList && !typeSection">
      <popper
          class="popper"
          v-if="menuNarrow"
          trigger="hover"
          :visible-arrow="false"
          :options="{
          placement: 'right-start',
          positionFixed: true,
          modifiers: {
             preventOverflow: {
              boundariesElement: 'viewport'
            }
          }
        }"
      >
        <ul class="aside-item__internal popper__element">
          <li class="popper__wrapper">
            <span class="aside-item__internal-title">{{ data.title }}</span>
            <item
                v-for="(item, i) in data.items"
                :data="item"
                :key="i"
                :index="i"
                :isActive="isActive"
                :flagViewTags="flagViewTags"
                :level="level + 1"
                :menuNarrow="true"
                :parent-index="parentIndex"
                @updateList="updateList($event)"
            />
          </li>
        </ul>

        <component
            slot="reference"
            :class="['aside-item__title', { _actual: isActualExcretion }]"
            v-bind="listTitleComponent"
            @click.native="listToggle"
        >
            <icon
                v-if="data.icon"
                class="aside-item__icon"
                :name="data.icon"
            />
          <icon
              v-else
              :class="['aside-item__arrow', { _rotate: isActiveList }]"
              name="arrow_right"
          />
          <span class="aside-item__name">{{ data.title }}</span>
          <component
              :is="componentTag"
              :data="data"
          />
        </component>
      </popper>

      <template v-else>
        <span
            :class="['aside-item__title', { _actual: isActualExcretion }]"
            v-bind="listTitleComponent"
            @click.native="listToggle"
        >
          <icon
              v-if="data.icon"
              class="aside-item__icon"
              :name="data.icon"
          />
          <icon
              v-else
              :class="['aside-item__arrow', { _rotate: isActiveList }]"
              name="arrow_right"
          />
          <span class="aside-item__name">{{ data.title }}</span>
          <component
              :is="componentTag"
              :data="data"
          />
        </span>

        <transition
            @before-enter="beforeEnter"
            @after-enter="afterEnter"
            @before-leave="beforeLeave"
            @after-leave="afterLeave"
        >
          <ul
              v-show="isActiveList"
              ref="list"
              class="aside-item__internal"
          >
            <span class="aside-item__internal-title">{{ data.title }}</span>
            <item
                v-for="(item, i) in data.items"
                :data="item"
                :key="i"
                :index="i"
                :isActive="isActive"
                :flagViewTags="flagViewTags"
                :level="level + 1"
                :parent-index="parentIndex"
                @updateList="updateList($event)"
            />
          </ul>
        </transition>
      </template>
    </template>

    <!-- Intercom виджет -->
    <template v-if="typeIntercom">
      <button class="aside-item__link" @click="interComstartTour()">
        <icon
            v-if="data.icon"
            class="aside-item__icon"
            :name="data.icon"
        />
        <span class="aside-item__name">{{ data.title }}</span>
        <component
            :is="componentTag"
            :data="data"
        />
      </button>
    </template>

    <template v-if="typeRoute">
      <router-link
          :class="[
          'aside-item__link',
          {
            'router-link-exact-active': isActualExcretion,
            _marker: data.marker,
            _d_root: data.d_root
          }
        ]"
          :to="data.to"
          v-tippy="typpyStyle"
          ref="tippyItem"
      >
        <icon
            v-if="data.icon"
            class="aside-item__icon"
            :name="data.icon"
        />
        <span class="aside-item__name">{{ data.title }}</span>
        <component
            :is="componentTag"
            :data="data"
        />
      </router-link>
    </template>

    <!-- Событие -->
    <template v-if="typeEvent && data.event">
      <button
          class="aside-item__link"
          v-tippy="typpyStyle"
          ref="tippyItem"
          @click="data.event"
      >
        <icon
            v-if="data.icon"
            class="aside-item__icon"
            :name="data.icon"
        />
        <span class="aside-item__name">{{ data.title }}</span>
        <component
            :is="componentTag"
            :data="data"
        />
      </button>
    </template>
  </li>
</template>

<script>
/* Компонент тега */
import Popper from 'vue-popperjs'

const componentTagRender = function () {
  return {
    template: `
      <span
        v-if="data.tagtext"
        class="aside__tagtext"
        :style="textStyle"
        >
            {{ data.tagtext.text }}
        </span>

      <span
        v-else-if="data.tagicon"
        class="aside__icontag"
        v-tippy="typpyStyle"
        :content="iconText"
        ref="icontag"
        >
            <icon :name="data.tagicon.icon" />
        </span>
    `,

    props: {
      data: {
        type: Object
      }
    },

    computed: {
      typpyStyle () {
        return {
          popperOptions: {
            modifiers: {
              preventOverflow: {
                enabled: false
              },
              hide: {
                enabled: false
              }
            }
          }
        }
      },

      iconText () {
        try {
          return this.data.tagicon.text
        } catch (_) {
          return false
        }
      },

      textStyle () {
        if (this.data.tagtext) {
          return `background-color: ${this.data.tagtext.color}`
        }
      }
    },

    watch: {
      iconText () {
        this.updateView()
      }
    },

    mounted () {
      this.updateView()
    },

    methods: {
      updateView () {
        this.$nextTick(() => {
          if (this.$refs.icontag) {
            if (this.iconText) {
              this.$refs.icontag._tippy.enable()
            } else {
              this.$refs.icontag._tippy.disable()
            }
          }
        })
      }
    }
  }
}

export default {
  name: 'item',

  components: {
    popper: Popper
  },

  data() {
    return {
      /* Переключатель анимации */
      animation: false
    }
  },

  props: {
    /* Список ссылок и разделов */
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },

    /* Уровень погружения */
    level: {
      type: Number,
      default: 1
    },

    /* Индекс элемента */
    index: {
      type: Number
    },

    /* Активные разделы */
    isActive: {
      type: Object
    },

    /* Флаг отображения тегов */
    flagViewTags: {
      type: Boolean,
      default: true
    },

    /* Функция возврата */
    updateList: {
      type: Function,
      default: function (val) {
        this.$emit('updateList', val)
        try {
          if (
              val.level > 2 &&
              this.isActive[this.parentIndex][val.level - 1] === undefined
          ) {
            this.$emit('updateList', {
              level: this.level,
              index: this.index,
              fast: true,
              parentIndex: this.parentIndex
            })
          }
        } catch (_) {
        }
      }
    },

    menuNarrow: {
      type: Boolean,
      default: false
    },

    parentIndex: {
      type: Number,
      default: undefined
    }
  },

  computed: {
    /* Классы раздела */
    classList() {
      let classes = ['aside-item']

      switch (true) {
        case this.typeList && !this.typeSection:
          classes.push('_list')
          if (this.isActiveList) {
            classes.push('_active')
          }
          break

        case this.typeRoute:
        case this.typeEvent:
          classes.push('_link')
          break
        case this.typeSection:
          classes.push('_section')
          break
      }

      return classes
    },

    /* Компонент ссылки */
    componentTag() {
      return componentTagRender(this.data)
    },

    /* Тип: раздел */
    typeSection() {
      return this.data.section
    },

    /* Тип: список */
    typeList() {
      return this.data.type === 'list'
    },

    /* Тип: роут */
    typeRoute() {
      return !!(this.data.type === 'route' && this.data.spa_route)
    },

    /* Тип: событие */
    typeEvent() {
      return !!(this.data.type === 'event' && this.data.event)
    },

    /* Тип: Intercom */
    typeIntercom() {
      return this.data.type === 'intercom'
    },

    /* Флаг активности списка */
    isActiveList() {
      try {
        return !!(
            this.typeList &&
            this.isActive[this.parentIndex][this.level] === this.index
        )
      } catch (_) {
        return false
      }
    },

    /* Флаг выделения пункта */
    isActualExcretion() {
      return !!this.data.spa_actual_action
    },

    /* Конфигурация */
    config() {
      return this.$store.state.config
    },

    typpyStyle() {
      if (this.level === 2 && (this.typeRoute || this.typeEvent)) {
        return {
          content: this.data.title,
          placement: 'right',
          boundary: 'viewport'
        }
      } else {
        return {
          onShow: () => {
            return false
          }
        }
      }
    },

    listTitleComponent() {
      if (this.typeList) {
        let props = {is: this.data.to ? 'router-link' : 'span'}

        if (this.data.to) {
          props.to = this.data.to
        }

        return props
      }

      return {}
    }
  },

    mounted() {
      if (this.isActualExcretion && this.typeRoute) {
        this.listToggle(null, true)
      }

      this.viewAboutItemTippy(this.menuNarrow)
    },

    watch: {
      menuNarrow(val) {
        this.viewAboutItemTippy(val)
      }
    },

    methods: {
      viewAboutItemTippy(val) {
        this.$nextTick(() => {
          if (this.level === 2 && this.$refs.tippyItem) {
            try {
              let elTippy = this.$refs.tippyItem.$el._tippy
              if (val) {
                elTippy.enable()
              } else {
                elTippy.disable()
              }
            } catch (_) {
            }
          }
        })
      },

      /* Открытие модального окна Intercom */
      interComstartTour() {
        if (Intercom && this.data.params) {
          Intercom('startTour', this.data.params.id)
        } else {
          this.$notify({type: 'error', title: 'Ошибка запуска виджета'})
        }
      },

      /* Управление раскрытием списка */
      listToggle(event, fast) {
        this.updateList({
          level: this.level,
          index: this.index,
          fast: fast,
          parentIndex: this.parentIndex
        })

        if (!this.animation) {
          this.animation = true
        }
      },

      /* Появление списка */
      beforeEnter(el) {
        if (this.animation) {
          // Setup clone
          let clone = el.cloneNode(true), h
          clone.style.width = el.style.width
          clone.style.visibility = 'hidden'
          clone.style.removeProperty('display')

          // Анимация внутренних списков (если таковые существуют)
          let desc = clone.querySelectorAll('*');
          [].forEach.call(desc, function (item) {
            item.style.removeProperty('none')
          })

          el.parentNode.appendChild(clone)
          h = clone.clientHeight
          clone.remove()

          // Принудительная анимация
          el.style.height = '0px'
          setTimeout(() => (el.style.height = h + 'px'), 1)
        }
      },

      afterEnter(el) {
        if (this.animation) {
          el.style.removeProperty('height')
        }
      },

      /* Скрытие списка */
      beforeLeave(el) {
        el.style.height = el.clientHeight + 'px'
        setTimeout(() => (el.style.height = '0px'), 1)
      },

      afterLeave(el) {
        el.style.removeProperty('height')
      }
    }
  }
</script>

<style lang="scss" src="./aside.scss" scoped></style>
