import {METHODS} from '@/const'
import ERRORS from '@/store/errors'

const actions = {
    getData ({commit, rootState}, input) {
        if (input.query.summary) {
            commit('saveSummary', {
                hasData: false,
                index: input.index
            })
        } else {
            commit('saveData', {
                hasData: false,
                index: input.index
            })
        }

        return new Promise((resolve, reject) => {
            rootState.request({
                type: METHODS.GET,
                url: '/catalog',
                auth: true,
                apiName: 'main',
                query: input.query,
                cancelable: true
            }).then(result => {
                if (result.has_data) {
                    if (input.query.summary) {
                        commit('saveSummary', {
                            hasData: true,
                            data: result && result.data && result.data.summary || {},
                            index: input.index
                        })
                    } else {
                        commit('saveData', {
                            hasData: true,
                            items: result && result.data && result.data.items || [],
                            total: result.data.total,
                            index: input.index
                        })
                    }
                }
                resolve(true)
            }, () => {
                reject(new Error(ERRORS.GET_DATA))
            })
        })
    },

    getUserSegments: function ({commit, rootState}, input) {
      return new Promise((resolve, reject) => {
        rootState.request({
            type: METHODS.GET,
            url: '/user_segments',
            auth: true,
            apiName: 'main',
            query: input.query,
            cancelable: true
        }).then(result => {
            if (result.has_data) {
              commit('saveCustomData', {
                  hasData: true,
                  items: result && result.data && result.data.items || [],
                  total: result.data.total,
                  index: input.index
              })
            }
            resolve(true)
        }, () => {
            reject(new Error(ERRORS.GET_DATA))
        })
    })
  }
}

export default actions
