const state = {
    tableCellsConfig: [
        {
            name: 'Трафик',
            id: 'traffic',
            items: [
                {
                    name: 'Сессии',
                    id: 'count_all',
                    visible: true,
                    needFormat: true,
                    filterName: 'sessions'
                },
                {
                    name: 'Пользователи',
                    id: 'users',
                    visible: true,
                    needFormat: true,
                    filterName: 'users'
                },
                {
                    name: 'Новые пользователи',
                    id: 'new_users',
                    visible: true,
                    needFormat: true,
                    filterName: 'new_users'
                }
            ]
        },
        {
            name: 'Продажи',
            id: 'sales',
            items: [
                {
                    name: 'Транзакции',
                    id: 'transactions',
                    visible: true,
                    needFormat: true,
                    filterName: 'transactions'
                },
                {
                    name: '%CR',
                    id: 'conversions',
                    visible: true,
                    needFormat: false,
                    additional: '%',
                    filterName: 'conversions'
                },
                {
                    name: 'Выручка (GA)',
                    id: 'transaction_revenue',
                    visible: true,
                    needFormat: true,
                    filterName: 'transaction_revenue'
                }
            ]
        }
    ],

    data: {
        channels1: {
            hasData: false,
            items: [],
            total: 0
        },
        channels2: {
            hasData: false,
            items: [],
            total: 0
        }
    },

    customData: {
      channels1: {
        hasData: false,
        items: [],
        total: 0
      },
      channels2: {
          hasData: false,
          items: [],
          total: 0
      }
    },

    summary: {
        channels1: {
            hasData: false,
            data: {}
        },
        channels2: {
            hasData: false,
            data: {}
        }
    }
}

export default state
