import Vue from 'vue';
import constants from '@/const/'

const mutations = {
    /* Запись профиля в хранилище */
    getProfile(state, data) {
        Vue.set(state, 'profile', data)
    },

    /* Пользователь принял куки */
    acceptCookie(state, data) {
      Vue.set(state, 'isCookieAccepted', data)
    },

    /* Запись списка разделов меню */
    setAsideList(state, data) {
        Vue.set(state, 'aside', data);
    },

    /* Сохранение списка регионов */
    saveRegions(state, input) {
        let regions = [];
        for (let item of input.data.items) {
            if (item.region_id && item.name) {
                regions.push({
                    id: item.region_id,
                    name: item.name
                })
            }
        }

        Vue.set(state.options.regions, 'data', regions);
    },

    saveClients (state, data) {
      let clients = []
      if (data && data.data && data.data.items && data.data.items.active && data.data.items.active.items && data.data.items.active.items.length) {
        clients = data.data.items.active.items
        Vue.set(state.options, 'clients', clients)
      }

      clients = []

      if (data && data.data && data.data.items && data.data.items.inactive && data.data.items.inactive.items && data.data.items.inactive.items.length) {
        clients = data.data.items.inactive.items
        Vue.set(state.options, 'clientsInactive', clients)
      }
    },

    /* Запись проектов в хранилище */
    pushProjects (state, payload) {
        let projects = { ...state.options.projects }

        if (payload.has_data) {
            Vue.set(state.options.projects, 'offset', projects.offset + projects.limit)
            Vue.set(state.options.projects, 'next', payload.next && !!payload.items.length)
            Vue.set(state.options.projects, 'list', projects.list.concat(payload.items))
        }
    },

    /* Запись проектов при поиске */
    pushProjectsSearch (state, payload) {
        let projects = { ...state.options.projects.search }

        if (payload.has_data) {
            Vue.set(state.options.projects.search, 'offset', projects.offset + projects.limit)
            Vue.set(state.options.projects.search, 'next', payload.next && !!payload.items.length)
            Vue.set(state.options.projects.search, 'items', projects.items.concat(payload.items))
        }
    },

    /* Очистка записей поиска */
    clearProjectsSearch (state) {
        Vue.set(state.options.projects.search, 'offset', 0)
        Vue.set(state.options.projects.search, 'next', true)
        Vue.set(state.options.projects.search, 'items', [])
    },

    /* Сохранение значения активного проекта */
    initActiveProject (state, id) {
        if (id) {
            localStorage.setItem(constants.LOCAL_STORAGE_DATA.ACTIVE_PROJECT, id)
            Vue.set(state, 'active_project', id)
        } else {
            localStorage.removeItem(constants.LOCAL_STORAGE_DATA.ACTIVE_PROJECT)
            Vue.set(state, 'active_project', 0)
        }
    },

    /* Запись проекта в хранилище */
    getProject (state, data) {
        Vue.set(state, 'active_project_data', data)
    },

    /* Сохранение фильтров */
    saveFilters (state, payload) {
        if (payload.hasData) {
            Vue.set(state, 'filtersOptions', {
                has_data: true,
                items: payload.data.items
            })
        } else {
            Vue.set(state, 'filtersOptions', {
                has_data: false,
                items: {
                    demensions: [],
                    devices: [],
                    traffic_sources: {},
                    device: [],
                    region: []
                }
            })
        }
    },

    setCancelRequests (state, payload) {
        Vue.set(state, 'cancelRequests', payload)
    },

    /* Сохранение фильтров по трафику */
    saveFiltersTraffic (state, payload) {
        if (payload.search) {
            let data = {...state.filtersTraffic[payload.name].search}
            if (payload.hasData && payload.data && payload.data.items && payload.data.items.length) {
                Vue.set(state.filtersTraffic[payload.name].search, 'offset', data.offset + data.limit)
                Vue.set(state.filtersTraffic[payload.name].search, 'next', payload.data.has_more && !!payload.data.items.length)
                Vue.set(state.filtersTraffic[payload.name].search, 'items', data.items.concat(payload.data.items))
            }
        } else {
            let data = {...state.filtersTraffic[payload.name]}
            if (payload.hasData && payload.data && payload.data.items && payload.data.items.length) {
                Vue.set(state.filtersTraffic[payload.name], 'offset', data.offset + data.limit)
                Vue.set(state.filtersTraffic[payload.name], 'next', payload.data.has_more && !!payload.data.items.length)
                Vue.set(state.filtersTraffic[payload.name], 'items', data.items.concat(payload.data.items))
            }
        }
    },

    /* Очистка фильтра по трафику */
    clearFiltersTraffic (state, name) {
        Vue.set(state.filtersTraffic[name], 'offset', 0)
        Vue.set(state.filtersTraffic[name], 'next', true)
        Vue.set(state.filtersTraffic[name], 'items', [])
    },

    /* Очистка фильтра по трафику */
    clearFiltersTrafficSearch (state, name) {
        Vue.set(state.filtersTraffic[name].search, 'offset', 0)
        Vue.set(state.filtersTraffic[name].search, 'next', true)
        Vue.set(state.filtersTraffic[name].search, 'items', [])
    },

    /* Сохранение списка регионов */
    saveRegionSearchList (state, payload) {
        if (payload.hasData) {
            Vue.set(state, 'regionSearchList', {
                hasData: true,
                items: (payload.data && payload.data.items) || []
            })
        } else {
            Vue.set(state, 'regionSearchList', {
                hasData: false,
                items: []
            })
        }
    },

  setTrafficSourceSelectedName(state, value) {
      state.trafficSourceSelectedName = value
  }
}
export default mutations
