import Vue from 'vue'

import {findKey} from '@/function/findOptions.js'

export default {
  setList (state, payload) {
    if (payload.has_data) {
      Vue.set(state, 'data', {
        ...state.data,
        has_data: payload.has_data && !!payload.items.length,
        next: payload.has_more,
        items: payload.items,
        total: payload.total,
        counts: payload.counts
      })
    } else {
      Vue.set(state, 'data', {
        has_data: false,
        limit: 100,
        offset: 0,
        total: 0,
        next: true,
        items: [],
        counts: {}
      })
    }
  },

  removeSegmentFromList (state, ids) {
    for (let id of ids) {
      let countSegment = findKey(id, state.data.items)
      if (countSegment !== undefined) {
        state.data.items.splice(countSegment, 1)
      }
    }
  },

  editSegmentInList (state, payload) {
    let countSegment = findKey(payload.id, state.data.items)
    if (countSegment !== undefined) {
      Vue.set(state.data.items[countSegment], 'name', payload.name)
    }
  },

  setAuthors (state, {payload, client_id}) {
    if (payload.has_data) {
      Vue.set(state, 'autors', {
        client_id: client_id,
        items: payload.data.map(item => {
          switch (true) {
            case (item.name && item.surname && item.name === item.surname):
              item.description = item.name
              break

            default:
              item.description = `${item.name} ${item.surname}`
              break
          }

          return item
        }) || []
      })
    } else {
      Vue.set(state, 'autors', {
        client_id: undefined,
        items: []
      })
    }
  }
}
