export default {
    PROJECT_SUMMARY: 'projectSummary',
    FUNNEL_STAGES: 'funnelStages',
    DEMAND_VISIBILITY: 'demandVisibility',
    TRAFFIC: 'traffic',
    TRANSACTIONS: 'transactions',
    REVENUE: 'revenue',
    CONVERSION: 'conversion',
    LEADERS_OUTSIDERS: 'leadersOutsiders',
    LEADERS: 'leaders',
    OUTSIDERS: 'outsiders',
    POPULAR_SLICES: 'popularSlices',
    CHANNELS: 'channels',
    URL_TYPES: 'urlTypes',
    GEO: 'geo',
    CATEGORIES: 'categories',
    DEVICES: 'devices'
}