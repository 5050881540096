import constants from '@/const/'
import errors from '@/store/errors.js'

export default {
  getMetrics ({rootState, commit}, input) {
    return new Promise((resolve, reject) => {
      rootState.request({
        type: constants.METHODS.GET,
        url: '/bi/metrics',
        apiName: 'seowork',
        auth: true,
        query: input.query
      }).then(result => {
        if (result && result.data && result.data.filters && result.data.filters.length) {
          commit('saveMetrics', result.data.filters)
        } else {
          commit('saveMetrics', [])
        }
        resolve(true)
      }, () => {
        reject(new Error(errors.GET_DATA))
      })
    })
  },

  getDefaultSegments ({rootState, commit}, input) {
    return new Promise((resolve, reject) => {
      rootState.request({
        type: constants.METHODS.GET,
        url: '/bi/frequency/top',
        apiName: 'seowork',
        auth: true,
        query: input.query
      }).then(result => {
        if (result && result.has_data && result.data && result.data.items && result.data.items.length) {
          commit('saveDefaultSegments', {
            has_data: true,
            items: result.data.items
          })
        }
        resolve(true)
      }, () => {
        reject(new Error(errors.GET_DATA))
      })
    })
  }
}
