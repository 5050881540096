export default function () {
  return {
    data: {
      has_data: false,
      limit: 100,
      offset: 0,
      next: true,
      items: []
    },

    autors: {
      client_id: undefined,
      items: []
    }
  }
}
