import moment from 'moment'

/*
 * Функция поиска ближайшей даты из списка
 */
export default function ({
  date,
  updates,
  sort = true,
  returnFormat = 'x',
  past = false
}) {
  const returnFormatMoment = (date) => {
    return moment.utc(date).format(returnFormat)
  }

  let sortUpdates = JSON.parse(JSON.stringify(updates))

  if (sort) {
    sortUpdates = sortUpdates.sort()
  }

  if (date < sortUpdates[0]) {
    return returnFormatMoment(sortUpdates[0])
  } else if (date > sortUpdates[sortUpdates.length - 1]) {
    return returnFormatMoment(sortUpdates[sortUpdates.length - 1])
  } else {
    if (past) {
      for (let i in sortUpdates) {
        switch (true) {
          case sortUpdates[i] === date:
            return returnFormatMoment(sortUpdates[i])

          case sortUpdates[i] > date:
            return returnFormatMoment(sortUpdates[i !== 0 ? i - 1 : i])
        }
      }
    } else {
      for (let i in sortUpdates) {
        if (sortUpdates[i] >= date) {
          return returnFormatMoment(sortUpdates[i])
        }
      }
    }
  }
}
