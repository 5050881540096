import Vue from 'vue'

/* Notifications */
import notify from '@/components/notify/'
Vue.use(notify)

/* Запросы */
import VueResource from 'vue-resource'
Vue.use(VueResource)

/* Библиотека для работы с Cookies */
import VueCookie from 'vue-cookie'
Vue.use(VueCookie)

/* Метаданные страниц */
import Meta from 'vue-meta'
Vue.use(Meta)

/* Библиотека компонентов */
import seoworkVueComponents from '@seowork/seowork-vue-components'
Vue.use(seoworkVueComponents)

/* SVG sprites */
const __svg__ = {
    path: '../assets/svg/**/*.svg',
    name: './static/svg/public.[hash].svg'
};

require('webpack-svgstore-plugin/src/helpers/svgxhr')(__svg__);

/*
 * Responsive
 * Библиотека глобальной шины размера экрана.
 * Предназначена для отслеживания размера экрана в цикличных компонентах
 */
import responsive from '@/function/responsive.js'
Vue.use(responsive);

/* Валидация */
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
import 'swiper/css/swiper.css'
