<template>
  <div class="metrics-container _custom-scrollbar">
    <div
        v-for="(block, i) of cells"
        :key="i"
        class="metrics-block"
    >
      <div
          v-if="!metricSearch"
          class="metrics-item"
      >
        <v-button
            :class="['metrics-item__toggle', {_active: metricsBlockOpened.includes(block.id)}]"
            type="text-base"
            :icon="metricsBlockOpened.includes(block.id) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
            height="20px"
            @click="toggleMetricsBlockView(block.id)"
        />
        <span class="metrics-item__title">{{ block.name }}</span>
        <v-metric-checkbox
            class="metrics-item__checkbox"
            :value="metricsConfig[block.id]"
            :icon="checkboxIconConfig[block.id]"
            :metrics="block.items"
            :id="block.id"
            :disabled="block.disabled"
            :on-change="toggleMetricsCheckbox"
            :data-test="`checkbox_${block.id}`"
        />
      </div>
      <template v-if="metricsBlockOpened.includes(block.id) || metricSearch">
        <template v-for="metric of block.items">
          <div
              v-if="!metricSearch || metricSearch && metric.name.toLowerCase().includes(metricSearch.toLowerCase())"
              :key="metric.id"
              class="metrics-item"
          >
            <span class="metrics-item__subtitle">
              {{ metric.name }}
            </span>
            <v-metric-checkbox
                class="metrics-item__checkbox"
                v-model="metricsConfig[metric.id]"
                :disabled="metric.disabled && !!metricsConfig[metric.id]"
                :id="metric.id"
                :parent-id="block.id"
                :on-change="toggleMetricsCheckbox"
                :icon="metricsConfig[metric.id] ? 'done' : ''"
                :data-test="`checkbox_${metric.id}`"
            />
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import {findOptions} from '@/function'

const metricCheckbox = {
  render: function (createElement) {
    return createElement(
        'v-checkbox',
        {
          props: {
            onChange: this.check,
            value: this.value,
            icon: this.icon,
            disabled: this.disabled
          }
        }
    )
  },

  props: {
    metrics: {
      type: Array,
      default: () => ([])
    },

    onChange: {
      type: Function,
      default: () => false
    },

    value: {
      type: Boolean,
      default: false
    },

    icon: {
      type: String,
      default: ''
    },

    id: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    parentId: {
      type: String,
      default: ''
    }
  },

  methods: {
    check () {
      this.onChange(this.id, this.value, this.metrics, this.parentId)
    }
  }
}

export default {
  name: 'metricsAdd',

  components: {
    'v-metric-checkbox': metricCheckbox
  },

  props: {
    metricsConfig: {
      type: Object,
      default: () => ({})
    },

    cells: {
      type: Array,
      default: () => ([])
    },

    metricSearch: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      metricsBlockOpened: [],
      checkboxIconConfig: {}
    }
  },

  created () {
    this.updateIcons()
  },

  watch: {
    metricsConfig: {
      deep: true,
      handler () {
        this.updateIcons()
      }
    }
  },

  methods: {
    /* Управление раскрытием строк */
    toggleMetricsBlockView (metricsBlock) {
      const index = this.metricsBlockOpened.indexOf(metricsBlock)
      if (index !== -1) {
        this.metricsBlockOpened.splice(index, 1)
      } else {
        this.metricsBlockOpened.push(metricsBlock)
      }
    },

    /* Получение иконка для чекбокса блока метрик */
    getCheckboxIcon (metrics) {
      return metrics.every(metric => this.metricsConfig[metric.id]) ? 'done' : metrics.some(metric => this.metricsConfig[metric.id]) ? 'remove' : ''
    },

    /* Управление чекбоксами блока метрик */
    toggleMetricsCheckbox (id, value, metrics, parentId) {
      if (metrics.length) {
        let config = {...this.metricsConfig}
        const hasDisabled = metrics.some(metric => metric.disabled)
        metrics.forEach(metric => {
          if (!metric.disabled) {
            config[metric.id] = !hasDisabled
              ? !value
              : this.checkboxIconConfig[id] === 'remove'
          }
        })

        config[id] = metrics.some(metric => config[metric.id])

        this.$emit('update-metrics-config', config)
        this.$set(this.checkboxIconConfig, id, this.getCheckboxIcon(metrics))
      } else {
        let config = {...this.metricsConfig, [id]: !value}
        let parentItem = findOptions(parentId, this.cells)
        let otherMetrics = parentItem.items.filter(metric => metric.id !== id)
        if (
            value && otherMetrics.every(metric => this.metricsConfig[metric.id] !== value) ||
            !value && otherMetrics.every(metric => this.metricsConfig[metric.id] === value)
        ) {
          config[parentId] = !value
        }

        this.$emit('update-metrics-config', config)
        this.$set(this.checkboxIconConfig, parentId, this.getCheckboxIcon(parentItem.items))
      }
    },

    updateIcons () {
      this.cells.forEach(cell => this.$set(this.checkboxIconConfig, cell.id, this.getCheckboxIcon(cell.items)))
    }
  }
}
</script>

<style scoped lang="scss">
.metrics {
  &-container {
    height: 330px;
    padding: 8px 0;
    overflow-y: scroll;
  }

  &-item {
    display: flex;
    align-items: center;
    padding: 10px 26px 10px 17px;

    &__toggle {
      flex-shrink: 0;
      margin-right: 7px;
      padding: 0 !important;

      &._active {
        & /deep/ .button__icon {
          fill: #2979FF;
        }
      }
    }

    &__checkbox {
      margin-left: auto;
    }

    &__title {
      margin-right: 5px;
      font-weight: 500;
    }

    &__subtitle {
      margin-left: 27px;
    }
  }

  &-block {
    min-width: 230px;
  }
}
</style>
