import {METHODS} from '@/const'
import ERRORS from '@/store/errors'

const actions = {
    /* Загрузка каталога */
    getCatalog ({commit, rootState}, input) {
      if (input.query.summary) {
        commit('saveSummary', {
            hasData: false
        })
      } else if (input.query.catalog_id === '0') {
        commit('saveCatalog', {
            hasData: false,
            catalogId: '0'
        })
      }
        return new Promise((resolve, reject) => {
            rootState.request({
                type: METHODS.GET,
                url: input.url || '/catalog',
                auth: true,
                apiName: 'main',
                query: input.query,
                cancelable: true
            }).then(result => {
              if (result.has_data) {
                if (input.query.summary) {
                  commit('saveSummary', {
                      hasData: true,
                      data: result.data.summary,
                  })
                } else {
                  commit('saveCatalog', {
                      hasData: true,
                      items: result && result.data && result.data.items || [],
                      total: result.data.total,
                  })
                }
              }
                resolve(true)
            }, () => {
                reject(new Error(ERRORS.GET_DATA))
            })
        })
    },

   getUserSegments: function ({commit, rootState}, input) {
      commit('saveUserSegments', {
        hasData: false,
      })

      return new Promise((resolve, reject) => {
        rootState.request({
            type: METHODS.GET,
            url: '/user_segments',
            auth: true,
            apiName: 'main',
            query: input.query,
            cancelable: true
        }).then(result => {
            if (result.has_data) {
              commit('saveUserSegments', {
                  hasData: true,
                  items: result && result.data && result.data.items || [],
                  total: result.data.total,
              })
            }
            resolve(true)
        }, () => {
            reject(new Error(ERRORS.GET_DATA))
        })
    })
  }
}

export default actions
