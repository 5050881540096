import Vue from 'vue'

export default {
  setFrequency (state, payload) {
    if(payload.has_data) {
      Vue.set(state, 'frequency', {
        has_data: true,
        ...payload.data
      })
    } else {
      Vue.set(state, 'frequency', {
        has_data: false
      })
    }
  },

  setFrequencySE (state, payload) {
    if(payload.yandex && payload.yandex.has_data) {
      Vue.set(state, 'frequencyYandex', {
        has_data: true,
        ...payload.yandex.data
      })
    } else {
      Vue.set(state, 'frequencyYandex', {
        has_data: false
      })
    }

    if(payload.google && payload.google.has_data) {
      Vue.set(state, 'frequencyGoogle', {
        has_data: true,
        ...payload.google.data
      })
    } else {
      Vue.set(state, 'frequencyGoogle', {
        has_data: false
      })
    }
  },

  setTraffic (state, {result, searchEngine}) {
    if(result.has_data) {
      Vue.set(state.traffic, searchEngine, {
        has_data: true,
        ...result.data
      })
    } else {
      Vue.set(state.traffic, searchEngine, {
        has_data: false
      })
    }
  },

  /* Запись проекта в хранилище */
  getProject (state, data) {
    Vue.set(state, 'project_data', data)
  },
}
