import Vue from 'vue'

export default {
  saveMetrics (state, payload) {
    const data = [...payload]
    if (data.length) {
      data.forEach(el => {
        switch (el.name) {
          case 'tech':
            el.label = 'Tech. metrics'
            break
          case 'visibility':
            el.label = 'Visibility metrics'
            break
          case 'ws':
            el.label = 'Demand metrics'
            break
        }
      })
    }
    Vue.set(state, 'metrics', payload)
  },

  saveDefaultSegments (state, payload) {
    Vue.set(state, 'defaultSegments', payload)
  }
}
