import settingsTableCells from './settingsTableCells/settingsTableCells'
import metricFilter from './metricFilter'
import segmentCreate from '@/services/dashboard/pages/segments/content/modals/create/'
import formSuccess from './formSuccess'
import segmentEdit from '@/services/dashboard/pages/segments/content/modals/edit'
import cardSettings from '@/services/dashboard/pages/quickStart/components/cardSettings/cardSettings'

/* Список модальных окон */
export default {
  settingsTableCells,
  metricFilter,
  segmentCreate,
  formSuccess,
  segmentEdit,
  cardSettings
}
