<template>
  <div class="settings-modal">
    <div class="settings-modal__title">Настройка метрик в таблице</div>
    <div class="settings-modal__description">
      Настройте таблицу, чтобы видеть те метрики которые нужны вам сейчас. <br>
      Добавляйте или удаляйте метрики для более удобной работы с вашими задачами!</div>

    <div class="settings-modal-container _custom-scrollbar">
      <div class="settings-modal-block">
        <div class="settings-modal-block__head">Добавление метрик</div>

        <div class="settings-modal-block__filter">
          <s-input
              class="settings-modal-block__filter-search"
              icon="search"
              placeholder="Поиск метрики"
              v-model="metricSearch"
              clear-button
              data-test="metric_search"
          />
        </div>

        <v-metrics-add
            :cells="tableCellsConfig"
            :metrics-config="metricsConfig"
            :metric-search="metricSearch"
            @update-metrics-config="updateMetricsConfig($event)"
        />
      </div>

      <div class="settings-modal-block">
        <div class="settings-modal-block__head">
          <span>Метрики в таблице</span>
          <div class="settings-modal-block__head-count">
            {{ metricsVisible.length }}/<span>{{ metricsCountTotal }}</span>
          </div>
        </div>

        <v-metrics-list
            :metrics="metricsVisible"
            @delete-metric="updateMetricsConfig($event)"
        />
      </div>
    </div>

    <div class="settings-modal-buttons">
      <v-button
          class="settings-modal__button"
          type="text-base"
          name="Отменить"
          @click="$modal.close()"
      />
      <v-button
          class="settings-modal__button"
          name="Сохранить"
          dataforce
          @click="setConfig"
      />
    </div>
  </div>
</template>

<script>
import {findOptions} from '@/function'

import metricsAdd from './components/metricsAdd'
import metricsList from './components/metricsList'

export default {
  name: 'settingsTableCells',

  components: {
    'v-metrics-add': metricsAdd,
    'v-metrics-list': metricsList
  },

  props: {
    tableCellsConfig: {
      type: Array,
      default: () => ([])
    },

    saveTableCellsConfigFunc: {
      type: Function,
      default: () => false
    },

    page: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      metricsConfig: {},

      metricSearch: undefined
    }
  },

  computed: {
    metricsCountTotal () {
      return Object.keys(this.metricsConfig).filter(metric => !findOptions(metric, this.tableCellsConfig)).length
    },

    metricsVisible () {
      let metrics = []
      this.tableCellsConfig.forEach(block => {
        block.items.forEach(metric => {
          if (this.metricsConfig[metric.id]) metrics.push({
            ...metric,
            parentName: block.name,
            parentId: block.id
          })
        })
      })
      return metrics
    },

    tableCellsConfigKey() {
      return `${this.page}TableCellsConfig`
    }
  },

  created () {
    this.tableCellsConfig.forEach(section => {
      this.$set(this.metricsConfig, section.id, section.items.some(metric => metric.visible))
      section.items.forEach(metric => {
        this.$set(this.metricsConfig, metric.id, metric.visible)
      })
    })
  },

  methods: {
    updateMetricsConfig (newConfig) {
      for (let item in newConfig) {
        this.$set(this.metricsConfig, item, newConfig[item])
      }
    },

    setConfig () {
      this.saveTableCellsConfigFunc({newConfig: this.metricsConfig, key: this.tableCellsConfigKey})
      this.$modal.close()
    }
  }
}
</script>

<style scoped lang="scss">
.settings-modal {
  width: calc(90vw - 48px);
  max-width: 760px;
  padding: 24px;

  &__title {
    margin-bottom: 10px;
    color: #2D3646;
    font-size: 20px;
    font-weight: 500;
  }

  &__description {
    margin-bottom: 16px;
    line-height: 20px;
    color: #455165;
    font-size: 12px;
  }

  &-container {
    display: flex;
    column-gap: 16px;
    margin-bottom: 26px;
    overflow-x: scroll;
  }

  &-block {
    border: 1px solid #E0E6F0;
    border-radius: 6px;
    color: #455165;

    &:first-child {
      width: 268px;
    }

    &:last-child {
      width: 428px;
    }

    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 17px;
      border-bottom: 1px solid #E0E6F0;
      font-size: 16px;
      font-weight: 500;

      &-count {
        font-size: 14px;
        font-weight: 400;

        span {
          color: #BDBDBD;
        }
      }
    }

    &__filter {
      border-bottom: 1px solid #E0E6F0;

      &-search {
        margin: 16px;
      }
    }
  }

  &-buttons {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}
</style>
