const state = {
    /* Типы заданий */
    taskTypes: [],

    /* Список проектов */
    projects: {
        limit: 100,
        offset: 0,
        next: true,
        list: [],
        search: []
    },
}

export default state