import state from './state.js'
import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

import list from './list/'
import item from './item'

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,

  modules: {
    list,
    item
  }
}
