var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"settings-modal"},[_c('div',{staticClass:"settings-modal__title"},[_vm._v("Настройка стикеров")]),_vm._m(0),_c('div',{staticClass:"settings-modal-container _custom-scrollbar"},[_c('div',{staticClass:"settings-modal-block"},[_c('div',{staticClass:"settings-modal-block__head"},[_vm._v("Добавление стикеров")]),_c('div',{staticClass:"settings-modal-block__filter"},[_c('s-input',{staticClass:"settings-modal-block__filter-search",attrs:{"icon":"search","placeholder":"Поиск стикера","clear-button":""},model:{value:(_vm.cardSearch),callback:function ($$v) {_vm.cardSearch=$$v},expression:"cardSearch"}})],1),_c('div',{staticClass:"settings-modal-block__filter"},[_c('v-button',{staticClass:"settings-modal__visibility-btn",attrs:{"type":"text-main","width":"100%","height":"44px","name":`${_vm.checkAll ? 'Скрыть' : 'Показать'} все стикеры`,"icon":`visibility${_vm.checkAll ? '_off' : ''}`,"offset-icon":"4px"},on:{"click":_vm.toggleAll}})],1),_c('v-card-add',_vm._b({on:{"toggle-active":_vm.updateCardConfigTemp}},'v-card-add',{
            cards: _vm.cards,
            cardConfig: _vm.cardConfigTemp,
            cardSearch: _vm.cardSearch,
            singleCardVisible: _vm.singleCardVisible,
            cardGroups: _vm.cardGroups
          },false))],1),_c('div',{staticClass:"settings-modal-block"},[_c('div',{staticClass:"settings-modal-block__head"},[_c('span',[_vm._v("Стикеры на странице")]),_c('div',{staticClass:"settings-modal-block__head-count"},[_vm._v(" "+_vm._s(_vm.cardsVisible.length)+"/"),_c('span',[_vm._v(_vm._s(_vm.cardConfigTemp.length))])])]),_c('v-card-list',{attrs:{"cards":_vm.cardDataVisible},on:{"delete-card":function($event){return _vm.toggleCardView($event)},"reorder":_vm.reorderCards,"delete-all":_vm.deleteAllCards}})],1)]),_c('div',{staticClass:"settings-modal-buttons"},[_c('v-button',{staticClass:"settings-modal__button",attrs:{"type":"text-base","name":"Отменить"},on:{"click":function($event){return _vm.$modal.close()}}}),_c('v-button',{staticClass:"settings-modal__button",attrs:{"name":"Сохранить","dataforce":""},on:{"click":_vm.save}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"settings-modal__description"},[_vm._v(" Настройте стикеры так, чтобы видеть только те, которые нужны вам сейчас. "),_c('br'),_vm._v(" Добавляйте или удаляйте стикеры для более удобной работы с вашими задачами! ")])
}]

export { render, staticRenderFns }