<template>
  <div class="pagination" :class="{'_table-bottom': tableBottom}">
    <!-- Отображаемое количество элементов -->
    <div class="pagination-status" v-if="showCountElement">
      {{ viewCountElements }}
    </div>
    <!-- Отображаемое количество элементов -->

    <!-- Селектор лимита элементов -->
    <div class="pagination-count" v-if="showLimitSelector">
      Строк на стр.
      <div class="pagination-select" v-click-outside="closeListLimits" @click="toggleListLimits">
        <div class="pagination-select__button">
          {{ limit }}
          <div class="pagination-select__arrow">
            <icon name="arrow_drop_down"/>
          </div>
        </div>
        <div class="pagination-select__list" v-if="selectLimts">
          <div
              class="pagination-select__item"
              :class="{ '_active': item === limit }"
              v-for="(item, index) in limitList"
              :key="index"
              @click="updateLimit(item)"
          >
            {{ item }}
            <icon v-if="item === limit" class="pagination-select__check" name="done"/>
          </div>
        </div>
      </div>
    </div>
    <!-- Cелектор лимита элементов -->

    <!-- Нумерованный список страниц -->
    <ul id="pagination-list" class="pagination-list">
      <li v-if="hasPrev">
        <router-link class="pagination-list__link" :to='pathComp(prevPage)'>
          <icon class="pagination-list__arrow" name="arrow_back_ios"/>
        </router-link>
      </li>

      <li v-if="hasFirst">
        <router-link class="pagination-list__link" :to="pathComp(1)">1</router-link>
      </li>

      <li v-if="hasFirstDot">
        <span class="pagination-list__dots">...</span>
      </li>

      <li v-for="page in pageNumbers">
        <span v-if="page === pageNumberReal" class="pagination-list__active">{{ page }}</span>
        <router-link v-else class="pagination-list__link" :to="pathComp(page)">{{ page }}</router-link>
      </li>

      <li v-if="hasLastDot">
        <span class="pagination-list__dots">...</span>
      </li>

      <li v-if="hasLast">
        <router-link class="pagination-list__link" :to="pathComp(totalPages)">{{ totalPages }}</router-link>
      </li>

      <li v-if="hasNext">
        <router-link class="pagination-list__link" :to='pathComp(nextPage)'>
          <icon class="pagination-list__arrow" name="arrow_forward_ios"/>
        </router-link>
      </li>
    </ul>
    <!-- Нумерованный список страниц -->
  </div>
</template>

<script>
import {generateRoute} from '@/function'
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside
  },

  data () {
    return {
      contentPath: 'components.pagination',

      /* Флаг отображения списка лимитов */
      selectLimts: false
    }
  },

  props: {
    /* Номер текущей страницы */
    pageNumber: {
      type: [Number, String]
    },

    /* Лимит объектов на странице */
    limit: {
      type: Number,
      default: 25
    },

    /* Общее количество элеменетов */
    count: {
      type: Number,
      default: 0
    },

    /* Список доступных лимитов */
    limitList: {
      type: Array,
      default: () => {
        return [5, 10, 25, 50, 100]
      }
    },

    /* Максимальное количество кнопок пагинации */
    buttonsCount: {
      type: Number,
      default: 9
    },

    /* Обновление количества элементов на странице */
    updateLimit: {
      type: Function,
      default: function (newLimit) {
        this.$emit('limit', newLimit)
        this.updatePage(1)
      }
    },

    /* Обновление номера страницы */
    updatePage: {
      type: Function,
      default: function (newPage) {
        if (this.pageNumber) {
          this.$emit('page', newPage)
        } else {
          this.$router.push(this.pathComp(newPage))
        }
      }
    },

    tableBottom: {
      type: Boolean,
      default: false
    },

    showLimitSelector: {
      type: Boolean,
      default: true
    },

    showCountElement: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    /* Отображаемое количество элементов */
    viewCountElements () {
      let total = this.count
      if (total === 0) return `0 из 0`
      let start = (this.pageNumberReal - 1) * this.limit + 1
      let end = this.pageNumberReal * this.limit
      return `${start}-${end < total ? end : total} из ${total}`
    },

    /* Валидный номер текущей страницы */
    pageNumberReal () {
      let page = this.pageNumber || this.$route.query.page

      if (page && !isNaN(+page) && page <= this.totalPages) {
        return +page
      } else {
        return 1
      }
    },

    /* Общее количество страниц */
    totalPages () {
      return Math.ceil(this.count / this.limit)
    },

    /* Номер предидущей страницы */
    prevPage () {
      return this.pageNumberReal - 1
    },

    /* Номер текущей страницы */
    nextPage () {
      return this.pageNumberReal + 1
    },

    /* Отображение кнопок навигации */
    viewNavigation () {
      return this.buttonsCount <= this.totalPages
    },

    /* Количество кнопок по краям */
    halfButtonsCountEdges () {
      let buttonsCount = this.buttonsCount <= this.totalPages ? this.buttonsCount : this.totalPages
      return (buttonsCount - 1) / 2
    },

    /* Ранжирование кнопок */
    rangeButtons () {
      return {
        start: Math.ceil(this.pageNumberReal - this.halfButtonsCountEdges),
        end: Math.ceil(this.pageNumberReal + this.halfButtonsCountEdges)
      }
    },

    /* Отображение ссылки на предидущую страницу */
    hasPrev () {
      return this.viewNavigation && this.pageNumberReal > 1
    },

    /* Отображение ссылки на следующую страницу */
    hasNext () {
      return this.viewNavigation && this.pageNumberReal < this.totalPages
    },

    /* Массив кнопок */
    pageNumbers () {
      let pages = []

      let start = this.rangeButtons.start
      let end = this.rangeButtons.end

      if (this.hasPrev) start++
      if (this.hasNext) end--

      if (this.hasFirst) start++
      if (this.hasLast) end--

      if (this.hasFirstDot) start++
      if (this.hasLastDot) end--

      if (start < 1) {
        end = end + (1 - start)
        start = 1
      } else if (end > this.totalPages) {
        start = start + (this.totalPages - end)
        end = this.totalPages
      }

      for (let i = start; i <= end; i++) {
        pages.push(i)
      }

      return pages
    },

    /* Отображение первой страницы */
    hasFirst () {
      if (this.viewNavigation) {
        let start = this.rangeButtons.start
        if (this.hasPrev) start++
        return start >= 2
      }
    },

    /* Отображение последней страницы */
    hasLast () {
      if (this.viewNavigation) {
        let end = this.rangeButtons.end
        if (this.hasNext) end--
        return end < this.totalPages
      }
    },

    /* Отображение первой страницы */
    hasFirstDot () {
      if (this.viewNavigation) {
        let start = this.rangeButtons.start
        if (this.hasPrev) start++
        if (this.hasFirst) start++
        return start >= 2
      }
    },

    /* Отображение последней страницы */
    hasLastDot () {
      if (this.viewNavigation) {
        let end = this.rangeButtons.end
        if (this.hasNext) end--
        if (this.hasLast) end--
        return end < this.totalPages - 1
      }
    }
  },

  methods: {
    /* Генерация ссылки */
    pathComp (pageId) {
      let newRoute = generateRoute(this.$route)
      newRoute.query.page = pageId || undefined
      return newRoute
    },

    /* Закрытие списка лимитов */
    closeListLimits () {
      this.selectLimts = false
    },

    /* Открытие списка лимитов */
    toggleListLimits () {
      this.selectLimts ^= true
    }
  }
}
</script>

<style lang="scss" src="./pagination.scss" scoped></style>
