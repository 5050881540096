export default function (value, length, slice = true) {
    if (
        typeof value !== 'string' ||
        !slice
    ) return value

    if (value.length === value.slice(0, length).length) return value

    return value.slice(0, length) + '...'
}
