<template>
  <div class="modal _custom-scrollbar" :class="{'_right': $modal.right, '_transparent': $modal.transparent}" v-if="$modal.name">
    <div class="modal__case">
      <div class="modal__shadow" @click="close"></div>
      <div class="modal__content">
        <v-button
          class="modal__close-btn"
          @click="close"
          icon="close"
          type="text-base"
          padding-left="4px"
          padding-right="4px"
        />
        <component :is="modalComponent" v-bind="$modal.props"/>
      </div>
    </div>
  </div>
</template>

<script>
import product from '@/modals'

export default {
  name: 'modal',

  computed: {
    modalComponent () {
      return this.createModalComponent(this.$modal.name)
    }
  },

  data () {
    return {
      scrollbar: '',
      htmlClass: document.getElementsByTagName('html')[0]
    }
  },

  watch: {
    '$route' (to, from) {
      if (to.query.modal) {
        this.open(to.query.modal)
      }
    },

    '$modal' (modal) {
      if (modal && modal.name && modal.name !== '') {
        this.htmlClass.classList.add('noscroll')
        this.htmlClass.style.marginRight = this.scrollbar + 'px'
      } else {
        this.htmlClass.classList.remove('noscroll')
        this.htmlClass.style.marginRight = 0
      }
    }
  },

  mounted () {
    this.$nextTick(function () {
      this.htmlClass.classList.add('noscroll')
      let w1 = this.htmlClass.clientWidth
      this.htmlClass.classList.remove('noscroll')
      let w2 = this.htmlClass.clientWidth
      this.scrollbar = w1 - w2
    })

    document.addEventListener('keydown', this.keydownEscape)
  },

  destroyed () {
    document.removeEventListener('keydown', this.keydownEscape)
  },

  methods: {
    /* Метод при нажатии на Escape */
    keydownEscape (event) {
      if (event.code === 'Escape') {
        this.close()
      }
    },

    open (name) {
      this.$modal.open({name: name})
    },

    close () {
      this.$modal.close()
      this.clearRoute()
    },

    clearRoute () {
      if (this.$route.query.modal) {
        let to = {
          name: JSON.parse(JSON.stringify(this.$route.name)),
          params: JSON.parse(JSON.stringify(this.$route.params)),
          query: JSON.parse(JSON.stringify(this.$route.query))
        }

        to.query.modal = undefined

        this.$router.replace(to)
      }
    },

    createModalComponent (name) {
      if (name && product[name]) {
        return product[this.$modal.name]
      } else {
        this.$modal.close()
      }
    }
  }
}
</script>

<style lang="scss" src="./modals.scss" scoped></style>
