const state = {
  projectFlags: [],

  projectsTotal: null,

  projects: {
    items: [],
    total: 0
  },

  selectedClientId: null
}

export default state
