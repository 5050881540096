var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
          'filters__item',
          { '_right': _vm.right },
          { '_left': _vm.left },
          { '_noline': _vm.noline },
          { '_bottom': _vm.bottom}
      ],style:(_vm.componentStyle)},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }