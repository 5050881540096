import ROUTE_NAME from '@/router/routeName'

export default {
    routes: {
        bi: {
            [ROUTE_NAME.BI]: 'BI',
            [ROUTE_NAME.BI_COMPARISON]: 'Segments comparison',
            [ROUTE_NAME.BI_COMPARISON_CORRELATION_SALES]: 'Sales correlations',
            [ROUTE_NAME.BI_COMPARISON_DYNAMICS_SALES]: 'Sales dynamics',
        }
    },

    components: {
        segments: {
            'Search': 'Search',
            'No segments found': 'No segments found'
        }
    },

    modules: {
        pages: {
            'Error': 'Error',
            'No access to projects': 'No access to projects',
            'No projects': 'No projects',
            'Cancel': 'Cancel',
            'Yes': 'Yes',
            'No': 'No',
            'Delete': 'Delete'
        },
        searchEngines: {
            'Yandex': 'Yandex',
            'Google': 'Google'
        },
        deviceTypes: {
            'Mobile': 'Mobile',
            'Desktop': 'Desktop'
        }
    },

    services: {
        bi: {
            comparison: {
                'No segments selected': 'No segments selected',
                'Select segments to compare': 'Select segments to compare',
                'Remove this segment from the comparison': 'Remove this segment from the comparison',
                'Segment name': 'Segment name',
                'Creation date': 'Creation date',
                'Traffic': 'Traffic',
                'Number of elements': 'Number of elements',
                'Delete': 'Delete',
                'Sales correlations': 'Sales correlations',
                'Transactions': 'Transactions',
                'Conversion': 'Conversion',
                'Sales dynamics': 'Sales dynamics',
                'date': 'date',
                'Analysis start date': 'Analysis start date',
                'Analysis end date': 'Analysis end date',

                filters: {
                    'Search system': 'Search system',
                    'Select SS': 'Select SE',
                    'Select a metric': 'Select a metric',
                    'Visibility metrics': 'Visibility metrics',
                    'Sales metric': 'Sales metric',
                    'Select period': 'Select period',
                    'Collect BI': 'Collect BI',
                    'Control group': 'Control group',
                    'Select segment': 'Select segment',
                    'Add segments': 'Add segments',
                    'All Site': 'All Site',
                    'Dynamics': 'Dynamics',
                    'Correlations': 'Correlations',
                    'Sales': 'Sales'
                }
            }
        },

        errors: {
            'Back to': 'Back to',
            'the home page': 'the home page',
            'the previous page': 'the previous page'
        },

        segments: {
            table: {
                'Name': 'Name',
                'Type': 'Type',
                'Status': 'Status',
                'User': 'User',
                'Date': 'Date',
                'Download': 'Download',
                'No data': 'No data',
                'Delete': 'Delete',
                'Success': 'Success',
                'Error': 'Error'
            },

            catalog: {
                'Project Data': 'Project Data',
                'Segmentation': 'Segmentation',
                'Segments Catalog': 'Segments Catalog',
                'Segments': 'Segments',
                'Completed tasks': 'Completed tasks',
                'URLs groups': 'URL groups',
                'Keywords groups': 'Keyword groups',
                'Categories': 'Categories',
                'All': 'All',
                'New segment': 'New segment',
                'Last tasks': 'Last tasks',

                'Search by segment': 'Search by segment',
                'No segments found': 'No segments found',

                'Back to segments catalog': 'Back to segments catalog',
                'Back to segment selection': 'Back to segment selection',

                'Collect BI': 'Collect BI',
                'Segment comparison': 'Segment comparison',
                'Selected': 'Selected',
                'Apply': 'Apply',
                'Search segments': 'Search segments',
                'Segment type': 'Segment type',
                'Select a date': 'Select a date',
                'User': 'User',
                'Date': 'Date',
                'Date of creation': 'Date of creation',
                'Search': 'Search',
                'Directory folder': 'Directory folder',

                'Segment name': 'Segment name',
                'Go to': 'Go to',
                'Number of elements': 'Number of elements',
                'Modified': 'Modified',
                'Created': 'Created',
                'Actions': 'Actions',
                'More': 'More',
                'Delete': 'Delete',
                'Edit': 'Edit',
                'Move': 'Move',
                'Save': 'Save',
                'Cancel': 'Cancel',
                'Delete selected': 'Delete selected',
                'Add to': 'Add to',
                'Export': 'Export',

                'System': 'System',
                'Custom': 'Custom',

                'pages': [
                    'URL',
                    'URLs',
                    'URLs'
                ],

                'URLs': 'URLs',
                'Keywords': 'Keywords',
                'Segment element': 'Segment element',
                'Copy to clipboard': 'Copy to clipboard',

                'Dashboard visibility by segment': 'Dashboard visibility by segment',
                'Dashboard competitors by segment': 'Dashboard competitors by segment',
                'BI report by segment': 'BI report by segment',
                'Dashboard tech monitor by segment': 'Dashboard tech monitor by segment',
                'SEO analytics url': 'SEO analytics url',
                'SEO analytics keywords': 'SEO analytics keywords',

                'Are you sure you want to delete the segment': 'Are you sure you want to delete the segment',
                'Are you sure you want to move the segment': 'Are you sure you want to move the segment',
                'Are you sure you want to delete': 'Are you sure you want to delete',

                'The "${segment.name}" segment has been changed': 'The "${segment.name}" segment has been changed',
                'Segments moved': 'Segments moved',
                'The task to delete the segment "${segment.name}" is set': 'The task to delete the segment "${segment.name}" is set',
                'The task to delete segments is set': 'The task to delete segments is set',
                'The task to delete the element "${item.name}" is set': 'The task to delete the element "${item.name}" is set',
                'The data has been updated': 'The data has been updated',
                'The segment is already in this folder': 'The segment is already in this folder',
                'An error occurred while moving the segment': 'An error occurred while moving the segment',
                'The task of creating the segment is set': 'The task of creating the segment',
                'The current project is frozen': 'The current project is frozen',
                'The task to add elements is set': 'The task to add elements is set',
                'The task to move elements is set': 'The task to move elements is set',
                'The task to delete elements is set': 'The task to delete elements is set',
                'Select segment': 'Select segment',

                'Creating a segment': 'Creating a segment',
                'Name of segment': 'Name of segment',
                'Select a project': 'Select a project',
                'Select date': 'Select date',
                'Add items': 'Add items',
                'Format': 'Format',
                'URL id | segment name': 'URL id | segment name',
                'URL | segment name': 'URL | segment name',
                'Keyword | segment name': 'Keyword | segment name',
                'Download example': 'Download example',
                'File': 'File',
                'List': 'List',
                'URL list': 'URL list',
                'List of keywords': 'List of keywords',
                'Validation error': 'Validation error',
                'An error occurred': 'An error occurred',
                'File successfully uploaded': 'File successfully uploaded',
                'Error loading the file': 'Error loading the file',

                'Adding keywords': 'Adding keywords',
                'Adding URLs': 'Adding URLs',
                'Adding segment elements': 'Adding segment elements',
                'Task name': 'Task name',

                'Editing a segment': 'Editing a segment',
                'Segment name must be at least 4 characters': 'Segment name must be at least 4 characters',
                'Maximum segment name length is 255 characters': 'Maximum segment name length is 255 characters',
                'Field is required': 'Field is required'
            }
        }
    },

    cookie: {
      'We use cookies on our site to provide you with the best user experience.': 'We use cookies on our site to provide you with the best user experience. ',
      'Privacy Policy': 'Privacy Policy',
      url: 'https://dataforce.io/confidential',
      Ok: 'Ok'
    },
}
