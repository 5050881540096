import Vue from 'vue'

export default {
  setSegmentData (state, payload) {
    let data = {
      segment_id: undefined,
      has_data: false,
      data: {}
    }

    if (payload && payload.has_data) {
      data = {
        segment_id: payload.segment_id,
        has_data: true,
        data: payload.data
      }
    }

    Vue.set(state, 'segment', data)
  },

  setSegmentName (state, payload) {
    Vue.set(state.segment.data, 'name', payload)
  },

  setList (state, payload) {
    let data = {
      ...state.data,
      has_data: false,
      offset: 0,
      total: 0,
      next: true,
      items: []
    }

    if (payload.has_data && !!payload.items.length) {
      data = {
        ...state.data,
        has_data: payload.has_data,
        next: payload.has_more,
        items: payload.items,
        total: payload.total
      }
    }

    Vue.set(state, 'data', data)
  },

  selectedItem (state, entity_id) {
    const itemIndex = state.data.items.findIndex(item => item.entity_id === entity_id)

    const newItem = Object.assign({}, state.data.items[itemIndex])
    newItem.selected = !newItem.selected

    const stateData = Object.assign({}, state.data)

    stateData.items.splice(itemIndex, 1, newItem)

    Vue.set(state, 'data', stateData)
  },

  selectAllItems (state) {
    const stateData = Object.assign({}, state.data)

    stateData.items = stateData.items.map(item => ({
      ...item,
      selected: true
    }))

    Vue.set(state, 'data', stateData)
  },

  unselectAllItems (state) {
    const stateData = Object.assign({}, state.data)

    stateData.items = stateData.items.map(item => ({
      ...item,
      selected: false
    }))

    Vue.set(state, 'data', stateData)
  }
}
