<template>
  <div class="card-item">
    <div class="card-controls">
      <v-button
          v-if="data.items && data.items.length"
          :class="['card__toggle', {_active: showItems}]"
          type="text-base"
          :icon="showItems ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
          height="20px"
          @click="showItems ^= true"
      />
      <span class="card-item__title">
        {{ data.title }}
      </span>
      <v-checkbox
          class="card-item__checkbox"
          :value="data.visible"
          :disabled="data.visible && singleCardVisible"
          :icon="checkboxIcon"
          @input="$emit('toggle-active', {value: $event, id: data.id})"
      />
    </div>
    <div
        v-if="data.items && data.items.length"
        v-show="showItems"
        :style="{paddingLeft: level + 1 ? 30 + 'px' : undefined}"
    >
      <v-card-add-item
          v-for="item of data.items"
          :key="item.id"
          :data="item"
          :single-card-visible="singleCardVisible"
          :level="level + 1"
          @toggle-active="$emit('toggle-active', $event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'v-card-add-item',

  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    singleCardVisible: {
      type: Boolean,
      default: false
    },
    level: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      showItems: false
    }
  },

  computed: {
    allItemsVisible() {
      return this.data.items && this.data.items.every(item => item.visible)
    },
    someItemsVisible() {
      return this.data.items && this.data.items.some(item => item.visible)
    },
    checkboxIcon() {
      return this.allItemsVisible
        ? 'done'
        : this.someItemsVisible
          ? 'remove'
          : undefined
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  &-item {
    &__checkbox {
      margin-left: auto;
    }
  }

  &-controls {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 10px 17px;
  }

  &__toggle {
    flex-shrink: 0;
    padding: 0 !important;

    &._active {
      & /deep/ .button__icon {
        fill: #2979FF;
      }
    }
  }
}
</style>
