import Vue from 'vue'

const events = new Vue({
    data: {
        sizes: {
            width: null,
            height: null,
            containerWidth: null,
            containerHeight: null,

            asideWidth: null,
            asideHeight: null,
            headerWidth: null,
            headerHeight: null
        },

        functions: {
            saveSize: (key, size) => {
                if (key && size) {
                    Vue.set(events.sizes, key, size)
                }
            },

            sizeContainerWidth: (size) => {
                if (size) {
                    Vue.set(events.sizes, 'containerWidth', size)
                }
            },

            sizeContainerHeight: (size) => {
                if (size) {
                    Vue.set(events.sizes, 'containerHeight', size)
                }
            }
        }
    }
});

const modals = {
    install(Vue, options = {}) {
        Object.defineProperty(Vue.prototype, '$sizes', {
            get: function() {
                return {...events.sizes, ...events.functions};
            }
        });

        events.sizes.width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        events.sizes.height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

        window.addEventListener('resize', () => {
            events.sizes.width =
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;
            events.sizes.height =
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight
        })
    }
};


export default modals
