export default {
  INDEX: 'index',
  LANDING: 'landing',
  DEMO: 'demo',
  REDIRECT: 'redirect',
  HELP: 'help',
  PRESENTATION: 'presentation',
  ABOUT_TEAM: 'aboutTeam',
  CONTACTS: 'contacts',
  CONFIDENTIAL: 'confidential',

  AUTH: 'auth',
  LOGIN: 'login',
  LOGOUT: 'logout',
  DASHBOARD: 'dashboard',
  INDEX_CATALOG: 'indexCatalog',
  TRAFFIC_FUNNEL: 'trafficFunnel',
  TASKS: 'tasks',
  LISTINGS: 'listings',
  CARDS: 'cards',

  PROJECT_CREATE: 'projectCreate',
  PROJECT_LIST: 'projectList',

  BI: 'bi',
  BI_COMPARISON: 'biComparison',
  BI_COMPARISON_DYNAMICS: 'biComparisonDynamics',
  BI_COMPARISON_CORRELATION_SALES: 'biComparisonCorrelationSales',
  BI_COMPARISON_DYNAMICS_SALES: 'biComparisonDynamicsSales',
  BI_CATEGORY: 'biCategory',
  BI_COMPARE_PERIODS: 'biComparePeriods',
  BI_TRACTION: 'biTraction',
  BI_COMPARE_CHANNELS: 'biCompareChannels',
  BI_CROSS_SALES: 'biCrossSales',
  BI_COMMERCE_OVERVIEW: 'biCommerceOverview',

  QUICK_START: 'quickStart',
  ROI_FUNNEL: 'roiFunnel',
  PREDICTION_FUNNEL: 'predictionFunnel',
  CONTENT_ANALYSIS: 'contentAnalysis',
  COMMERCE_ANALYSIS: 'commerceAnalysis',
  BRAND_ANALYSIS: 'brandAnalysis',
  SEGMENTS_EXPERIMENTS: 'segmentsExperiments',
  K50_FEED: 'k50Feed',
  K50_REPORT: 'k50Report',
  INSIGHTS_ANALYTICS: 'insightsAnalytics',
  CONTENT_INSIGHTS: 'contentInsights',
  ABC_ANALYSIS: 'abcAnalysis',

  SEGMENTS: 'segments',
  SEGMENTS_CONTENT: 'segmentsContent',
  SEGMENTS_CONTENT_INDEX: 'segmentsContentIndex',
  SEGMENTS_CONTENT_LIST: 'segmentsContentList',
  SEGMENTS_CONTENT_ITEM: 'segmentsContentItem'
}
