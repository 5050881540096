/* Функция округления */
export function updateTrafficSourceName (config, trafficSource) {
  let newConfig = [...config]

  outer:
  for (let configItem of newConfig) {
    for (let metric of configItem.items) {
      if (metric.id === 'transaction_revenue') {
        metric.name = `Выручка (${trafficSource})`
        break outer
      }
    }
  }

  return config
}
