import {updateTrafficSourceName} from '@/function'

const getCustomSegmentItems = (userSegments) => {
  if (!userSegments.hasData) {
    return []
  }

  return userSegments.items.map((item) => ({
      ...item,
        catalog_id: item.name_hash,
        url_title: item.name,
    }))
}

const getCatalogSegmentItems = (catalog) => {
  if (!catalog.hasData) {
    return []
  }

  return [...catalog.items]
}

const getters = {
  mixedSegments: state => {
    const customItems = getCustomSegmentItems(state.userSegments)
    const catalogItems = getCatalogSegmentItems(state.catalog)

    if (!state.userSegments.hasData && !state.catalog.hasData) {
      return {
        hasData: false,
        items: [],
        total: 0
      }
    }

    return {
      hasData: true,
      items: customItems.concat(catalogItems),
      total: state.userSegments.total + state.catalog.total
    }
  },

  cellsConfig: (state, getters, rootState) => key => {
    if (key === 'commerceOverviewTableCellsConfig') {
      return rootState.tableSettings[key]
    } else {
      return updateTrafficSourceName(rootState.tableSettings[key], rootState.trafficSourceSelectedName)
    }
  }
}

export default getters
