<template>
  <div class="settings-modal">
    <div class="settings-modal__title">Настройка стикеров</div>
    <div class="settings-modal__description">
      Настройте стикеры так, чтобы видеть только те, которые нужны вам сейчас. <br>
      Добавляйте или удаляйте стикеры для более удобной работы с вашими задачами!
    </div>

    <div class="settings-modal-container _custom-scrollbar">
      <div class="settings-modal-block">
        <div class="settings-modal-block__head">Добавление стикеров</div>

        <div class="settings-modal-block__filter">
          <s-input
            class="settings-modal-block__filter-search"
            icon="search"
            placeholder="Поиск стикера"
            v-model="cardSearch"
            clear-button
          />
        </div>

        <div class="settings-modal-block__filter">
          <v-button
            class="settings-modal__visibility-btn"
            type="text-main"
            width="100%"
            height="44px"
            :name="`${checkAll ? 'Скрыть' : 'Показать'} все стикеры`"
            :icon="`visibility${checkAll ? '_off' : ''}`"
            offset-icon="4px"
            @click="toggleAll"
          />
        </div>

        <v-card-add
          v-bind="{
              cards,
              cardConfig: cardConfigTemp,
              cardSearch,
              singleCardVisible,
              cardGroups
            }"
          @toggle-active="updateCardConfigTemp"
        />
      </div>

      <div class="settings-modal-block">
        <div class="settings-modal-block__head">
          <span>Стикеры на странице</span>
          <div class="settings-modal-block__head-count">
            {{ cardsVisible.length }}/<span>{{ cardConfigTemp.length }}</span>
          </div>
        </div>

        <v-card-list
          :cards="cardDataVisible"
          @delete-card="toggleCardView($event)"
          @reorder="reorderCards"
          @delete-all="deleteAllCards"
        />
      </div>
    </div>

    <div class="settings-modal-buttons">
      <v-button
        class="settings-modal__button"
        type="text-base"
        name="Отменить"
        @click="$modal.close()"
      />
      <v-button
        class="settings-modal__button"
        name="Сохранить"
        dataforce
        @click="save"
      />
    </div>
  </div>
</template>

<script>
import {findKey, findOptions} from '@/function'
import {NOTIFY_STATUS} from '@/const'
import CARD_NAME from '../../cardName'
import CARD_GROUP_NAME from '../../cardGroupName'

import cardAdd from './cardAdd/cardAdd.vue'
import cardList from './cardList'

export default {
  name: 'cardSettingsModal',

  components: {
    'v-card-add': cardAdd,
    'v-card-list': cardList
  },

  props: {
    cards: {
      type: Object,
      default: () => ({})
    },

    configName: {
      type: String,
      default: ''
    },

    cardConfig: {
      type: Array,
      default: () => ([])
    },

    cardList: {
      type: Array,
      default: () => ([])
    },

    setCardListFunc: {
      type: Function,
      required: true
    },

    updateWidgetConfigFunc: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      cardConfigTemp: [],
      cardListTemp: [],
      cardSearch: undefined,
      cardGroups: [
        {
          id: CARD_GROUP_NAME.DEMAND_VISIBILITY,
          title: 'Спрос/видимость',
          parent: [CARD_GROUP_NAME.FUNNEL_STAGES]
        },
        {
          id: CARD_GROUP_NAME.TRAFFIC,
          title: 'Трафик',
          parent: [CARD_GROUP_NAME.FUNNEL_STAGES]
        },
        {
          id: CARD_GROUP_NAME.TRANSACTIONS,
          title: 'Транзакции',
          parent: [CARD_GROUP_NAME.FUNNEL_STAGES]
        },
        {
          id: CARD_GROUP_NAME.REVENUE,
          title: 'Выручка',
          parent: [CARD_GROUP_NAME.FUNNEL_STAGES]
        },
        {
          id: CARD_GROUP_NAME.CONVERSION,
          title: 'Конверсия',
          parent: [CARD_GROUP_NAME.FUNNEL_STAGES]
        },
        {
          id: CARD_GROUP_NAME.LEADERS,
          title: 'Лидеры',
          parent: [CARD_GROUP_NAME.LEADERS_OUTSIDERS]
        },
        {
          id: CARD_GROUP_NAME.OUTSIDERS,
          title: 'Аутсайдеры',
          parent: [CARD_GROUP_NAME.LEADERS_OUTSIDERS]
        },
        {
          id: CARD_GROUP_NAME.CHANNELS,
          title: 'По каналам',
          parent: [CARD_GROUP_NAME.POPULAR_SLICES]
        },
        {
          id: CARD_GROUP_NAME.URL_TYPES,
          title: 'По типам страниц',
          parent: [CARD_GROUP_NAME.POPULAR_SLICES]
        },
        {
          id: CARD_GROUP_NAME.GEO,
          title: 'По гео',
          parent: [CARD_GROUP_NAME.POPULAR_SLICES]
        },
        {
          id: CARD_GROUP_NAME.CATEGORIES,
          title: 'По категориям',
          parent: [CARD_GROUP_NAME.POPULAR_SLICES]
        },
        {
          id: CARD_GROUP_NAME.DEVICES,
          title: 'По типам устройств',
          parent: [CARD_GROUP_NAME.POPULAR_SLICES]
        },
        {
          id: CARD_GROUP_NAME.PROJECT_SUMMARY,
          title: 'Общее состояние проекта'
        },
        {
          id: CARD_GROUP_NAME.FUNNEL_STAGES,
          title: 'По этапам воронки'
        },
        {
          id: CARD_GROUP_NAME.LEADERS_OUTSIDERS,
          title: 'По лидерам/аутсайдерам'
        },
        {
          id: CARD_GROUP_NAME.POPULAR_SLICES,
          title: 'По популярным срезам'
        }
      ]
    }
  },

  computed: {
    cardData() {
      return this.cardConfigTemp.map(card => {
        return {
          ...card,
          name: this.cards[card.id] && this.cards[card.id].title
        }
      })
    },

    cardDataVisible() {
      return this.cardData.filter(item => item.visible)
    },

    cardsVisible() {
      return this.cardData.filter(item => item.visible)
    },

    checkAll() {
      return this.cardConfigTemp.every(item => item.visible)
    },

    singleCardVisible() {
      return this.cardConfigTemp.filter(item => item.visible).length === 1
    }
  },

  watch: {
    cardConfigTemp(val) {
      this.cardListTemp = val.filter(item => item.visible)
    }
  },

  created() {
    this.cardConfigTemp = [...this.cardConfig]
  },

  methods: {
    toggleCardView({value, id}) {
      if ((!value && !this.singleCardVisible) || value) {
        let newConfig = [...this.cardConfigTemp]

        let idx = findKey(id, newConfig)
        let item = findOptions(id, newConfig)

        item.visible = value

        newConfig.splice(idx, 1)
        newConfig = [item, ...newConfig]

        this.cardConfigTemp = [...newConfig]
      }
    },

    updateCardConfigTemp({value, id}) {
      if (findOptions(id, this.cardGroups)) {
        const cards = Object.values(this.cards)
        const getCardChildren = items => {
          items.forEach(child => {
            if (findOptions(child.id, this.cardGroups)) {
              getCardChildren(cards.filter(card => card.parent && card.parent.includes(child.id)))
            } else {
              this.toggleCardView({value, id: child.id})
            }
          })
        }

        const children = [...cards, ...this.cardGroups].filter(card => card.parent && card.parent.includes(id))
        getCardChildren(children)
      } else {
        this.toggleCardView({value, id})
      }
    },

    reorderCards(data) {
      let config = [...data]

      this.cardConfig.forEach(item => {
        if (!findOptions(item.id, config)) {
          config.push(item)
        }
      })

      this.cardConfigTemp = config
    },

    async save() {
      try {
        await this.updateWidgetConfigFunc(this.cardConfigTemp)
          .then(() => {
            this.$store.commit('dashboard/quickStart/saveWidgetConfig', {
              hasData: true,
              projectId: this.$store.state.dashboard.quickStart.widgetConfig.projectId,
              data: this.cardConfigTemp
            })
            this.setCardListFunc([...this.cardListTemp])
          })
      } catch ({message}) {
        this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
      }
      this.$modal.close()
    },

    toggleAll() {
      let config = []

      Object.keys(this.cards).forEach(key => {
        config.push({
          id: key,
          visible: !this.checkAll || key !== CARD_NAME.DYNAMICS ? !this.checkAll : this.checkAll
        })
      })

      this.cardConfigTemp = config
    },

    deleteAllCards() {
      let config = []
      const visible = [...this.cardDataVisible]

      config = this.cardConfigTemp.map(item => {
        const idx = findKey(item.id, visible)
        if (idx) {
          return {
            id: item.id,
            visible: false
          }
        } else {
          return {...item}
        }
      })

      this.cardConfigTemp = config
    }
  }
}
</script>

<style scoped lang="scss">
.settings-modal {
  width: calc(90vw - 48px);
  max-width: 760px;
  padding: 24px;

  &__title {
    margin-bottom: 10px;
    color: #2D3646;
    font-size: 20px;
    font-weight: 500;
  }

  &__description {
    margin-bottom: 16px;
    line-height: 20px;
    color: #455165;
    font-size: 12px;
  }

  &-container {
    display: flex;
    column-gap: 16px;
    margin-bottom: 26px;
    overflow-x: scroll;
  }

  &-block {
    border: 1px solid #E0E6F0;
    border-radius: 6px;
    color: #455165;

    &:first-child {
      width: 268px;
    }

    &:last-child {
      width: 428px;
    }

    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 17px;
      border-bottom: 1px solid #E0E6F0;
      font-size: 16px;
      font-weight: 500;

      &-count {
        font-size: 14px;
        font-weight: 400;

        span {
          color: #BDBDBD;
        }
      }
    }

    &__filter {
      border-bottom: 1px solid #E0E6F0;

      &-search {
        margin: 16px;
      }
    }
  }

  &-buttons {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    height: 32px;
    line-height: 32px;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  &__visibility-btn {
    min-width: 200px;
    border-radius: 0;
    font-size: 12px;

    & /deep/ .button__icon {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
