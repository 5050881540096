const state = {
  /* Каталог */
  catalog: {
    hasData: false,
    items: [],
    total: 0
  },

  /* Сегменты пользователя */
  userSegments: {
    hasData: false,
    items: [],
    total: 0
  }
}

export default state
