/*
 * Функция валидации URL
 */
export default function (string, ports = ['http', 'https']) {
  try {
    let url = new URL(string)
    return ports.indexOf(url.protocol.replace(/:$/, '')) !== -1 &&
      url.host.split('.').filter(_ => _).length > 1 && !url.host.match(/^\.+/)
  } catch (_) {
    return false
  }
}
